import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { ComponentTheme, ThemeInterface } from '@core-mkt/services/theme-parser/theme-parser.service';
import { ThemeParserService } from '@core-mkt/services/theme-parser/theme-parser.service';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { MultiColumnComponentType } from './multi-column';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import type { DropdownData } from '../../vision-dropdown/vision-dropdown/vision-dropdown';
import { VisionModalService } from '../../vision-modal/vision-modal.service';

@Component({
  selector: 'multi-column',
  templateUrl: './multi-column.component.html',
  styleUrls: ['./multi-column.component.scss'],
})
export class MultiColumnComponent implements OnInit {
  @Input() data: MultiColumnComponentType;
  theme: ThemeInterface;
  faChevronDown = faChevronDown;
  faTimes = faTimes;
  containerLevelClasses: string;
  topLevelClasses: string;

  constructor(
    private redactor: WysiwygRedactorService,
    private themeParser: ThemeParserService,
    private modalService: VisionModalService,
  ) {}

  ngOnInit() {
    if (this.data.componentTitle === null) {
      this.data.componentTitle = '';
    } else {
      this.data.componentTitle = this.redactor.bypassSanitizer(this.data.componentTitle);
    }
    // this.separateTailwindClasses(this.data.tailwindClasses);
    this.data.columnList = this.data.columnList.filter((column) => column.columnContent !== null);
    this.data.columnList.forEach((column) => {
      column.columnContent = this.redactor.bypassSanitizer(column.columnContent);
    });
    const themeInput: ComponentTheme = {
      styles: this.data.styles,
      backgroundColor: this.data.backgroundColor,
      backgroundImage: this.data.backgroundImage?.[0],
      textColor: this.data.textColor,
      columnCount: this.data.columnList ? this.data.columnList.length : 0,
      columnDistribution: this.data.twoColumnDistribution,
    };
    this.theme = this.themeParser.getThemeClasses(themeInput);
    const selectedClasses = this.data.tailwindClasses;
    if (selectedClasses && selectedClasses !== null) {
      this.containerLevelClasses = this.themeParser.getContainerLevelClasses(selectedClasses);
      this.topLevelClasses = this.themeParser.getTopLevelClasses(selectedClasses);
    }
  }

  modalWrapperClickHandler(event: Event): void {
    if (
      (event.target as HTMLElement).classList.contains('highlighted-text-modal-1') &&
      this.data.highlightedTextModal1.length > 0
    ) {
      const modalContent = this.data.highlightedTextModal1[0].modalContent;
      this.modalService.openModal({ showModal: true, modalContent });
    }
    if (
      (event.target as HTMLElement).classList.contains('highlighted-text-modal-2') &&
      this.data.highlightedTextModal2.length > 0
    ) {
      const modalContent = this.data.highlightedTextModal2[0].modalContent;
      this.modalService.openModal({ showModal: true, modalContent });
    }
  }

  setDropdownData(dropdownItems, dropdownTitle, dropdownAlignment, dropdownTopLabel): DropdownData {
    return {
      dropdownItems: dropdownItems,
      dropdownPlaceholder: dropdownTitle,
      dropdownAlignment: dropdownAlignment,
      dropdownTopLabel: dropdownTopLabel,
    };
  }
}
