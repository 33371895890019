/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const AmbassadorInvFragment = {
  AmInv: gql`
    fragment AmInvQuery on baseComponents_ambassadorInvite_BlockType {
      ambassadorInviteTitle
      ambassadorInviteContent
      ambassadorInviteRightContent
      styles
      ctaLabel
      ctaStyle
      typeHandle
    }
  `,
};
