import { Component, Input } from '@angular/core';
import type { IconConfig } from '@core-mkt/interfaces/icons';
import { IconService } from '@core-mkt/services/iconService/icon.service';
import type { SwiperOptions } from 'swiper/types';
import type { CarouselItem } from '../study-plan-builder.models';
import Swiper, { Keyboard } from 'swiper';

Swiper.use([Keyboard]);

@Component({
  selector: 'study-builder-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent {
  @Input() data: CarouselItem[];
  prevIcon: IconConfig = null;
  nextIcon: IconConfig = null;

  public swiperConfig: SwiperOptions = {
    navigation: true,
    slidesPerView: 1,
    freeMode: true,
    initialSlide: 0,
    centeredSlides: true,
    centerInsufficientSlides: true,
    loop: true,
    keyboard: {
      enabled: true,
    },
    on: {
      init: () => this.initializeNavigationButtons(),
    },
  };

  constructor(private iconService: IconService) {}

  get carouselItems(): CarouselItem[] {
    return this.data;
  }

  /*
   * Omitting type Swiper to not collide with Swiper installer
   */
  onSwiper(swiper): void {
    this.prevIcon = this.iconService.getBrandIcon('slider-left');
    this.nextIcon = this.iconService.getBrandIcon('slider-right');

    if (swiper?.navigation && swiper.navigation.prevEl && swiper.navigation.nextEl && this.prevIcon && this.nextIcon) {
      swiper.navigation.prevEl.innerHTML = this.prevIcon.icon;
      swiper.navigation.nextEl.innerHTML = this.nextIcon.icon;
      this.addInteractionListeners(swiper.navigation.prevEl);
      this.addInteractionListeners(swiper.navigation.nextEl);
    }
  }

  /*
   * Called when Swiper is initialized to set up navigation button behavior
   */
  private initializeNavigationButtons(): void {
    const swiperContainer = document.querySelector('.swiper') as HTMLElement;
    if (swiperContainer) {
      const prevButton = swiperContainer.querySelector('.swiper-button-prev') as HTMLElement;
      const nextButton = swiperContainer.querySelector('.swiper-button-next') as HTMLElement;
      if (prevButton && nextButton) {
        this.addInteractionListeners(prevButton);
        this.addInteractionListeners(nextButton);
      }
    }
  }

  private addInteractionListeners(button: HTMLElement): void {
    button.addEventListener('touchstart', () => this.addActiveState(button));
    button.addEventListener('mousedown', () => this.addActiveState(button));
    button.addEventListener('touchend', () => {
      this.resetButtonState(button);
      button.classList.add('hover-state');
      setTimeout(() => {
        button.classList.remove('hover-state');
      }, 200);
    });
    button.addEventListener('mouseup', () => this.resetButtonState(button));
    button.addEventListener('mouseleave', () => this.resetButtonState(button));
  }

  private addActiveState(button: HTMLElement): void {
    button.classList.add('active-state');
  }

  private resetButtonState(button: HTMLElement): void {
    button.classList.remove('active-state');
    button.classList.remove('hover-state');
  }
}
