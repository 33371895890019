import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { QueryParamModule } from '@core-mkt/services/query-param/query-param.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { VisionModalComponent } from './vision-modal/vision-modal.component';

@NgModule({
  declarations: [VisionModalComponent],
  exports: [VisionModalComponent],
  imports: [CommonModule, FontAwesomeModule, QueryParamModule],
})
export class VisionModalModule {}
