/* eslint-disable prettier/prettier */
import { ProductFragment } from '@core-mkt/shared/craft/product-entry-fragment';
import * as gql from 'fake-tag';

export const ProductCardFragment = {
  ProductCard: gql`
    fragment ProductCardQuery on baseComponents_productCards_BlockType {
      backgroundImage {
        url
        ... on images_Asset {
          altText
        }
      }
      ctaStyle
      filterType
      hideCta
      lineBreak_content
      lineBreak_styles
      useCompactCards
      productCardList {
        ... on productCardList_BlockType {
          cardTitle
          ctaLabel
          ctaLink
          ctaNewTab
          ctaNofollow     
          learnMoreLabel
          learnMoreLink
          learnMoreNewTab
          learnMoreNofollow
          productEntry {
            ...ProductEntry
          }
          showSavings
          starCaption
          starRating
          styles
          textContent
        }
      }
      sectionTitle
      styles
      typeHandle
    }
    ${ProductFragment.product}
  `,
};
