<div [id]="topProductID" *ngIf="hasContent" class="top-product font-body bg-cover {{ theme?.root }}" [style]="theme?.bgImage">
  <div class="container mx-auto flex flex-col items-center {{ theme?.container }} {{ showContent ? imgAlign : '' }}">
    <div class="lg:w-1/2 w-full flex-col lg:mr-v-spacing">
      <div *ngIf="showTitle" class="mb-v-spacing top-product__title flex flex-col">
        <div class="flex items-center">
          <span class="inline-block">
            <img
              *ngIf="data.productSeal.length > 0 && data.productSeal[0] && data.productSeal[0].url !== null"
              [src]="data.productSeal[0].url"
              [alt]="data.productSeal[0].alt"
              class="mb-3 mr-4 top-product__seal"
              width="48"
              height="48"
            />
          </span>
          <span class="inline-block" *ngIf="data.eyebrow" [innerHTML]="data.eyebrow"></span>
        </div>
        <div class="wysiwyg" [innerHTML]="data.sectionTitle"></div>
      </div>
      <div *ngIf="data.showTrustpilotStars" class="my-v-spacing">
        <div class="flex items-center cursor-pointer text-xs xxsm:text-sm xs:text-base" (click)="openTruspilot()">
          <img
            class="h-[24px]"
            height="24"
            width="106.67"
            src="https://xgrit-ecom.imgix.net/ace/trustpilot-star-rating.svg?v=1706889351"
            alt=""
          />
          <span *ngIf="trustpilotVerified" class="mx-2.5"
            ><span class="font-extrabold">{{ trustpilotTrustScore }}/5</span> Stars</span
          >
          <span *ngIf="trustpilotVerified"
            ><span class="font-extrabold">{{ trustpilotNumberOfReviews | number }}</span> Reviews</span
          >
        </div>
      </div>
      <featured-content *ngIf="featuredContent" [data]="featuredContent" class="pb-4 block"></featured-content>
      <div
        *ngIf="showContent" (click)="activateModalWrapper($event, 'wysiwyg')"
        class="wysiwyg mb-0 top-product__content text-baselg leading-[25px] w-full {{ checkStyle }} {{
          theme?.linkColor === ('text-vis-reverse' || 'text-white')  ? 'darkLink' : ''
        }}"
        [innerHTML]="data.sectionContent"
      ></div>
      <div class="flex flex-col">
        <div class="flex flex-col md:flex-row mt-v-spacing" *ngIf="showPricing">
          <div class="{{ displayOfferTimer ? '' : 'lg:flex-col' }} flex pricing top-product__pricing">
            <div #prices (click)="activateModalWrapper($event)" class="flex items-center prices" [innerHtml]="pricingText"></div>
          </div>
          <div
            *ngIf="displayOfferTimer"
            class="timer flex lg:justify-start md:justify-center items-center ml-0 md:ml-2 font-bold"
          >
            <div class="mr-1">OFFER ENDS</div>
            <div class="tracking-wide">
              <span class="hour">{{ countdown | async | durationText : 'daysToHours' }}:</span>
              <span class="minute">{{ countdown | async | durationText : 'minutes' }}:</span>
              <span class="second">{{ countdown | async | durationText : 'seconds' }}</span>
            </div>
          </div>
        </div>
        <ng-container *ngIf="showFirstButtonOnly">
          <div class="{{ outerButtonAlign }}">
            <div class="mb-3 md:mb-v-spacing mt-v-spacing vision-button-container" #topSectionCta>
              <!-- Standard CTA -->
              <vision-button
                defaultStyle="tertiary"
                *ngIf="showCTA && !showModal1CTA"
                [data]="ctaButtonData"
                class="primary-cta"
              ></vision-button>
              <!-- Modal 1 CTA -->
              <button
                *ngIf="showModal1CTA"
                (click)="activateModal(data.highlightedTextModal1[0].modalContent)"
                class="{{ ctaButtonData.buttonStyle }}-button top-product__modal-cta v-button font-bold mt-0 ml-0"
                [class.cursor-pointer]="data.highlightedTextModal1[0].modalContent"
              >
                {{ ctaButtonData.buttonText }}
              </button>
            </div>
            <div
              class="flex sm:flex-row flex-col w-max self-center {{
                outerButtonAlign.length > 0 &&
                  'xl:self-center md:justify-self-start justify-self-center sm:ml-3 whitespace-nowrap'
              }}"
              *ngIf="modalHasContent"
            >
              <button
                *ngIf="data.hideGuaranteeLink === false"
                (click)="activateModal(data.modalContent)"
                class="tertiary-button top-product__modal-cta font-bold mt-0 ml-0 pt-3 border-b-2"
                [class.cursor-pointer]="data.modalContent"
              >
                {{ moneyGuaranteeLabel }}
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="secondButtonData">
          <div class="{{ outerButtonAlign }} my-v-spacing vision-button-container dual-button" #topSectionCta>
            <!-- Standard CTA -->
            <vision-button
              class="{{ (hasSecondButtonData || showModal2CTA) && 'lg:mb-3 xl:mb-0 sm:mr-3 mb-3' }}"
              defaultStyle="tertiary"
              *ngIf="showCTA && !showModal1CTA"
              [data]="ctaButtonData"
            ></vision-button>
            <!-- Modal 1 CTA -->
            <button
              *ngIf="showModal1CTA"
              (click)="activateModal(data.highlightedTextModal1[0].modalContent)"
              class="{{
                ctaButtonData.buttonStyle
              }}-button top-product__modal-cta v-button w-full md:!w-max font-bold mt-0 ml-0 mb-3 md:mb-0 lg:mb-3 xl:mb-0 lg:mr-0 {{showModal2CTA && 'sm:mr-3'}}"
              [class.cursor-pointer]="data.highlightedTextModal1[0].modalContent"
            >
              {{ ctaButtonData.buttonText }}
            </button>
            <!-- Second CTA -->
            <vision-button
              *ngIf="!showModal2CTA"
              class="{{ modalHasContent && 'mb-3 md:mb-0 lg:mb-3 xl:mb-0' }}"
              defaultStyle="tertiary"
              [data]="secondButtonData"
            ></vision-button>
            <!-- Modal 2 CTA -->
            <button
              *ngIf="showModal2CTA"
              (click)="activateModal(data.highlightedTextModal2[0].modalContent)"
              class="{{ secondButtonData.buttonStyle }}-button top-product__modal-cta v-button w-ful max-w-max md:w-max font-bold mt-0  {{
                modalHasContent && 'mb-3 md:mb-0 lg:mb-3 xl:mb-0'
              }}"
              [class.xl:ml-3]="showModal1CTA"
              [class.cursor-pointer]="data.highlightedTextModal2[0].modalContent"
            >
              {{ secondButtonData.buttonText }}
            </button>
            <div
              class="flex sm:flex-row flex-col xl:justify-start lg:self-start md:justify-self-start self-center justify-center  md:justify-start items-center {{
                outerButtonAlign.length > 0 && 'self-center md:mt-3 xl:ml-3 lg:ml-0 md:ml-3 whitespace-nowrap'
              }}"
              [class.w-full]="outerButtonAlign.includes('flex-wrap')"
              [class.w-max]="!outerButtonAlign.includes('flex-wrap')"
              *ngIf="modalHasContent"
            >
              <button
                *ngIf="!data.hideGuaranteeLink && !(this.wistiaButtonData?.wistiaId && this.ctaButtonData)"
                (click)="activateModal(data.modalContent)"
                class="tertiary-button top-product__modal-cta font-bold mt-0 ml-0 pt-3 w-max"
                [class.cursor-pointer]="data.modalContent"
              >
                {{ moneyGuaranteeLabel }}
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="wistiaButtonData">
          <div class="{{ outerButtonAlign }} my-v-spacing vision-button-container wistia-button" #topSectionCta>
            <!-- Standard CTA -->
            <vision-button
              class="mb-3 sm:mb-0 sm:mr-3"
              defaultStyle="tertiary"
              *ngIf="showCTA && !showModal1CTA"
              [data]="ctaButtonData"
            ></vision-button>
            <!-- Modal 1 CTA -->
            <button
              *ngIf="showModal1CTA"
              (click)="activateModal(data.highlightedTextModal1[0].modalContent)"
              class="{{ ctaButtonData.buttonStyle }}-button top-product__modal-cta v-button w-full md:w-max font-bold mt-0 ml-0
              {{ wistiaButtonData?.wistiaId && 'md:mr-3 lg:mr-0 xl:mr-3' }}"
              [class.cursor-pointer]="data.highlightedTextModal1[0].modalContent"
            >
              {{ ctaButtonData.buttonText }}
            </button>
            <!-- Wistia div for initialization and modal activation -->
            <div class="wistia-wrapper">
              <div
                class="wistia-cta-{{ wistiaCtaData?.wistiaId }} wistia_embed wistia_async_{{
                  wistiaCtaData?.wistiaId
                }} popover=true videoFoam=true autoPlay=true"
              ></div>
              <vision-video-button
                *ngIf="this.wistiaButtonData.wistiaId"
                (click)="ctaVideoPlay($event)"
                class="block mt-3 md:mt-0 lg:mt-3 xl:mt-0 videoCta-{{ this.wistiaCtaData?.wistiaId }}"
                defaultStyle="secondary"
                [data]="wistiaButtonData"
              >
              </vision-video-button>
            </div>
            <div
              class="flex sm:flex-row flex-col {{
                outerButtonAlign.length > 0 &&
                  'xl:justify-self-start lg:justify-self-center md:justify-self-start self-center lg:self-start justify-center items-center md:justify-start whitespace-nowrap'
              }}"
              [class.w-full]="outerButtonAlign.includes('flex-wrap')"
              [class.w-max]="!outerButtonAlign.includes('flex-wrap')"
              *ngIf="modalHasContent"
            >
              <button
                *ngIf="!data.hideGuaranteeLink"
                (click)="activateModal(data.modalContent)"
                class="tertiary-button top-product__modal-cta flex font-bold mt-4 ml-0 sm:ml-v-spacing pt-3 w-max"
                [class.cursor-pointer]="data.modalContent"
              >
                {{ moneyGuaranteeLabel }}
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div
      class="lg:w-1/2 w-full flex flex-col items-center mx-auto justify-center py-v-spacing sm:px-v-spacing"
      *ngIf="data.topSectionSlides && data.topSectionSlides.length > 0"
    >
      <top-section-slider
        class="block w-full"
        [slides]="data.topSectionSlides"
        [controlStyle]="data.slideControlStyle"
        [indicatorStyle]="data.slideIndicatorStyle"
        [slideAutoplay]="data.slideAutoplay"
        [transitionTime]="transitionTime"
      ></top-section-slider>
    </div>
    <div
      class="lg:w-1/2 w-full flex flex-col mx-auto justify-center py-v-spacing sm:px-v-spacing lg:ml-v-spacing"
      *ngIf="data.rightContent || categoryWidgets"
    >
      <top-section-wysiwyg
        [rightContent]="data.rightContent"
        [bottomRightContent]="bottomRightContent"
        [categoryWidgets]="categoryWidgets"
        [data]="data"
      ></top-section-wysiwyg>
    </div>
  </div>

</div>

<!-- Desktop course overview bottom popup -->
<div
  *ngIf="!ctaDisplay && showPriceCta && courseOverviewPopup"
  cond-class
  [target]="'body'"
  [addClass]="'mb-20'"
  class="fixed hidden md:block desktop-overview bottom-0 bg-white z-50 top-product__bottom-banner sm:right-[48px] w-full sm:w-[470px] rounded-t-md"
  [class.minimized]="isCoursePopupMinimized"
>
  <!-- Desktop course popup banner -->
  <div class="popup-banner hidden p-4 bg-vis-top sm:flex flex-row items-center rounded-t-md relative">
    <div class="save-badge">
      <span
        class="rounded-full bg-ace-teal-200 text-sm font-extrabold text-vis-top px-3 py-2 mr-2.5 inline-block leading-none"
        >SALE!</span
      >
    </div>
    <div class="savings text-vis-reverse font-bold text-baselg" [innerHTML]="percentSavedText"></div>
    <div
      class="icon-container h-[44px] w-[44px] ml-auto right-0 flex items-center absolute"
      (click)="toggleCourseOverviewMinimize()"
    >
      <fa-icon
        class="fa-sm text-vis-reverse cursor-pointer mx-auto"
        [icon]="isCoursePopupMinimized ? faChevronUp : faChevronDown"
      ></fa-icon>
    </div>
  </div>
  <!-- Course popup content -->
  <div class="content py-v-spacing mx-v-spacing mx-auto flex flex-row items-center">
    <div class="flex flex-col w-full sm:w-auto">
      <span class="inline-block" *ngIf="data.eyebrow" [innerHTML]="data.eyebrow"></span>
      <div class="wysiwyg popup-title mb-v-spacing" [innerHTML]="data.sectionTitle"></div>
      <div *ngIf="data.showTrustpilotStars" class="mb-v-spacing">
        <div class="flex items-center cursor-pointer text-xs xxsm:text-sm xs:text-base" (click)="openTruspilot()">
          <img
            class="h-[24px]"
            height="24"
            width="106.67"
            src="https://xgrit-ecom.imgix.net/ace/trustpilot-star-rating.svg?v=1706889351"
            alt=""
          />
          <span *ngIf="trustpilotVerified" class="mx-2.5"
            ><span class="font-extrabold">{{ trustpilotTrustScore }}/5</span> Stars</span
          >
          <span *ngIf="trustpilotVerified"
            ><span class="font-extrabold">{{ trustpilotNumberOfReviews | number }}</span> Reviews</span
          >
        </div>
      </div>
      <!-- Course popup pricing -->
      <div class="flex pricing top-product__pricing mb-6">
        <div #prices (click)="activateModalWrapper($event)" class="flex items-center prices" [innerHtml]="pricingText"></div>
      </div>
      <!-- Course popup CTA and guarantee modal link -->
      <div class="flex flex-col sm:flex-row sm:items-center">
        <vision-button defaultStyle="primary" [data]="ctaButtonData"></vision-button>
        <button
          *ngIf="!data.hideGuaranteeLink && !(this.wistiaButtonData?.wistiaId && this.ctaButtonData)"
          (click)="activateModal(data.modalContent)"
          class="tertiary-button font-bold w-fit ml-3 sm:w-auto mt-4 sm:mt-0 !text-base"
          [class.cursor-pointer]="data.modalContent"
        >
          {{ moneyGuaranteeLabel }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Mobile Course overview bottom popup -->
<div
  *ngIf="!ctaDisplay && showPriceCta && courseOverviewPopup"
  cond-class
  [target]="'body'"
  [addClass]="'mb-20'"
  class="fixed block md:hidden mobile-overview bottom-0 bg-white z-50 top-product__bottom-banner md:right-[48px] w-full md:w-[470px] rounded-t-md"
  [class.minimized]="isMobileCoursePopupMinimized"
>
  <!-- Desktop course popup banner -->
  <div class="popup-banner hidden p-4 bg-vis-top md:flex flex-row items-center rounded-t-md relative">
    <div class="save-badge">
      <span
        class="rounded-full bg-ace-teal-200 text-sm font-extrabold text-vis-top px-3 py-2 mr-2.5 inline-block leading-none"
        >SALE!</span
      >
    </div>
    <div class="savings text-vis-reverse font-bold text-baselg" [innerHTML]="percentSavedText"></div>
    <div
      class="icon-container h-[44px] w-[44px] ml-auto right-0 flex items-center absolute"
      (click)="toggleMobileCourseOverviewMinimize()"
    >
      <fa-icon
        class="fa-sm text-vis-reverse cursor-pointer mx-auto"
        [icon]="isMobileCoursePopupMinimized ? faChevronUp : faChevronDown"
      ></fa-icon>
    </div>
  </div>
  <!-- Mobile course popup banner -->
  <div
    class="mobile-popup-banner p-4 bg-vis-top flex md:hidden flex-row items-center rounded-t-md relative"
    [ngClass]="{ hidden: isMobileCoursePopupMinimized }"
  >
    <div class="save-badge">
      <span
        class="rounded-full bg-ace-teal-200 text-sm font-extrabold text-vis-top px-3 py-2 mr-2.5 inline-block leading-none"
        >SALE!</span
      >
    </div>
    <div class="savings text-vis-reverse font-bold text-baselg" [innerHTML]="percentSavedText"></div>
    <div
      class="icon-container h-[44px] w-[44px] ml-auto right-0 flex items-center absolute"
      (click)="toggleMobileCourseOverviewMinimize()"
    >
      <fa-icon
        class="fa-sm text-vis-reverse cursor-pointer mx-auto"
        [icon]="isMobileCoursePopupMinimized ? faChevronUp : faChevronDown"
      ></fa-icon>
    </div>
  </div>
  <!-- Minimized mobile course popup banner -->
  <div
    class="mobile-popup-banner-minimized p-4 bg-white flex md:hidden flex-row items-center rounded-t-md"
    [ngClass]="{ hidden: !isMobileCoursePopupMinimized }"
  >
    <div class="text-vis-regular font-extrabold text-lg line-through">${{ originalPrice }}</div>
    <div class="text-vis-top font-extrabold text-lg ml-3">${{ discountPrice }}</div>
    <vision-button class="popup-cta ml-6" defaultStyle="primary" [data]="ctaButtonData"></vision-button>
    <div
      class="icon-container h-[44px] w-[44px] ml-auto right-0 flex items-center absolute"
      (click)="toggleMobileCourseOverviewMinimize()"
    >
      <fa-icon
        class="fa-sm text-black cursor-pointer mx-auto"
        [icon]="isMobileCoursePopupMinimized ? faChevronUp : faChevronDown"
      ></fa-icon>
    </div>
  </div>
  <!-- Course popup content -->
  <div class="content py-v-spacing mx-v-spacing mx-auto flex flex-row items-center">
    <div class="flex flex-col w-full sm:w-auto">
      <span class="inline-block" *ngIf="data.eyebrow" [innerHTML]="data.eyebrow"></span>
      <div class="wysiwyg popup-title mb-v-spacing" [innerHTML]="data.sectionTitle"></div>
      <div *ngIf="data.showTrustpilotStars" class="mb-v-spacing">
        <div class="flex items-center cursor-pointer text-xs xxsm:text-sm xs:text-base" (click)="openTruspilot()">
          <img
            class="h-[24px]"
            height="24"
            width="106.67"
            src="https://xgrit-ecom.imgix.net/ace/trustpilot-star-rating.svg?v=1706889351"
            alt=""
          />
          <span *ngIf="trustpilotVerified" class="mx-2.5"
            ><span class="font-extrabold">{{ trustpilotTrustScore }}/5</span> Stars</span
          >
          <span *ngIf="trustpilotVerified"
            ><span class="font-extrabold">{{ trustpilotNumberOfReviews | number }}</span> Reviews</span
          >
        </div>
      </div>
      <!-- Course popup pricing -->
      <div class="flex pricing top-product__pricing mb-6">
        <div #prices  (click)="activateModalWrapper($event)" class="flex items-center prices" [innerHtml]="pricingText"></div>
      </div>
      <!-- Course popup CTA and guarantee modal link -->
      <div class="flex flex-col sm:flex-row sm:items-center">
        <vision-button defaultStyle="primary" [data]="ctaButtonData"></vision-button>
        <button
          *ngIf="!data.hideGuaranteeLink && !(this.wistiaButtonData?.wistiaId && this.ctaButtonData)"
          (click)="activateModal(data.modalContent)"
          class="tertiary-button font-bold w-fit m-auto sm:ml-3 sm:w-auto mt-4 sm:mt-auto !text-base"
          [class.cursor-pointer]="data.modalContent"
        >
          {{ moneyGuaranteeLabel }}
        </button>
      </div>
    </div>
  </div>
</div>
