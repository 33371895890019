import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { SafeHtml } from '@angular/platform-browser';
import { IconService } from '@core-mkt/services/iconService/icon.service';
import type { ComponentTheme, ThemeInterface } from '@core-mkt/services/theme-parser/theme-parser.service';
import { ThemeParserService } from '@core-mkt/services/theme-parser/theme-parser.service';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import type { VisionButton } from '@core-mkt/shared/components/vision-button/vision-button/vision-button';
import type { AccordionItem, AccordionSectionTitle } from './accordion';
import { Accordion } from './accordion';

@Component({
  selector: 'accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements OnInit {
  @Input() data: Accordion;
  @Input() isChild = false;
  theme: ThemeInterface;
  accordionItems: AccordionItem[] = [];
  showLeftContent = true;
  closeIcon: SafeHtml | null;
  openIcon: SafeHtml | null;

  constructor(
    private redactor: WysiwygRedactorService,
    private IconService: IconService,
    private themeParser: ThemeParserService,
  ) {}

  get accordionType(): string {
    return this.data.accordionDisplayType === 'boxed' ? ' bg-white' : '';
  }

  get accordionTitle(): string {
    return this.data.accordionTitle;
  }

  get accordionLeftContent(): string {
    return this.data.accordionLeftContent;
  }

  get accordionPadding(): string {
    if (!this.isChild || (!this.showLeftContent && this.showLeftContent !== null)) {
      return 'py-v-spacing sm:px-v-spacing';
    }
    if (!this.isChild && this.showLeftContent === false) {
      return 'py-v-spacing tab:px-v-spacing max-w-vision mx-auto';
    }
    return '';
  }

  get buttonData(): VisionButton {
    return {
      bgTheme: '',
      buttonLink: this.data.buttonLink,
      buttonText: this.data.buttonLabel,
      buttonStyle: this.data.buttonType,
      newTab: this.data.buttonLinkNewTab,
      nofollow: this.data.buttonLinkNofollow,
      noopener: this.data.buttonLinkNewTab,
      noreferrer: this.data.buttonLinkNewTab,
      ctaTag: this.buttonCtaTag,
      fullWidth: false,
    };
  }

  get buttonType(): string {
    return this.data.buttonType;
  }

  get buttonLabel(): string {
    return this.data.buttonLabel;
  }

  get buttonCtaTag(): string {
    return this.data.buttonCtaTag !== null ? this.data.buttonCtaTag : '';
  }

  get buttonLink(): string {
    return this.data.buttonLink;
  }

  get buttonLinkNewTab(): boolean {
    return this.data.buttonLinkNewTab;
  }

  get buttonLinkNofollow(): boolean {
    return this.data.buttonLinkNofollow;
  }

  get accordionImage(): boolean {
    return this.data.accordionItems.some((item: AccordionItem) => item.accordionImage?.[0]) ? true : false;
  }

  accordionImageURL(imgUrl: string): string {
    if (imgUrl.includes('auto=compress,format')) {
      return imgUrl;
    }
    return `${imgUrl}&auto=compress,format`;
  }

  async ngOnInit(): Promise<void> {
    this.showLeftContent = this.data.accordionLeftContent && this.data.accordionLeftContent.length > 0;
    this.data.accordionItems.forEach((item: AccordionItem) => {
      item.itemDescription = this.redactor.bypassSanitizer(item.itemDescription);
      item.itemTitle = this.redactor.bypassSanitizer(item.itemTitle);
      item.accordionSectionTitle.forEach((title: AccordionSectionTitle) => {
        title.sectionTitle = this.redactor.bypassSanitizer(title.sectionTitle);
      });
      this.accordionItems.push(item);
    });
    this.data.accordionTitle = this.redactor.bypassSanitizer(this.data.accordionTitle);
    this.data.accordionLeftContent = this.redactor.bypassSanitizer(this.data.accordionLeftContent);

    const themeInput: ComponentTheme = {
      styles: this.data.styles,
      columnCount: 0,
      columnDistribution: '',
    };

    this.theme = this.themeParser.getThemeClasses(themeInput);

    // Validate if the accordion is using a dark background
    const isDarkBackground = this.theme?.root?.includes('bg-vis-dark');
    /**
     * If the accordion is using a dark background, change the icon color to white
     * Otherwise, keep the brand color
     */
    if (isDarkBackground) {
      this.openIcon = this.redactor.bypassSanitizer(
        this.IconService.getBrandIcon('accordion-open')?.icon.replace('fill-vis-brand-1', 'fill-white'),
      );
      this.closeIcon = this.redactor.bypassSanitizer(
        this.IconService.getBrandIcon('accordion-close')?.icon.replace('fill-vis-brand-1', 'fill-white'),
      );
    } else {
      this.openIcon = this.redactor.bypassSanitizer(this.IconService.getBrandIcon('accordion-open')?.icon);
      this.closeIcon = this.redactor.bypassSanitizer(this.IconService.getBrandIcon('accordion-close')?.icon);
    }
  }

  toggle(accordionItem: AccordionItem) {
    // Check if there's an image associated with the accordion
    // If there is an image, only 1 description and 1 image can be shown at a time
    if (this.accordionImage) {
      // If the clicked item is already showing its description, hide it + image
      if (accordionItem.showDescription) {
        accordionItem.showDescription = false;
      } else {
        // If the clicked item is not showing its description, hide all descriptions + images
        this.data.accordionItems.forEach((accordionItem: AccordionItem) => {
          accordionItem.showDescription = false;
        });
        // Show the description + image of the clicked item
        accordionItem.showDescription = true;
      }
    } else {
      // If there's no image associated with the accordion, simply toggle the visibility of the description
      accordionItem.showDescription = !accordionItem.showDescription;
    }
  }
}
