import { DOCUMENT } from '@angular/common';
import type { OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { IconService } from '@core-mkt/services/iconService/icon.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { VisionModalService } from '../vision-modal.service';

@Component({
  selector: 'vision-modal',
  templateUrl: './vision-modal.component.html',
  styleUrls: ['./vision-modal.component.scss'],
})
export class VisionModalComponent implements OnInit {
  faTimes = faTimes;
  showModal = false;
  modalContent = '';

  constructor(
    private iconService: IconService,
    private visionModalService: VisionModalService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    this.document.addEventListener('keydown', (event: KeyboardEvent) => this.handleKeyDown(event));
    this.visionModalService.modalContent$.subscribe((modalData) => {
      this.showModal = modalData.showModal;
      this.modalContent = modalData.modalContent;
    });
  }

  handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Escape' || event.key === 'Enter') {
      this.showModal = false;
    }
  }

  handleBlur(id: string): void {
    if (id === 'close-modal') {
      this.document.getElementById('modal-content').focus();
    }
  }
}
