<section class="grid gap-3">
  <!-- Select Element -->
  <vision-select [isLoadingState]="isLoadingState" [isLoadingPlaceholder]="'Loading your state'"
    [placeholder]="'Select a State'" [extendClassStyles]="'custom-class'" [data]="states"
    (changeEvent)="handleStateChange($event)" [defaultSelectedOption]="stateFromGeolocation">
  </vision-select>
  <!-- Select Element -->
  <vision-select [disabled]="selectedProductsDisabled" [placeholder]="'Select a Product'"
    [extendClassStyles]="'custom-class'" [data]="this.selectedProducts"
    (changeEvent)="handleProductChange($event)">
  </vision-select>
  <!-- Link Button -->
  <vision-button class="mt-1" defaultStyle="primary" [data]="finalCtaButtonData"></vision-button>
</section>
