export const mockRoute = {
  snapshot: {
    queryParams: {
      testing: true,
    },
    data: {
      pageData: {
        apiData: {},
        productsData: {},
        craftData: {
          header: {
            aceGraphicPromoBanner: [
              {
                backgroundColor: 'bg-vis-dark text-vis-reverse',
                backgroundImage: [],
                textColor: '',
                image: [
                  {
                    url: 'https://xgrit-ecom.imgix.net/ace/AA_PLbanner_40off_2023-02-06-213027_atec.svg?v=1675719027',
                    altText: null,
                  },
                ],
                imageAlignment: 'flex-row',
                graphicBannerContent:
                  '<p class="eyebrow">NEW YEAR, NEW CAREER</p>\n<h2>Save an additional 40% with code <span class="text-ace-teal-600 font-extrabold">NEWYEAR40</span></h2>',
                promotionEndsDateTime: null,
                promotionStartDateTime: null,
              },
            ],
          },
        },
      },
    },
  },
};
