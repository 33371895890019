import { Injectable } from '@angular/core';
import { EnvService } from '@core-mkt/services/env/env.service';
import type { SitesDictionary } from 'libs/common/src/lib/accessibility/description-siblingSites.dictionary';
import { brandSites } from 'libs/common/src/lib/accessibility/description-siblingSites.dictionary';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  public brandSites: SitesDictionary[] = brandSites;

  constructor(public env: EnvService) {}

  get siblingSites(): string[] {
    return this.env.get.brandConfig.siblingSites;
  }

  getFullUrl(): string {
    return '';
  }

  getSeoUrl(): string {
    return '';
  }

  getProtocol(): string {
    return '';
  }

  getHost(): string {
    return '';
  }

  getHostname(): string {
    return '';
  }

  getPort(): string {
    return '';
  }

  getPathname(): string {
    return '';
  }

  getQueryParams(): { [key: string]: string } {
    return {};
  }

  getQueryParam(key: string): string | null {
    return null;
  }

  getHash(): string {
    return '';
  }

  /**
   * Gets the aria-label value to the anchor element
   * Removing dashes and slashes from a link
   * Validating type of url link
   * @param link The string with the URL
   * @param html The string with html code
   */
  getAriaLabel(link: string, html?: string): string {
    if (link === '/') {
      return 'Home';
    }
    if (link?.startsWith('http')) {
      return this.removeHTMLTags(html);
    }

    const sectionList = link?.split('/');
    if (sectionList[0] === '') {
      sectionList.shift();
    }

    sectionList.pop();
    sectionList.push(sectionList[0]);
    sectionList.shift();

    const firstReplae = sectionList.toString().replace(/\-/gi, ' ');
    const ariaLabel = firstReplae.replace(/\,/gi, ' ');
    return ariaLabel.replace(/(^\w{1})|(\s+\w{1})/g, (l) => l.toUpperCase());
  }

  /**
   * Remove html code from a string
   * @param html The string with html code
   */
  removeHTMLTags(html: string): string {
    if (html === null || html === '') {
      return '';
    }
    html = html?.toString();
    return html?.replace(/(<([^>]+)>)/gi, '');
  }

  /**
   * Gets the aria-labelledby value to the anchor element
   * Removing html code from a label
   * @param label The string with visible label link
   */
  getAriaLabelledby(label: string): string {
    const textLabel = label?.replace(/(<([^>]+)>)/gi, '');
    const withoutSpaces = textLabel?.replace(/\s/g, '-').toLowerCase();
    const cleanText = withoutSpaces?.toString().replace(/\--/gi, '-');
    return cleanText;
  }
  
  /**
   * Gets context information value for hidden element
   * @param link string with the URL link
   * @param html The string with html code
   */
  getCompleteTextContextForButton(link: string, html?: string): string {
    if (this.isUrlSite(link)) {
      return this.getSiteDescription(link);
    }
    return this.getAriaLabel(link, html);
  }

  /**
   * Method to validate specific URL link to return an specific aria labelledby context
   * @param link string with the URL link
   * @param compareLink string with the URL link to compare
   * @param ariaContext The string with context value
   */
  checkAriaLabelledbyContext(link: string, compareLink: string, ariaContext: string): string {
    if (link === compareLink) {
      return ariaContext;
    }
    return undefined;
  }

  getTextContext(link: string, label?: string): string {
    if (link?.startsWith('http')) {
      return this.getContextToDomainLink(link, label);
    }
    return this.getCompleteTextContextForButton(link);
  }

  isUrlSite(link: string): boolean {
    return this.siblingSites?.some((site) => link.includes(site));
  }

  getContextToDomainLink(link: string, label?: string): string {
    if (this.isUrlSite(link)) {
      return this.getSiteDescription(link);
    }
    return this.getAriaLabel(link, label);
  }

  getSiteDescription(urlDomain: string): string {
    return this.brandSites.find((site) => site.domain === urlDomain)?.description;
  }
}
