import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { UserLocationResponse } from '@core-mkt/interfaces/geolocation';
import { GeolocationService } from '@core-mkt/services/geolocation/geolocation.service';
import { map } from 'rxjs';
import type { ProductPicker } from '../../layout/layout/layout';
import { VisionButton } from '../../vision-button/vision-button/vision-button';

@Component({
  selector: 'product-picker',
  templateUrl: './product-picker.component.html',
})
export class ProductPickerComponent implements OnInit {
  @Input() data: ProductPicker[]; // Data from the parent component
  @Input() ctaButtonData: VisionButton; // If data from parent component needs to override CTA button data
  states: { value: string; label: string }[] = []; // States to show on the select field
  selectedProducts: { value: string; label: string; href: string; noFollow: boolean; noReferrer: boolean }[] = []; // Products to show on the select field
  stateFromGeolocation: string; // State from geolocation
  selectedProductsDisabled = true; // Disable the select field if no products are selected
  stateAbbrFromCookie: string; // State abbreviation from cookie
  isLoadingState: boolean; // Loading state for geolocation

  defaultCtaButtonData: VisionButton = {
    bgTheme: 'bg-white',
    buttonText: 'Get My License',
    buttonLink: '',
    fullWidth: true,
    nofollow: false,
    noreferrer: false,
    noopener: false,
    newTab: false,
    useIcon: true,
  };

  finalCtaButtonData: VisionButton = {
    bgTheme: 'bg-white',
    buttonText: 'Get My License',
    buttonLink: '',
    fullWidth: true,
    nofollow: false,
    noreferrer: false,
    noopener: false,
    newTab: false,
    useIcon: true,
  };

  constructor(public geolocation: GeolocationService) {
    this.geolocation?.isLoading.subscribe((isLoading) => {
      this.isLoadingState = isLoading;
    });
  }

  ngOnInit() {
    // Set the states for the product picker
    this.setStates();
    // Get the user location process
    if (!this.geolocation.getLocationCookie()) {
      // If we don't have a cookie, get the user location
      this.getUserLocation();
    } else {
      // If we have a cookie, get the user location from the cookie
      this.getUserLocationFromCookie();
    }

    // Use the provided CTA data if available, otherwise use default button data above
    this.finalCtaButtonData = this.ctaButtonData || this.defaultCtaButtonData;
  }

  /**
   * Retrieves the user's location from the cookie.
   * Sets the state abbreviation from the cookie and gets the corresponding state name from geolocation.
   * If the state name is available, it calls the handleStateChange method with the state name.
   * Finally, it sets the isLoadingState flag to false.
   */
  getUserLocationFromCookie(): void {
    this.stateAbbrFromCookie = this.geolocation.getLocationCookie();
    this.stateFromGeolocation = this.geolocation.getStateName(this.stateAbbrFromCookie);

    if (this.stateFromGeolocation) {
      this.handleStateChange(this.stateFromGeolocation);
    }

    this.geolocation.setIsLoadingState(false);
  }

  /**
   * Retrieves the user's location and performs necessary actions based on the location.
   */
  getUserLocation(): void {
    this.geolocation.setIsLoadingState(true);

    this.geolocation
      .getUserLocation()
      .pipe(
        map((location: UserLocationResponse) => {
          return location.stateabbr;
        }),
      )
      .subscribe((state) => {
        this.geolocation.setLocationCookie(state);
        this.geolocation.setStateAbbr(state);

        this.stateFromGeolocation = this.geolocation.getStateName(state);
        // If we have a state name, call the handleStateChange method
        if (this.stateFromGeolocation) {
          this.handleStateChange(this.stateFromGeolocation);
        }
        this.geolocation.setIsLoadingState(false);
      });
  }

  /**
   * Sets the states for the product picker.
   */
  setStates() {
    if (this.data[0]?.productPickerTable !== null && this.data[0]?.productPickerTable !== undefined) {
      this.data[0].productPickerTable.forEach((product) => {
        if (product && !this.states.some((state) => state.value === product.state)) {
          this.states.push({ value: product.state, label: product.state });
        }
      });
    }
  }

  /**
   * Handles the state change event and updates the selected products based on the selected state.
   * @param state - The new state value.
   */
  handleStateChange(state: string): void {
    const stateAbbr = this.geolocation.getStateAbbr(state);
    // Reset the selected products on state change
    this.selectedProducts = [];
    // Search for the product
    this.data[0]?.productPickerTable.find((item) => {
      if (item.state === state) {
        this.selectedProducts.push({
          value: item.productName,
          label: item.productName,
          href: item.productLink,
          noFollow: item.noFollow,
          noReferrer: item.noReferrer,
        });
      }
    });
    // Update the cookie value if user selects a different state
    this.geolocation.updateLocationCookie(stateAbbr);
    // If we have selected products, enable the select
    if (this.selectedProducts.length) {
      this.selectedProductsDisabled = false;
    }
  }

  /**
   * Handles the change event of the product picker.
   * Updates the finalCtaButtonData based on the selected product.
   *
   * @param value - The value of the selected product.
   */
  handleProductChange(value: string) {
    this.selectedProducts.filter((product) => {
      if (product.value === value) {
        this.finalCtaButtonData.buttonLink = product.href;
        this.finalCtaButtonData.nofollow = product.noFollow;
        this.finalCtaButtonData.noreferrer = product.noReferrer;
      }
    });
  }
}
