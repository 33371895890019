import { DOCUMENT } from '@angular/common';
import type { AfterViewInit, OnInit } from '@angular/core';
import { Component, HostListener, Inject, Input } from '@angular/core';
import type { SafeHtml } from '@angular/platform-browser';
import { EnvService } from '@core-mkt/services/env/env.service';
import { HubspotService } from '@core-mkt/services/hubspot/hubspot.service';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import type { HubspotMultiForm } from './hubspot';
import { Hubspot } from './hubspot';

@Component({
  selector: 'hubspot',
  templateUrl: './hubspot.component.html',
})
export class HubspotComponent implements AfterViewInit, OnInit {
  @Input() data: Hubspot;
  formData = [];
  currentStep = 0;

  constructor(
    private redactor: WysiwygRedactorService,
    private hubspotService: HubspotService,
    private env: EnvService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  get hubspotID(): string {
    const hubspotId = this.env.get.brandConfig.hubspotID;
    return this.data.hubspotType ? this.data.hubspotType : hubspotId;
  }

  get isAceHubspotType(): boolean {
    return this.hubspotID !== '8812b0a9-131e-4f90-8139-043c3fb1d357';
  }

  get hubspotContent(): SafeHtml {
    return this.data.hubspotContent;
  }

  get multiform(): HubspotMultiForm[] {
    return this.hubspotService.multiform;
  }

  ngOnInit(): void {
    this.data.hubspotContent = this.redactor.bypassSanitizer(this.data.hubspotContent);
  }

  ngAfterViewInit(): void {
    this.hubspotService.initForms(this.hubspotID);
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: any) {
    if (this.isAceHubspotType) {
      return;
    }
    const eventData = event.data;
    if (eventData.type === 'hsFormCallback' && eventData.eventName === 'onFormReady') {
      this.blockForm();
      const step = this.multiform.find((f) => f.formId === eventData.id)?.step;
      this.addProgressBarToForm(step);
    } else if (eventData.type === 'hsFormCallback' && eventData.eventName === 'onFormSubmit') {
      this.currentStepDone(eventData.data);
    }
  }

  getActiveForm(): HTMLFormElement {
    const iframe: HTMLIFrameElement = this.document.getElementById(this.multiform[this.currentStep].id)
      .firstElementChild as HTMLIFrameElement;
    const innerDocument = iframe?.contentDocument || iframe?.contentWindow.document;
    return innerDocument?.getElementById(`hsForm_${this.multiform[this.currentStep].formId}`) as HTMLFormElement;
  }

  blockForm(): void {
    if (this.currentStep !== 2) {
      const form = this.getActiveForm();
      if (form) {
        form.action = '';
      }
    }
  }

  currentStepDone(data: any): void {
    if (this.currentStep !== 2) {
      this.formData.push(...data);
      this.currentStep++;
      if (this.currentStep === 2) {
        const form = this.getActiveForm();
        this.formData.forEach((e) => {
          const el: any = form.elements.namedItem(e.name);
          el.value = e.value;
        });
      }
    }
  }

  addProgressBarToForm(step: number): void {
    const iframe: any = this.document.getElementById(this.multiform[step]?.id)?.firstElementChild;
    const innerDocument = iframe?.contentDocument || iframe?.contentWindow.document;
    const subTitleElement = innerDocument?.getElementsByTagName('h3')[0];
    const parentElement = subTitleElement?.parentElement;
    if (parentElement) {
      parentElement.style.cssText = `text-align: center;`;
      this.addDivider(parentElement);
      this.addStepsBar(parentElement, step);
    }
  }

  addDivider(element: Element): void {
    const divider = this.document.createElement('hr');
    divider.style.cssText = `border-top: 1px solid #eeeeee;margin-top: 18px;`;
    element.appendChild(divider);
  }

  addStepsBar(element: Element, step: number): void {
    const progressBar = [];
    progressBar.push(this.document.createElement('span'));
    progressBar.push(this.document.createElement('span'));
    progressBar.push(this.document.createElement('span'));
    progressBar.forEach((s, index) => {
      if (index === step) {
        progressBar[index].style.cssText = `
          height: 12px;
          width: 12px;
          background-color: #33475b;
          border-radius: 50%;
          display: inline-block;
          margin-left: -12px;
          margin-right: 25px;
          margin-top: 10px;
          `;
      } else {
        progressBar[index].style.cssText = `
          height: 12px;
          width: 12px;
          background-color: #ffffff;
          border-radius: 50%;
          display: inline-block;
          margin-left: -12px;
          margin-right: 25px;
          margin-top: 10px;
          border-style: solid;
          border-width: thin;
          border-color: gray;
          `;
      }
      element.appendChild(progressBar[index]);
    });
  }
}
