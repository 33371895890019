import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';

@Component({
  selector: 'study-builder-wyswyg-content',
  templateUrl: './wyswyg-content.component.html',
})
export class WyswygContentComponent implements OnInit {
  @Input() data: string; // Prop for the content
  @Input() isCenterAligned: boolean; // Prop for the content alignment
  content: string; // Sanitized content to be displayed

  constructor(private redactor: WysiwygRedactorService) {}

  ngOnInit(): void {
    this.content = this.redactor.bypassSanitizer(this.data);
  }
}
