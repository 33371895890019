/* eslint-disable prettier/prettier */
import { BundlesFragment } from '@core-mkt/shared/craft/bundles-fragment';
import * as gql from 'fake-tag';
import { HeaderFragment, SettingsFragment } from './fragments/header-fragment-query';
import { SEOBundlesREFragment } from './fragments/seo-fragment-query';

export const TemplateBundlesQuery = {
  name: 'TemplateBundlesQuery',
  query: gql`
    query ($site: [String], $slug: [String]) {
      entryData: entries(site: $site, slug: $slug) {
        slug
        sectionHandle
        typeHandle
        ... on aceRealEstateTemplates_bundlesTemplate_Entry {
          hideStickyBanner
          bundlesTemplate {
            ...bundlesTemplate_fragment
          }
        }
      }
      feefo: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_bundlesTemplate_Entry {
          feefoProductId
        }
      }
      seoContent: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_bundlesTemplate_Entry {
          ...RealEstateSEOFragmentEntry
        }
      }
      styles: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_bundlesTemplate_Entry {
          embedStyles
        }
      }
      optimizely: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_bundlesTemplate_Entry {
          optimizelyEnabled
        }
      }
      globalOptimizely: globalSets(handle: "globalOptimizely") {
        ... on globalOptimizely_GlobalSet {
          optimizelyAceAgentTemplates {
            ... on optimizelyAceAgentTemplates_aceAgentTemplateTypes_BlockType {
              optimizelyEnabled: bundlesAATemplateOpti
            }
          }
        }
      }
      structuredData: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_bundlesTemplate_Entry {
          structuredData
        }
      }
      overrides: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_bundlesTemplate_Entry {
          aceHeaderPageSettings {
            ...SettingsFragment
          }
        }
      }
      header: globalSets(handle: "aceHeader") {
        ... on aceHeader_GlobalSet {
          ...HeaderFragmentEntry
        }
      }
    }
    fragment productEntry_fragment on EntryInterface {
      ... on aceRealEstateProducts_xgritProduct_Entry {
        productPageUrl
        productState
        xgritBundleCoupon
        xgritCoupon
        xgritProductDisableRsa
        xgritProductId
        aceRealEstateProductCategory {
          ... on realEstateCategories_Category {
            aceCategoryCode
            title
          }
        }
      }
    }
    ${HeaderFragment.header}
    ${SettingsFragment.settings}
    ${SEOBundlesREFragment.seoContent}
    ${BundlesFragment.bundlesTemplate}
  `,
};
