<div [className]="theme?.root + ' bg-no-repeat bg-center bg-cover'" [style]="theme?.bgImage">
	<div class="container {{ theme.container }}">
	  <div
		*ngIf="topContent"
		class="wysiwyg py-v-spacing tab:px-v-spacing max-w-vision mx-auto bundle__top-content"
		[innerHtml]="topContent"
	  ></div>
	  <div class="mx-auto w-fit">
		<!-- Desktop View -->
		<div
		  id="bundle"
		  class="auto-cols-min  bundle-container md:grid grid-cols-1 justify-items-center desktop-grid {{bundleContainerStyles}}"
		>
		  <div class="bundle-xl-space lg:col-span-1 {{bundleContainerStylesColSpan}}"></div>
		  <ng-template ngFor let-b [ngForOf]="bundles" let-last="last" let-index="index">
			<div
			  class="pt-v-spacing {{ b.topContent ? 'top-bundle-with-top':'top-bundle-without-top' }} w-full flex flex-col bundle-product"
			  [ngClass]="bundles.length > 2 ? { 'basic-column': index === 0, 'deluxe-column': index === 1, 'premium-column': index === 2 } : { 'basic-column': index === 0, 'premium-column': index === 1}"
			>
			  <div
				class="rounded-t-md w-full bundle-top"
				[ngClass]="b.topContent ? 'bg-vis-teal-2 text-vis-reverse' + ' border-t border-r border-l' : ''"
			  >
				<span
				  class="text-sm font-extrabold rounded-t-md inline-block uppercase py-2 px-6 w-full"
				 [ngClass]="b.topContent ? 'bg-vis-teal-2 text-vis-reverse' : ''"
				  >{{ b.topContent }}</span
				>
			  </div>
			  <div
				class="w-full flex flex-col h-full bg-white text-vis-regular rounded-b-md-top border-top-bundle {{b.topContent ? 'rounded-t-none' : 'rounded-t-md-top'}}"
			  >
				<div class="wysiwyg p-v-spacing xl:pb-6 pb-4" [innerHTML]="b.principalContent"></div>
				<div class="flex flex-col h-full justify-end">
				  <div class="w-full pt-0 xl:pb-4 pb-2 p-v-spacing">
					<div [class]="b.savePercentage ? '' : 'invisible'" class="text-xs font-bold text-vis-brand-2">
					  SAVE <span *ngIf="b.discountType === 'PERCENT'">{{ b.savePercentage }}%</span><span *ngIf="b.discountType === 'FLAT'">${{ b.maxPrice - b.originalPrice }}</span>
					</div>
					<div class="flex">
					  <span
						*ngIf="b.maxPrice && b.savePercentage"
						class="text-lg font-extrabold price-cross-color line-through decoration-vis-dark text-vis-regular mr-1"
						>${{ b.maxPrice | customNumber : '1.0-0' }}</span
					  >
					  <span #bundlePrice *ngIf="b.originalPrice" class="text-lg font-extrabold text-vis-teal">
						${{ b.originalPrice | customNumber : '1.0-0' }}</span
					  >
					  <span
						*ngIf="couponApplied"
						class="coupon-applied text-[13px] font-bold text-vis-brand-2 ml-3 rounded-[30px] mb-[30px] py-[7px] px-[15px] bg-[#d9e7f2]"
						>Coupon Applied!</span
					  >
					</div>
				  </div>
				  <div *ngIf="b.originalPrice >= 50" class="pb-4 text-xs text-vis-grey-4 px-v-spacing">
					<span
					  >As low as ${{ b.affirmPrice }}/mo with
					  <img class="inline-block affirm-img" src="https://xgrit-ecom.imgix.net/ace/affirm-logo.svg" />
					  †, or 4 interest-free payments of ${{ b.klarnaPrice }} with
					  <img class="inline-block klarna-img" src="https://xgrit-ecom.imgix.net/ace/klarna-logo.svg" />
					  ††
					</span>
				  </div>
				  <div class="w-full pt-0 p-v-spacing items-center">
					<div class="w-full xs:mr-v-spacing lg:mr-0 xl:mr-v-spacing">
					  <vision-button [data]="getButtonData(b)" [className]="'basic-cta_desktop'"></vision-button>
					</div>
					<div class="w-full mt-2 text-center text-xs cursor-pointer text-vis-subtle" (click)="activateModal()">
					  <span *ngIf="b.product[0] && b.product[0].productState !== 'FL'" class="inline-block"
						>{{guaranteeText}}</span
					  >
					</div>
				  </div>
				  <div class="p-v-spacing w-full mt-4" *ngIf="b.bottomContent" [innerHTML]="b.bottomContent"></div>
				</div>
			  </div>
			</div>
		  </ng-template>
		</div>
		<!--end bundle-->
		<div class="pricing-table-container mt-12 lg:mt-0">
		  <div class="pricing-table w-full mx-auto" id="pricing-table" *ngIf="bundlePricingItems">
			<div class="pricing-table-header mx-auto w-full stuck border-b rounded-t-none sticky top-0">
				<div class="pricing-table-row grid w-full auto-cols-min {{bundleStylesColsBig}}">
					<div class="pricing-table-row-label lg:col-span-1 {{bundleContainerStylesColSpan}}"></div>
					<div class="pricing-table-row-package rounded-t-none text-vis-regular basic-column"><strong class="text-vis-regular">{{bundleTitleOne}}</strong></div>
					<div class="pricing-table-row-package rounded-t-none text-vis-regular"  [ngClass]="bundles.length > 2 ? 'deluxe-column': 'premium-column'"><strong class="text-vis-regular">{{bundleTitleTwo}}</strong></div>
					<div *ngIf="bundles.length > 2"  class="pricing-table-row-package rounded-t-none text-vis-regular premium-column"><strong class="text-vis-regular">{{bundleTitleThree}}</strong></div>
				</div>
			</div>
			<div class="pricing-table-boddy mx-auto w-full">
        		<ng-template ngFor let-bpi [ngForOf]="bundlePricingItems" let-last="last" let-index="index">
          			<div class="pricing-table-row grid w-full auto-cols-min {{bundleStylesColsBig}}">
						<div class="pricing-table-row-label lg:col-span-1 {{bundleContainerStylesColSpan}}" 
							[innerHTML]="bpi.principalContent"
							></div>
						<div class="pricing-table-row-package rounded-t-none"><span class="icon w-7 h-7 bg-no-repeat bg-center bg-contain inline-block {{bpi. productOne ? 'icon-checked':'icon-unchecked'}}"></span></div>
						<div class="pricing-table-row-package rounded-t-none"><span class="icon w-7 h-7 bg-no-repeat bg-center bg-contain inline-block {{bpi.productTwo ? 'icon-checked':'icon-unchecked'}}"></span></div>
						<div *ngIf="bundles.length >2" class="pricing-table-row-package rounded-t-none"><span class="icon w-7 h-7 bg-no-repeat bg-center bg-contain inline-block {{bpi.productThree ? 'icon-checked':'icon-unchecked'}}"></span></div>
         			 </div>
        		</ng-template>
		  	</div>
		</div>

		<div
		  id="bundle"
		  class="auto-cols-min  bottom-bundle-container grid justify-items-center desktop-grid {{bundleContainerStyles}}"
		>
			<div class="bundle-xl-space hidden lg:block lg:col-span-1 {{bundleContainerStylesColSpan}}"></div>
				<ng-template ngFor let-b [ngForOf]="bundles" let-last="last" let-index="index">
					<div
					class="{{ b.topContent ? 'top-bundle-with-top':'top-bundle-without-top' }} w-full flex flex-col bottom-bundle-with-top rounded-t-md-bottom bundle-product"
					>
						<div
							class="rounded-t-md w-full bottom-bundle-top"
							[ngClass]="b.topContent ? 'bg-vis-teal-2 text-vis-reverse' + ' border-t border-r border-l' : ''"
						>
							<span
								class="text-sm font-extrabold rounded-t-md inline-block uppercase py-2 px-6 w-full"
								[ngClass]="b.topContent ? 'bg-vis-teal-2 text-vis-reverse' : ''"
							>
								{{ b.topContent }}
							</span>
						</div>
						<div
							[ngClass]="bundles.length > 2 ? { 'basic-column': index === 0, 'deluxe-column': index === 1, 'premium-column': index === 2 } : { 'basic-column': index === 0, 'premium-column': index === 1}"
							class="w-full flex flex-col h-full bg-white text-vis-regular rounded-b-md-bottom bottom-bundle {{!b.topContent ? 'rounded-t-md-bottom' : ''}}"
						>
							<div class="wysiwyg p-v-spacing-bottom-bundle text-vis-regular">
								<p class="large text-vis-regular"><strong class="text-vis-regular">{{index === 0 ? bundleTitleOne :''}} {{index === 1 ? bundleTitleTwo : ''}} {{index === 2 ? bundleTitleThree : ''}}</strong></p>
							</div>
							<div class="flex flex-col h-full">
								<div class="w-full pt-0 pb-4 p-v-spacing-bottom-bundle-container pb-3">
									<div class="flex">
										<span
											*ngIf="b.maxPrice && b.savePercentage"
											class="sm:text-lg text-base font-extrabold price-cross-color line-through decoration-vis-dark text-vis-regular mr-1"
										>
											${{ b.maxPrice | customNumber : '1.0-0' }}
										</span>
										<span #bundlePrice *ngIf="b.originalPrice" class="sm:text-lg text-base font-extrabold text-vis-teal">
											${{ b.originalPrice | customNumber : '1.0-0' }}
										</span>
										<span
											*ngIf="couponApplied"
											class="coupon-applied text-[13px] font-bold text-vis-brand-2 ml-3 rounded-[30px] mb-[30px] py-[7px] px-[15px] bg-[#d9e7f2]"
										>
											Coupon Applied!
										</span>
									</div>
								</div>
								<div class="w-full pt-0 p-v-spacing-bottom-bundle-buttom items-center">
									<div class="w-full lg:mr-0 xl:mr-v-spacing bottom-bundle-button-small">
										<vision-button [data]="getButtonData(b, 'bottom-bundle-small')" [className]="'basic-cta_desktop'"></vision-button>
									</div>

									<div class="w-full lg:mr-0 xl:mr-v-spacing bottom-bundle-button">
										<vision-button [data]="getButtonData(b, 'bottom-bundle')" [className]="'basic-cta_desktop'"></vision-button>
									</div>

									<div class="w-full mt-2 text-center text-xs cursor-pointer text-vis-subtle pb-3" (click)="activateModal()">
										<span *ngIf="b.product[0] && b.product[0].productState !== 'FL'" class="inline-block">
											{{guaranteeText}}
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</ng-template>
			</div>
		<!-- End Desktop View -->
	  	</div>
	  <div class="bnpl-disclaimer mt-12">
		<div class="flex">
		  <div class="bullet">†</div>
		  <div>
			<p>
			  Payment options are offered by Affirm and are subject to an eligibility check. Options depend on your
			  purchase amount, and a down payment may be required. CA residents: Loans by Affirm Loan Services, LLC are
			  made or arranged pursuant to a California Finance Lender license. For licenses and disclosures, see
			  <a target="_blank" href="https://www.affirm.com/licenses">affirm.com/licenses</a>.
			</p>
		  </div>
		</div>
		<div class="flex">
		  <div class="bullet">††</div>
		  <div>
			<p>
			  See Klarna payment
			  <a href="https://cdn.klarna.com/1.0/shared/content/legal/terms/0/en_us/sliceitinx" target="_blank">terms</a
			  >. A higher initial payment may be required for some consumers. CA resident loans made or arranged pursuant
			  to a California Financing Law license.
			</p>
		  </div>
		</div>
	  </div>
	</div>
  </div>
