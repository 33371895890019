import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { InsertableModule } from '@aceable/core';
import { MarketingModule } from '@core-mkt/shared/components/marketing/marketing.module';

import { FooterModule as AAFooterModule } from '@core-mkt/aa/src/app/components/footer/footer.module';
import { CareerCenterModule } from '@core-mkt/shared/components/career-center/career-center.module';
import { EmailFormModule } from '@core-mkt/shared/components/email-form/email-form.module';
import { GladlyHelpCenterModule } from '@core-mkt/shared/components/gladly-help-center/gladly-help-center.module';
import { GraphicBannerModule } from '@core-mkt/shared/components/graphic-banner/graphic-banner.module';
import { VisionModalModule } from '@core-mkt/shared/components/vision-modal/vision-modal.module';
import { AccordionModule } from '../../components/accordion/accordion.module';
import { AmbassadorInviteModule } from '../../components/ambassador-invite/amssador-invite.module';
import { AmbassadorReferralModule } from '../../components/ambassador-referral/ambassador-referral.module';
import { BreadcrumbModule } from '../../components/breadcrumb/breadcrumb.module';
import { CareersModule } from '../../components/careers/careers.module';
import { HubspotModule } from '../../components/hubspot/hubspot.module';
import { LayoutModule } from '../../components/layout/layout.module';
import { LearnMoreModule } from '../../components/learn-more/learn-more.module';
import { MultiCardModule } from '../../components/multi-card/multi-card.module';
import { MultiColumnModule } from '../../components/multi-column/multi-column.module';
import { OneThreeColumnModule } from '../../components/one-three-column/one-three-column.module';
import { ResourcesModule } from '../../components/resources/resources.module';
import { ReviewsModule } from '../../components/reviews/reviews.module';
import { TrustpilotModule } from '../../components/trustpilot/trustpilot.module';
import { TemplateGenericComponent } from './template-component/template-generic.component';
import { TemplateGenericRoutingModule } from './template-generic-routing.module';

@NgModule({
  declarations: [TemplateGenericComponent],
  exports: [TemplateGenericComponent],
  imports: [
    CommonModule,
    MarketingModule,
    InsertableModule,
    TemplateGenericRoutingModule,
    OneThreeColumnModule,
    BreadcrumbModule,
    MultiColumnModule,
    VisionModalModule,
    MultiCardModule,
    TrustpilotModule,
    ReviewsModule,
    CareersModule,
    CareerCenterModule,
    LayoutModule,
    AccordionModule,
    HubspotModule,
    ResourcesModule,
    AmbassadorReferralModule,
    AmbassadorInviteModule,
    LearnMoreModule,
    GraphicBannerModule,
    EmailFormModule,
    AAFooterModule,
    GladlyHelpCenterModule,
  ],
})
export class TemplateGenericModule {}
