import type { CraftXGritProductEntry } from '@core-mkt/interfaces/craft-xgrit-product-entry';
import type { Image } from '@core-mkt/interfaces/image';

export interface EmailForm {
  backgroundColor: string;
  styles: string[];
  backgroundImage: Image[];
  textColor: string;
  formContent: string;
  formType: boolean;
  hideContentOnMobile: boolean;
  formVertical: string;
  showParentEmailField: boolean;
  showPhoneNumberField: boolean;
  hideEmailField: boolean;
  showNameField: boolean;
  subscriptionListIds: string;
  imageAlignment: string;
  emailFormImage: Image[];
  product?: CraftXGritProductEntry;
  ctaType: string;
  ctaLabel: string;
}

export interface FormPayload {
  listId: number;
  subscribers: SubScriber[];
  type: string;
  brandId?: string;
}

export interface DeResponse {
  data: {
    successCount: number;
    failCount: number;
    invalidEmails: string[];
    invalidUserIds: string[];
  };
  status: number;
  statusText: string;
  headers: {
    'content-length': string;
    'content-type': string;
  };
  config: {
    transitional: {
      silentJSONParsing: boolean;
      forcedJSONParsing: boolean;
      clarifyTimeoutError: boolean;
    };
    transformRequest: any[];
    transformResponse: any[];
    timeout: number;
    xsrfCookieName: string;
    xsrfHeaderName: string;
    maxContentLength: number;
    maxBodyLength: number;
    headers: {
      Accept: string;
      'Content-Type': string;
    };
    method: string;
    url: string;
    data: string;
  };
}

export interface SubScriber {
  dataFields: {
    brandId?: string;
    studentFirstName?: string;
    studentEmail?: string;
    parentEmail?: string;
    phoneNumber?: string;
    studentCourseState?: string;
    studentCourseType?: string;
    userType?: string;
    productType?: string;
    courseState?: string;
    courseType?: string;
  };
  email: string;
}

export enum UserType {
  Student = 'student',
  Parent = 'parent',
  Unknown = '',
}
