import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import type { Renderer2 } from '@angular/core';
import { Inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { EnvService } from '../env/env.service';
import type { WistiaApiData } from './wistia';

@Injectable({
  providedIn: 'root',
})
export class ServerWistiaService {
  regExp = /\[wistia id="(.*?)" type="(.*?)"\]/;
  renderer: Renderer2;
  defaultDescription =
    'See how Aceable’s online real estate school is the fast and efficient choice. Get ready to pass the state exam with confidence through bite-sized lessons and practice questions. ';
  wistiaObject: WistiaApiData;

  constructor(@Inject(DOCUMENT) public document: Document, private env: EnvService, readonly httpClient: HttpClient) {}

  scriptLoadedSubject = new Subject();

  /**
   * createWistiaPosterEl created poster elements and attached events to poster for lazy load purposes
   *
   * @param {string} wistiaId - Wistia Id
   *  @param {string} el - element where posted will be inserted into
   *
   */
  private createWistiaPosterEl = async (wistiaId: string, el: Element) => {
    const playClasses =
      'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-32 h-20 flex justify-center items-center cursor-pointer';
    const posterClass = 'poster';
    const imgId = `img_${wistiaId}`;
    const svgId = `svg_${wistiaId}`;
    const durId = `dur_${wistiaId}`;
    const poster = this.renderer.createElement('div');
    poster.classList.add(posterClass, 'group');
    const time = (this.wistiaObject.duration / 60).toFixed(2);
    //look at the original image and determine if image is in portrait or landscape mode
    const portraitMode = this.wistiaObject.assets[0].height > this.wistiaObject.assets[0].width;
    this.wistiaObject.thumbnail.url =
      this.wistiaObject.thumbnail.url.match(/^[^?]+/)[0] +
      '?image_crop_resized=' +
      (portraitMode ? '360x640' : '672x366');
    poster.innerHTML = `
      <img id="${imgId}" class="mb-3 cursor-pointer ${posterClass}" src="${this.wistiaObject.thumbnail.url}" width="100%" height="360px" alt="Wistia video poster" />
      <div id="${svgId}-container" class="${playClasses} h-20 bg-wistia-play">
        <svg class="fill-current text-vis-reverse w-8" id="${svgId}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
          <path d="M361 215C375.3 223.8 384 239.3 384 256C384 272.7 375.3 288.2 361 296.1L73.03 472.1C58.21 482 39.66 482.4 24.52 473.9C9.377 465.4 0 449.4 0 432V80C0 62.64 9.377 46.63 24.52 38.13C39.66 29.64 58.21 29.99 73.03 39.04L361 215z"/>
        </svg>
        <div id="spinner-${wistiaId}" class="spinner-border animate-spin inline-block w-10 h-10 rounded-full hidden" role="status">
          <span class="visually-hidden">
            <svg
              className="animate-spin -inline-block w-8 h-8 border-4 rounded-full"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                cx="12"
                cy="12"
                r="10"
                stroke="white"
                strokeWidth="4"
              ></circle>
              <path
                fill="white"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </span>
        </div>
        <span class="hidden text-vis-reverse absolute top-2/3 left-1/2 transform -translate-x-1/2 -translate-y-2/3" id="${durId}">${time}</span>
      </div>
    `;

    poster.onmouseenter = () => {
      this.document.getElementById(`${svgId}-container`).classList.add('w-full', 'h-full');
      this.document.getElementById(`${svgId}-container`).classList.remove('w-32', 'h-20');
      this.document.getElementById(svgId).classList.remove('w-32', 'h-20');
      this.document.getElementById(durId).classList.remove('hidden');
    };
    poster.onmouseleave = () => {
      this.document.getElementById(svgId).classList.add('w-32', 'h-20');
      this.document.getElementById(durId).classList.add('hidden');
      this.document.getElementById(`${svgId}-container`).classList.add('w-32', 'h-20');
      this.document.getElementById(`${svgId}-container`).classList.remove('w-full', 'h-full');
    };
    poster.onclick = () => {
      const videoContainer = this.document.createElement('div');
      videoContainer.innerHTML = `
        <div class="wistia_responsive_padding invisible !absolute" id="wistia-padding" style="padding:56.25% 0 0 0;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><span class="wistia_embed wistia-hero wistia_async_${wistiaId} popover=true popoverAnimateThumbnail=true videoFoam=true autoPlay=true" style="display:inline-block;height:100%;position:relative;width:100%">&nbsp;</span></div></div>
      `;
      el.parentElement.append(videoContainer);
      this.loadVideoScripts(wistiaId);
      this.document.getElementById(svgId).classList.add('hidden');
      this.document.getElementById(`spinner-${wistiaId}`).classList.remove('hidden');
    };

    this.waitForElement('[id*="wistia-' + wistiaId + '"]', wistiaId);
    this.renderer.appendChild(el, poster);
  };

  /**
   * Wait for an element before resolving a promise
   * @param {String} querySelector - Selector of element to wait for
   * @param {String} wistiaId - ID for wistia video
   */
  waitForElement(querySelector, wistiaId) {}

  parseDuration(duration: number) {
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);
    return `PT${minutes}M${seconds}S`;
  }

  /**
   * parseStuctureData parses wistia api data into data structure expected by google
   *
   * @param {string} wistiaId - Wistia Id
   *
   */
  parseStuctureData(wistiaId: string) {
    const formattedDuration = this.parseDuration(this.wistiaObject.duration);
    const desc = this.wistiaObject.description === '' ? this.defaultDescription : this.wistiaObject.description;
    const wistAsset = this.wistiaObject.assets.find((x) => x.type === 'IphoneVideoFile' || x.type === 'VideoObject');
    const lastIndex = wistAsset?.url.lastIndexOf('/');
    const path = wistAsset?.url.substring(0, lastIndex);
    const contentType = wistAsset?.contentType.split('/')[1];
    const contentUrl = wistAsset?.url.split('.bin')[0];

    const wistia = `
          {
            "@context": "https://schema.org",
            "@type": "VideoObject",
            "name": "${this.wistiaObject.name}",
            "description": "${desc}",
            "thumbnailUrl": [
              "${this.wistiaObject.thumbnail.url}"
             ],
            "duration": "${formattedDuration}",
            "uploadDate": "${this.wistiaObject.updated}",
            "contentUrl": "${contentUrl}.${contentType}",
            "embedUrl": "${path}",
            "regionsAllowed": "US"
          }
          `;
    this.insertStructuredDataElement(wistia);
  }

  /**
   * insertStructuredDataElement inserts structued data into application <head>
   *
   * @param {string} wistiaStrucutredData - Wistia Strucuted data as a string
   *
   */
  insertStructuredDataElement(wistiaStrucutredData: string) {
    const { head } = this.document;
    const s = this.document.createElement('script');
    s.type = 'application/ld+json';
    s.text = wistiaStrucutredData;
    s.onload = () => this.scriptLoadedSubject.next({ type: 'wistia', loaded: true });
    s.setAttribute('script-loader:wistia', '');
    head.appendChild(s);
  }

  /**
   * getWistiaData call Wistia api to pull video data
   *
   * @param {string} wistiaId - Wistia Id
   * @return {Observable<WistiaApiData>} - Wistia Api data
   *
   */
  getWistiaData = (wistiaId): Observable<WistiaApiData> => {
    const url = `https://api.wistia.com/v1/medias/${wistiaId}.json`;

    return this.httpClient.get<any>(url, {
      headers: {
        Authorization: this.env.get.wistiaVerification,
      },
    });
  };

  /**
   * setRendererForWistiaPosters set renderer from the target template
   *
   * @param {Renderer2} renderer - renderer
   *
   */
  setRendererForWistiaPosters = (renderer) => {
    this.renderer = renderer;
  };

  /**
   * wistiaParser called to inject div in place holder of "shortcode". Called from the redactorSanitizer.
   * Used to inject video structured data into head for SEO purposes
   *
   * @param {string} content - Redactor HTML content
   * @return {string} - HTML content with div instead of "shortcode"
   *
   */
  wistiaParser = (content: string) => {
    if (content && typeof content === 'string' && typeof String.prototype.matchAll === 'function') {
      const wistiaInstances = [...content.matchAll(new RegExp(this.regExp, 'g'))];

      //No wistia shortcode found, return un-modified content
      if (!wistiaInstances.length) {
        return content;
      }
      wistiaInstances.forEach((instance) => {
        const wistiaId = instance[1];

        const wistiaPosterHtml = `<div id="${wistiaId}" class="wistia-vid relative"></div>`;
        //Insert Image Poster
        content = content.replace(instance[0], wistiaPosterHtml);
        //Get Wistia data
        this.getWistiaData(wistiaId).subscribe((data) => {
          this.wistiaObject = data;
          const wistiaElement = this.document.getElementById(wistiaId);
          //Parse and Insert Strcuted Data into Head
          this.parseStuctureData(wistiaId);
          this.createWistiaPosterEl(wistiaId, wistiaElement);
        });
      });
    }
    return content;
  };

  /**
   * insertWistiaCtaData is used to 1) get wistia data and 2) inject structured video data into head for SEO purposes
   *
   * @param {string} wistiaId - wistia video id
   *
   */
  insertWistiaCtaData(wistiaId: string) {
    if (wistiaId === null || wistiaId === '') {
      return;
    }
    this.getWistiaData(wistiaId).subscribe((data) => {
      this.wistiaObject = data;
      //Parse and Insert Strucuted Data into Head
      this.parseStuctureData(wistiaId);
    });
  }

  /**
   * wistiaSingleInputParser use to check if "shortcode" is coming in, if not, parse it and return it
   *
   * @param {string} wistiaId - wistia video id
   * @return {string} - string parsed as the expected "shortcode"
   *
   */
  wistiaSingleInputParser = (input: string) => {
    if (this.regExp.test(input)) {
      return input;
    }
    return `[wistia id="${input}" type="popover"]`;
  };

  /**
   * loadVideoScripts to overwritte client side call i.e. do not nothing server side
   *
   * @param {string} wistiaId - wistia video id
   *
   */
  loadVideoScripts = (wistiaId: string) => {};
}
