import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import type { PageDataResponse } from '@core-mkt/resolvers/page-data/page-data-response';
import { cloneDeep } from 'lodash-es';
import type { FooterNavLink } from './footer';
import { footerData } from './footer-data';

@Component({
  selector: 'aarp-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  showHideFooter = true;
  footerData: FooterNavLink[] = footerData;
  resolverData: PageDataResponse;

  constructor(private route: ActivatedRoute) {
    if (this.route.snapshot) {
      this.resolverData = cloneDeep(this.route.snapshot.data.pageData);
    }

    this.showHideFooter = !this.resolverData?.craftData?.headerData?.hideFooter;
  }
}
