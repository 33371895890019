<div class="footer__{{ footerLocation }}-container {{ alignClass }} {{ flexDirection }} flex flex-col">
  <a
    class="{{ footerLocation === 'bottom' ? 'pb-0' : 'pb-3' }} text-sm leading-6 flex {{ linkClass }}"
    *ngFor="let link of links; let first = first"
    [href]="link.linkUrl"
    [attr.target]="link.newTab ? '_blank' : null"
    [attr.nofollow]="link.nofollow"
    [attr.aria-label]="link.linkText + (link.newTab ? ' (opens in a new tab)' : '')"
  >
    <fa-icon
      *ngIf="link.icon"
      class="mr-3 h-4 w-4 self-center -mt-1 justify-self-end fa-1x"
      [icon]="[iconPrefix, link.icon]"
    ></fa-icon>
    <span class="md:inline">{{ link.linkText }}</span>
    <span *ngIf="footerLocation === 'bottom'" class="mx-[10px] divider">|</span>
  </a>
</div>