import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import type { VisionSelectOption } from './vision-select.models';

@Component({
  selector: 'vision-select',
  templateUrl: './vision-select.component.html',
  styleUrls: ['./vision-select.component.scss'],
})
export class VisionSelectComponent {
  @Input() extendClassStyles = null; // Add additional class styles to the select element
  @Input() data: VisionSelectOption[] = []; // Array of objects with value and label
  @Input() formControl: FormControl; // Form control for the select element
  @Input() placeholder = 'Select an option'; // Placeholder text for the select element
  @Input() defaultSelectedOption = ''; // Default selected option
  @Input() disabled = false; // Disable the select element
  @Input() isLoadingState = false; // Loading state for the select element
  @Input() isLoadingPlaceholder = null; // Placeholder text to display when loading
  @Output() changeEvent = new EventEmitter<string>(); // Event emitter for value changes

  faCaretDown = faCaretDown; // Icon for the select element

  /**
   * Handles the change event of the vision select component.
   * Emits the selected value through the changeEvent output.
   *
   * @param event - The change event object.
   */
  handleChange(event: Event) {
    const value = (event.target as HTMLSelectElement).value;
    this.changeEvent.emit(value);
  }
}
