import { CommonModule } from '@angular/common';
import type { ComponentFactory } from '@angular/core';
import { ComponentFactoryResolver, NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AmbassadorModalComponent } from './ambassador-modal/ambassador-modal.component';

@NgModule({
  declarations: [AmbassadorModalComponent],
  exports: [AmbassadorModalComponent],
  imports: [CommonModule, FontAwesomeModule],
})
export class AmbassadorModalModule {
  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}
  public resolveComponent(): ComponentFactory<AmbassadorModalComponent> {
    return this.componentFactoryResolver.resolveComponentFactory(AmbassadorModalComponent);
  }
}
