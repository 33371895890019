import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { CraftXGritProductEntry } from '@core-mkt/interfaces/craft-xgrit-product-entry';
import type { Image } from '@core-mkt/interfaces/image';
import { ImgixService } from '@core-mkt/services/imgix/imgix.service';
import { ProductService } from '@core-mkt/services/product/product.service';
import { ThemeParserService } from '@core-mkt/services/theme-parser/theme-parser.service';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import { XGritCompleteProduct } from '@core-mkt/services/xgrit-api/xgrit-product';
import type { VisionButton } from '@core-mkt/shared/components/vision-button/vision-button/vision-button';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { ResourceCard } from '../resources/resources';

@Component({
  selector: 'resource-card',
  templateUrl: './resource-card.component.html',
  styleUrls: ['./resource-card.component.scss'],
})
export class ResourceCardComponent implements OnInit {
  @Input() data: ResourceCard;
  faAngleRight = faAngleRight;
  faAngleRightStyles = { width: '16px', height: '32px' };
  resourceTextContent = undefined;

  constructor(
    private redactor: WysiwygRedactorService,
    private imgixService: ImgixService,
    private themeService: ThemeParserService,
    private ps: ProductService,
  ) {}

  get image(): Image | undefined {
    return this.data.resourceImage?.[0];
  }

  get backgroundColor(): string {
    if (!this.data.resourceBackgroundColor) {
      return 'bg-white text-vis-regular';
    }
    if (typeof this.data.resourceBackgroundColor === 'string') {
      return this.data.resourceBackgroundColor;
    }
    return this.themeService.getDominantClassFromStyleList(this.data.resourceBackgroundColor);
  }

  get textContent(): string {
    return this.resourceTextContent;
  }

  get ctaStyle(): string {
    return this.data.resourceCtaStyle;
  }

  get ctaLabel(): string {
    return this.data.resourceCtaLabel ? this.data.resourceCtaLabel : 'Read More';
  }

  get ctaLink(): string {
    return this.data.resourceCtaLink;
  }

  get ctaTag(): string {
    return this.data.resourceCtaTag;
  }

  get ctaNewTab(): boolean {
    return this.data.resourceCtaLinkNewTab;
  }

  get ctaNofollow(): boolean {
    return this.data.resourceCtaLinkNofollow;
  }

  get buttonData(): VisionButton {
    return {
      bgTheme: this.backgroundColor,
      buttonLink: this.data.resourceCtaLink,
      buttonAriaLabel: this.data.resourceCtaLinkAriaLabel,
      buttonText: this.ctaLabel,
      buttonStyle: this.ctaStyle,
      useIcon: true,
      newTab: this.data.resourceCtaLinkNewTab,
      nofollow: this.data.resourceCtaLinkNofollow,
      noopener: this.data.resourceCtaLinkNewTab,
      noreferrer: this.data.resourceCtaLinkNewTab,
      ctaTag: this.data.resourceCtaTag,
      fullWidth: false,
    };
  }

  ngOnInit(): void {
    this.resourceTextContent = this.redactor.bypassSanitizer(this.data.resourceTextContent);

    //Getting Aria label to Cta Link
    if (this.data.resourceCtaLink){
      this.getAriaLabelToCheckout(this.data.resourceCtaLink);
    }
  }

  ctaClick(): void {
    if (this.data.resourceCtaLink) {
      window.location.href = this.data.resourceCtaLink;
    }
  }

  /**
   * Getting aria label from product title
   * valitating to get aria label according url link
   */
  getAriaLabelToCheckout(link: string): void {
    if (link?.startsWith('https://checkout')) {
      const linkList = link?.split('?');
      const queryString = linkList[linkList.length-1];
      const urlParams = new URLSearchParams(queryString);
      const productId = urlParams.get('productId');
      const productEntry: CraftXGritProductEntry = {
        xgritProductId: productId
      };

      this.ps.getProductData(productEntry).then((product: XGritCompleteProduct) => {
        this.data.resourceCtaLinkAriaLabel = product.apiData.title ? product.apiData.title : undefined;
      });
    }
  }
}
