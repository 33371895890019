<div
    *ngIf="showModal"
    class="vision-modal__modal fixed inset-0 bg-vis-dark/50 z-[99] flex justify-center items-center"
  >
    <div class="vision-modal__modal-body max-w-[600px] bg-white text-vis-regular shadow-md px-8 !py-v-spacing wysiwyg relative rounded flex">
      <div
        class="vision-modal__modal-body-content wysiwyg flex-grow"
        [innerHTML]="modalContent"
        id="modal-content"
        aria-label="Modal Content"
        tabindex="{{ showModal ? 0 : -1 }}"
        >
      </div>
      <fa-icon
        id="close-modal"
        aria-label="Close Modal"
        tabindex="{{ showModal ? 0 : -1 }}"
        (blur)="handleBlur($event.currentTarget.id)"
        class="vision-modal__modal-close text-vis-muted absolute right-v-spacing top-v-spacing fa-1x cursor-pointer"
        (click)="showModal = false"
        (onKeyPress)="handleKeyDown($event)"
        [icon]="faTimes"
      ></fa-icon>
    </div>
  </div>
