/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const SliderCardFragment = {
  SliderCard: gql`
    fragment SliderCardQuery on baseComponents_sliderCard_BlockType {
      displayAllCardsMobile
      lineBreak_content
      lineBreak_styles
      slidesCards {
        ... on slidesCards_BlockType {
          slideButtonLabel
          slideButtonLink
          slideButtonNewTab
          slideButtonNofollow
          slideButtonStyle
          slideContent
          slideImage {
            url
            ... on images_Asset {
              altText
            }
          }
        }
      }
      sliderDescription
      sliderTitle
      styles
      typeHandle
    }
  `,
};
