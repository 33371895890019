import { NgModule } from '@angular/core';
import type { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import { TemplateBundlesComponent } from './template-component/template-bundles.component';

const routes: Routes = [{ path: '', component: TemplateBundlesComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TemplateBundlesRoutingModule {}
