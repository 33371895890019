<div
  [className]="theme?.root + ' bg-no-repeat bg-center bg-cover ambassador-invite-component'"
  [style]="theme?.bgImage"
>
  <div class="container {{ theme?.container }}">
    <div class="section-container flex items-center justify-center flex-col-reverse lg:flex-row-reverse">
      <div *ngIf="ambassadorInviteRightContent" class="right-content m-auto w-full lg:w-1/2 xs:p-v-spacing py-v-spacing">
        <div class="wysiwyg" (click)="modalWrapperClickHandler($event)" [innerHTML]="ambassadorInviteRightContent"></div>
      </div>
      <div
        class="p-0 {{ ambassadorInviteRightContent ? 'lg:w-1/2' : '' }} w-full"
      >
        <div
          class="content-section w-full xs:p-v-spacing py-v-spacing"
          [ngClass]="{
            'tab:px-v-spacing max-w-vision': !ambassadorInviteRightContent
          }"
        >
          <div class="lg:w-full lg:mr-v-spacing lg:pr-v-spacing">
            <div *ngIf="ambassadorInviteTitle" class="wysiwyg !mb-4 layout-title" [innerHTML]="ambassadorInviteTitle"></div>
            <div *ngIf="ambassadorInviteContent" class="wysiwyg" (click)="modalWrapperClickHandler($event)" [innerHTML]="ambassadorInviteContent"></div>
          </div>
          <div class="lg:w-full pt-8 flex">
            <button
              id="raf-trigger"
              [className]="buttonProps.style + '-button' + ' lg:!w-auto v-button w-min whitespace-nowrap ambassador-invite-cta'"
            >
              {{ buttonProps.label }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showModal" class="fixed inset-0 bg-vis-dark/50 z-50 flex justify-center items-center">
  <div class="w-full md:w-2/4 lg:w-1/4 bg-white text-vis-regular shadow-md relative rounded">
    <div
      *ngIf="isLoading"
      class="absolute w-full h-full bg-vis-dark/30 z-50 p-v-spacing items-center justify-center"
    >
      <div class="relative w-12 mx-auto my-auto top-1/3">
        <fa-icon class="fa-3x" [icon]="faCircleNotch" [spin]="true"></fa-icon>
      </div>
    </div>
    <form [formGroup]="referralForm" class="p-5">
      <fa-icon
        class="text-vis-regular font-bold absolute right-4 top-4 text-lg cursor-pointer"
        (click)="closeModal()"
        [icon]="faTimes"
      ></fa-icon>
      <div class="font-bold py-v-spacing sm:px-v-spacing text-lg text-center w-full">{{ modalTitle }}</div>
      <div class="text-center w-full py-v-spacing sm:px-v-spacing">{{ modalBody }}</div>
      <div *ngIf="step === 1" class="py-v-spacing sm:px-v-spacing w-full">
        <input
          type="text"
          placeholder="Email Address"
          class="w-full border rounded border-color-ace-gray-500 p-2"
          formControlName="email"
          required
        />
        <p *ngIf="isEmailInvalid" class="text-xs text-red-500">Valid email required.</p>
      </div>
      <div *ngIf="step === 1" class="py-v-spacing sm:px-v-spacing w-full text-center">
        <div>
          <button type="submit" (click)="signUp()" [className]="buttonProps.style + ' py-2 px-4 rounded-full'">
            Sign Up
          </button>
        </div>
        <p *ngIf="apiError !== ''" class="text-xs text-red-500 py-2">
          Oops! We encountered an error.<br />Please try again.
        </p>
      </div>
      <div *ngIf="step === 2" class="py-v-spacing sm:px-v-spacing w-full text-center">
        <p class="border-2 border-dotted border-gray-900 p-4 text-vis-muted">
          <a [href]="referalLink" target="_blank">{{ referalLink }}</a>
        </p>
      </div>
      <div class="py-v-spacing sm:px-v-spacing w-full text-xs text-center">
        <p>
          <fa-icon [icon]="faCopy"></fa-icon>
          {{ getYear() }} Aceable
        </p>
      </div>
    </form>
  </div>
</div>
