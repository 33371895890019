<div class="legacy-bgColor text-vis-reverse text-sm bg-vis-dark" *ngIf="showDefaultNav">
  <header class="font-body container mx-auto flex align-top py-4 sm:px-4 relative">
    <skip-nav></skip-nav>
    <a href="/" class="mr-0 sm:mr-8 lg:mr-0 xl:mr-8 min-h-[44px] min-w-[171px]" aria-label="Home">
      <img
        class="ace__nav-logo hidden lg:flex max-h-10"
        [src]="aaLogo"
        width="140"
        height="50"
        alt="Aceable Agent Logo"
        loading="lazy"
      />

      <img
        class="flex lg:hidden max-h-10"
        [src]="aaLogo"
        width="140"
        height="36"
        alt="Aceable Agent Logo"
        loading="lazy"
      />
    </a>
    <nav class="ace__nav flex flex-grow align-middle">
      <ace-header-navlink *ngFor="let navLink of headerData" [data]="navLink" class="items-center hidden lg:flex">
      </ace-header-navlink>
    </nav>
    <nav class="ace__nav hidden lg:flex lg:align-middle justify-end" data-test="accountNav">
      <ace-header-navlink *ngFor="let navLink of accountData" [data]="navLink" class="items-center hidden lg:flex">
      </ace-header-navlink>
    </nav>
    <button (click)="activateMobileMenu = !activateMobileMenu" class="text-vis-reverse text-xs lg:hidden items-center"
      aria-label="Open navigation menu"
    >
      <fa-icon class="mr-4 text-md fa-1x" [icon]="faHamburger" aria-label="Open navigation menu"></fa-icon>
    </button>
  </header>
</div>
<div *ngIf="activateMobileMenu" class="ace__nav-mobile_legacy flex flex-col justify-items-start content-start fixed top-0 left-0 flex w-full h-full z-[1022]">
  <div class="ace__nav-mobile_menu_legacy h-full overflow-y-auto bg-white relative">
    <div class="p-5 text-md">
      <img
        class="flex lg:hidden max-h-10 text-ace-teal-700"
        [src]="aaLogoBlue"
        width="140"
        height="36"
        alt="Aceable Agent Logo"
        loading="lazy"
      />
      <fa-icon
        class="text fa-1x absolute right-7 cursor-pointer top-5"
        (click)="activateMobileMenu = !activateMobileMenu"
        [icon]="faTimes"
        aria-label="Close navigation menu"
      ></fa-icon>
    </div>
    <collapse-link *ngFor="let vertLink of mobileHeaderData" [link]="vertLink"></collapse-link>
  </div>
</div>
