import { Injectable } from '@angular/core';
import { CookieService } from '@core-mkt/services/cookie/cookie.service';
import {
  CourseDuration,
  CourseTypes,
  type ProductBuilderCategory,
  type ProductBuilderTableRow,
} from '@core-mkt/shared/components/study-plan-builder/study-plan-builder/study-plan-builder.models';
import type { VisionSelectOption } from '@core-mkt/shared/components/vision-select/vision-select/vision-select.models';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { XGritApiService } from '../xgrit-api/xgrit-api.service';
import type {
  ProductCodeLists,
  XGritPurchaseReceiptPriceResponse,
} from '../xgrit-api/xgrit-purchase-receipt-price-response';
import type { Product, ProductFromResponse } from './product-builder.models';

@Injectable({
  providedIn: 'root',
})
export class ProductBuilderService {
  public availableProducts = new BehaviorSubject<Product[]>([]);

  constructor(private xGritApiService: XGritApiService, private cookie: CookieService) {}

  /**
   * Sets the states based on the provided data.
   *
   * @param data - The data used to set the states.
   * @returns An array of VisionSelectOption representing the states.
   */
  getStates(data: ProductBuilderCategory): VisionSelectOption[] {
    let states: VisionSelectOption[] = [];

    if (data.productBuilderTable.length && data.productBuilderTable[0] !== null) {
      data.productBuilderTable.forEach((product) => {
        if (product && !states.some((state) => state.value === product.state)) {
          states.push({ value: product.state, label: product.state });
        }
      });
    } else {
      // Return Fallback for no data
      states = [{ value: 'No Value', label: 'No Options' }];
    }

    return states;
  }

  /**
   * Retrieves products based on the specified state.
   *
   * @param state - The state to filter the products by.
   * @param data - An array of ProductBuilderCategory objects.
   * @returns An array of ProductBuilderTableRow objects that match the specified state.
   */
  getProductsPerState(state: string, data: ProductBuilderCategory[]): ProductBuilderTableRow[] {
    const products: ProductBuilderTableRow[] = [];

    data[0].productBuilderTable.find((product) => {
      if (product.state === state) {
        products.push(product);
      }
    });
    return products;
  }

  /**
   * Retrieves the value of the 'emailCoupon' cookie.
   * @returns The value of the 'emailCoupon' cookie, or null if the cookie is not found.
   */
  public getEmailCouponCookie(): string | null {
    return this.cookie.getCookie('emailCoupon');
  }

  /**
   * Retrieves the available course types and durations based on the provided data.
   *
   * @param data - The data used to retrieve the course types and durations.
   * @returns An object containing the available course types and durations.
   */
  getAvailableProducts(data: ProductBuilderTableRow[], overrideCoupon?: string): Observable<Product[]> {
    return new Observable<Product[]>((observer) => {
      const params: ProductCodeLists[] = this.setProductsParams(data, overrideCoupon);

      this.xGritApiService
        .getMultiProductPriceInfo(params)
        .pipe(
          map((response) => {
            const productsFromResponse: Partial<ProductFromResponse>[] = [];
            response.priceSetList.forEach((product: XGritPurchaseReceiptPriceResponse) => {
              if (product && product.lineItemList && product.lineItemList.length > 0) {
                productsFromResponse.push({
                  id: product.lineItemList[0].product._id as string,
                  price: product.display.grossTotal,
                  discountedPrice: product.display.total,
                  discountType: product.lineItemList[0].couponList[0]?.discountType as string,
                  hasDiscount: product.lineItemList[0].couponList[0]?.offPercent as number, // Use discount percentage
                  coupon: product.lineItemList[0].couponList[0]?.code as string,
                });
              }
            });
            return productsFromResponse;
          }),
        )
        .subscribe(
          (response) => {
            const products = this.composeProducts(data, response);
            observer.next(products);
            observer.complete();
          },
          (error) => {
            console.warn('Error retrieving available products:', error);
            observer.error(error);
          },
        );
    });
  }

  /**
   * Retrieves the available course types and durations from the given array of products.
   *
   * @param data - The array of products.
   * @returns An object containing the available course types and durations.
   */
  getAvailableCourseTypesAndDurations(data: Product[]): {
    availableCourseTypes: VisionSelectOption[];
    availableDurations: (VisionSelectOption & {
      price: number;
      discountedPrice: number;
      hasDiscount: number;
      productId: string;
    })[];
  } {
    const availableCourseTypes: VisionSelectOption[] = [];
    const availableDurations: (VisionSelectOption & {
      price: number;
      discountedPrice: number;
      hasDiscount: number;
      productId: string;
    })[] = [];

    data.forEach((product) => {
      if (!availableCourseTypes.some((courseType) => courseType.value === product.courseType.value)) {
        availableCourseTypes.push({
          value: product.courseType.value,
          label: CourseTypes[product.courseType.value] as string,
        });
      }
      if (!availableDurations.some((duration) => duration.value === product.duration.value)) {
        availableDurations.push({
          value: product.duration.value,
          label: CourseDuration[product.duration.value] as string,
          price: product.product.price,
          discountedPrice: product.product.discountedPrice,
          hasDiscount: product.product.hasDiscount,
          productId: product.product.id,
        });
      }
    });
    return { availableCourseTypes, availableDurations };
  }

  /**
   * Sets the parameters for the products.
   *
   * @param data - An array of ProductBuilderTableRow objects.
   * @returns An array of ProductCodeLists.
   */
  setProductsParams(data: ProductBuilderTableRow[], overrideCoupon?: string): ProductCodeLists[] {
    const hasOverrideCoupon = !!overrideCoupon;

    const availableProductsList: ProductCodeLists[] = [];
    const emailCoupon = this.getEmailCouponCookie();

    data.forEach((product) => {
      /**
       * If the override coupon is provided, use it.
       * If the product has a coupon, use it. Otherwise, use the email coupon.
       * If the email coupon is null, the couponCodeList will be an empty array.
       */
      const couponCodeList = [
        hasOverrideCoupon ? overrideCoupon : product.coupon ? product.coupon : emailCoupon,
      ].filter((item) => item !== null);

      availableProductsList.push({
        productIdList: [product.productId],
        couponCodeList,
      });
    });

    return availableProductsList;
  }

  /**
   * Composes an array of Product objects based on the provided data and xgritProducts.
   *
   * @param data - An array of ProductBuilderTableRow objects.
   * @param xgritProducts - An array of Partial<ProductFromResponse> objects.
   * @returns An array of Product objects.
   */
  composeProducts(data: ProductBuilderTableRow[], xgritProducts: Partial<ProductFromResponse>[]): Product[] {
    const products: Product[] = [];
    xgritProducts.forEach((product) => {
      const matchedProduct = data.find((item) => item.productId === product.id);
      if (matchedProduct) {
        products.push({
          courseType: {
            value: matchedProduct.courseType,
            label: CourseTypes[matchedProduct.courseType] as string,
          },
          duration: {
            value: matchedProduct.duration,
            label: CourseDuration[matchedProduct.duration] as string,
          },
          state: matchedProduct.state,
          product: {
            id: matchedProduct.productId,
            price: product.price,
            discountedPrice: product.discountedPrice,
            hasDiscount: product.hasDiscount,
            discountType: product.discountType,
            coupon: product.coupon ? product.coupon : null,
            checkoutURL: null,
          },
        });
      }
    });
    return products;
  }
}
