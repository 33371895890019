import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { EnvService } from '@core-mkt/services/env/env.service';
import type { ComponentTheme, ThemeInterface } from '@core-mkt/services/theme-parser/theme-parser.service';
import { ThemeParserService } from '@core-mkt/services/theme-parser/theme-parser.service';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import type { VisionButton } from '../../vision-button/vision-button/vision-button';
import type { ProductCategory, ProductPicker } from './side-product-picker';
import { SideProductPicker } from './side-product-picker';

@Component({
  selector: 'side-product-picker',
  templateUrl: './side-product-picker.component.html',
})
export class SideProductPickerComponent implements OnInit {
  @Input() data: SideProductPicker; // Data from the parent component
  pickerTheme: ThemeInterface; // Theme for the product picker
  mobileBannerTheme: ThemeInterface; // Theme for the mobile banner
  ctaButtonData: VisionButton; // CTA button data
  showModal = false;
  faTimes = faTimes;

  constructor(
    private redactor: WysiwygRedactorService,
    private themeParser: ThemeParserService,
    private env: EnvService,
  ) {}

  /**
   * Gets the content of the product picker.
   * @returns The first element of the productPicker array from Craft
   */
  get productPickerContent(): ProductCategory[] | null {
    if (this.productPicker.productCategory.length > 0) {
      return this.productPicker.productCategory;
    }
    return null;
  }
  // Flatten product picker matrix array
  get productPicker(): ProductPicker {
    return this.data.productPicker.length > 0 ? this.data.productPicker[0] : null;
  }

  get sideContent(): string {
    return this.data?.sideContent;
  }

  get productPickerStyles(): string[] {
    return this.productPicker?.styles;
  }

  get productPickerBackgroundColor(): string {
    return this.productPicker?.backgroundColor;
  }

  get productPickerCtaLabel(): string {
    return this.productPicker?.ctaLabel;
  }

  get productPickerCtaType(): string {
    return this.productPicker?.ctaType;
  }

  get productPickerHeading(): string {
    return this.productPicker?.productPickerHeading ?? 'Choose a State and Course';
  }

  get displayMobileBanner(): boolean {
    return this.data?.mobileBannerOptions[0]?.displayMobileBanner;
  }

  get bannerStyles(): string[] {
    return this.data?.mobileBannerOptions[0]?.styles;
  }

  get bannerBackgroundColor(): string {
    return this.data?.mobileBannerOptions[0]?.backgroundColor;
  }

  get bannerCtaLabel(): string {
    return this.data?.mobileBannerOptions[0]?.ctaLabel ?? this.env.get.brandConfig.ctaText;
  }

  get bannerCtaType(): string {
    return this.data?.mobileBannerOptions[0]?.ctaType ?? 'primary';
  }

  get bannerText(): string {
    return this.data?.mobileBannerOptions[0]?.bannerText ?? 'Interested in a real estate career?';
  }

  ngOnInit() {
    this.setTheme();
    this.data.sideContent = this.redactor.bypassSanitizer(this.data.sideContent);

    // Product picker CTA data
    this.ctaButtonData = {
      buttonStyle: this.productPickerCtaType ?? 'primary',
      bgTheme: null,
      buttonText: this.productPickerCtaLabel ?? this.env.get.brandConfig.ctaText,
      buttonLink: '',
      fullWidth: true,
      nofollow: false,
      noreferrer: false,
      noopener: false,
      newTab: false,
      useIcon: false,
    };
  }

  toggleModal() {
    this.showModal = !this.showModal;
  }

  setTheme(): void {
    const pickerThemeInput: ComponentTheme = {
      styles: this.productPickerStyles,
      backgroundColor: this.productPickerBackgroundColor,
      backgroundImage: undefined,
      textColor: '',
      columnCount: 0,
      columnDistribution: '',
    };

    const mobileBannerThemeInput: ComponentTheme = {
      styles: this.bannerStyles,
      backgroundColor: this.bannerBackgroundColor,
      backgroundImage: undefined,
      textColor: '',
      columnCount: 0,
      columnDistribution: '',
    };

    this.pickerTheme = this.themeParser.getThemeClasses(pickerThemeInput);
    this.mobileBannerTheme = this.themeParser.getThemeClasses(mobileBannerThemeInput);
  }
}
