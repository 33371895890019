import { CommonModule } from '@angular/common';
import type { ComponentFactory } from '@angular/core';
import { ComponentFactoryResolver, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LazyImgModule } from '@core-mkt/services/lazy-load/lazy-load.module';
import { QueryParamModule } from '@core-mkt/services/query-param/query-param.module';
import { PrivacyManagerModule } from '@core-mkt/shared/components/privacy-manager/privacy-manager.module';
import { VisionButtonModule } from '@core-mkt/shared/components/vision-button/vision-button.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FooterLinkComponent } from './footer-link/footer-link.component';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  declarations: [FooterComponent, FooterLinkComponent],
  exports: [FooterComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    QueryParamModule,
    LazyImgModule,
    VisionButtonModule,
    PrivacyManagerModule,
  ],
})
export class FooterModule {
  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}
  public resolveComponent(): ComponentFactory<FooterComponent> {
    return this.componentFactoryResolver.resolveComponentFactory(FooterComponent);
  }
}
