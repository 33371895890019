<div class="footer text-base text-vis-subtle bg-vis-grey-2" *ngIf="showHideFooter">
  <div class="flex flex-wrap container">
    <!-- Logo -->
    <div class="footer__logo-container md:border-0 w-full lg:w-1/2 pb-v-spacing lg:pb-0 flex flex-col md:flex-col justify-start md:justify-between lg:justify-start align-top">
      <a
        class="hover:text-ace-teal-500"
        *ngFor="let footerLogo of footerLogos"
        [href]="footerLogo.logoLink"
        aria-label="Home"
      >
        <img
          class="footer-logo h-[44px] w-auto"
          width="230"
          height="40"
          *ngIf="footerLogo.logoImage.length > 0"
          [src]="footerLogo.logoImage[0].url"
          [alt]="footerLogo.logoImage[0].altText ? footerLogo.logoImage[0].altText : ''"
        />
        {{ footerLogo.logoImage.length === 0 ? stripLink(footerLogo.logoLink) : '' }}
      </a>
      <!-- Aceable Family -->
      <div class="font-extrabold mt-6 mb-1 text-sm">
        <p>Part of the 
          <a [attr.aria-labelledby]="requestService.getAriaLabelledby('Aceable') + '-nav-label'" class="text-vis-brand-1 underline font-bold aceable-link" href="https://www.aceable.com">
            <span>Aceable</span>
            <!-- Hidden Context -->
            <span [attr.id]="requestService.getAriaLabelledby('Aceable') + '-nav-label'" class="sr-only">{{requestService.getCompleteTextContextForButton('/Aceable/')}}</span>
          </a> 
          Family</p>
      </div>
      <!-- Vertical Links: Driving, Real Estate, Insurance, Mortgage -->
      <div class="font-bold text-vis-brand-1 text-sm pb-v-spacing lg:pb-0">
        <ng-container *ngFor="let link of verticalLinks; let last = last">
          <a [attr.aria-labelledby]="requestService.getAriaLabelledby(link.linkText) + '-nav-label'" [href]="link.linkUrl" class="text-vis-brand-1 underline">
            <span>{{ link.linkText }}</span>
            <!-- Hidden Context -->
            <span [attr.id]="requestService.getAriaLabelledby(link.linkText) + '-nav-label'" class="sr-only">{{requestService.getCompleteTextContextForButton(link.linkUrl, link.linkText)}}</span>
          </a>
          <span *ngIf="!last" class="mx-[10px] text-vis-subtle">|</span>
        </ng-container>
      </div>
      <div class="border-b-[1px] border-[#B7BEC1] lg:border-0"></div>
    </div>
    <!-- General Links: Help Center, Blog -->
    <footer-link
      class="md:mt-1 pt-v-spacing lg:pl-v-spacing lg:pt-0 lg:mt-0 w-1/2 lg:w-1/4 flex lg:justify-center"
      [links]="generalLinks"
      footerLocation="general"
      alignClass=""
      linkClass="w-full lg:w-full"
    ></footer-link>
    <!-- Social Links: Twitter, Facebook, Instagram, LinkedIn, YouTube -->
    <footer-link
      class="
        md:pl-0
        lg:mt-0
        md:w-1/2
        mx-0
        pt-v-spacing
        md:mt-1
        lg:py-0 lg:w-1/4
        py-3
        lg:pl-v-spacing
        flex
      "
      [links]="socialLinks"
      footerLocation="social"
      alignClass="lg:items-start w-full sm:w-3/4 md:w-full"
      linkClass=""
    ></footer-link>
    <!-- Copyright -->
    <div class="pt-v-spacing text-sm self-end lg:text-left footer__copyright py-1 w-full">
      <!-- Bottom Links: Privacy Policy, Terms & Conditions -->
      <div class="w-full flex flex-wrap flex-row items-baseline">
        <footer-link
          footerLocation="bottom"
          [links]="bottomLinks"
          alignClass="w-full"
        >
        </footer-link>
        <!-- <span class="mx-[10px]">|</span> -->
        <button (click)="openPrivacyManagerModal()">Do not sell or share my information</button>
      </div>
      <p class="pt-3 lg:pt-2">© {{ copyrightYear }} Aceable, Inc. <br class="hidden block sm:hidden">All rights reserved.</p>
    </div>
  </div>
</div>
<email-capture *ngIf="emailCaptureData && !(promoBannerActive && ((!!graphicBanner) && displayRE))" [data]="emailCaptureData"></email-capture>

<privacy-manager *ngIf="showPrivacyManagerModal" (hideModal)="showPrivacyManagerModal = false;"></privacy-manager>
