<div *ngIf="theme" class="font-body bg-cover multi-column {{ theme?.root }} {{topLevelClasses}}" [style]="theme?.bgImage">
  <div class="{{containerLevelClasses}} container {{ theme?.container }}" (click)="modalWrapperClickHandler($event)">
    <div
      *ngIf="data.componentTitle !== null && data.componentTitle?.length !== 0"
      class="py-v-spacing tab:px-v-spacing max-w-vision mx-auto wysiwyg multi-column__title"
      [innerHTML]="data.componentTitle"
    ></div>
    <div class="flex flex-wrap xl:flex-nowrap xl:flex-row justify-center" [class.sm-flex-column]="data.columnList.length < 5">
      <div
        *ngFor="let column of data.columnList; let index = index"
        class="multi-column__column py-v-spacing w-full {{ theme?.columnWidths[index] }}"
        [ngClass]="{
          'tab:px-v-spacing max-w-vision mx-auto': data.columnList.length === 1,
          'sm:px-v-spacing': data.columnList.length > 1
        }"
      >
        <div class="wysiwyg" [innerHTML]="column.columnContent"></div>

        <!--Optional dropdown-->
        <div *ngIf="column.dropdownTitle && column.dropdown.length > 0" class="py-v-spacing sm:px-v-spacing">
          <vision-dropdown [dropdownData]="setDropdownData(column.dropdown, column.dropdownTitle, column.dropdownAlignment)"></vision-dropdown>
        </div>
      </div>
    </div>
  </div>
</div>
