<marketing [includeOptimizely]="includeOptimizely" [segmentIdOverride]="segmentIdOverride"></marketing>
<banner></banner>
<ace-header></ace-header>
<ng-container *ngIf="formattedData">
  <div *ngFor="let options of formattedData" class="flex flex-col mx-auto">
    <ng-template aceInsertableHost [options]="options" [registry]="registryService"> </ng-template>
  </div>
</ng-container>
<vision-modal></vision-modal>
<footer></footer>
