/* eslint-disable prettier/prettier */
import { BundlesFragment } from '@core-mkt/shared/craft/bundles-fragment';
import * as gql from 'fake-tag';
import { HeaderFragment, SettingsFragment } from './fragments/header-fragment-query';
import { SEOBundlesFragment } from './fragments/seo-fragment-query';

export const TemplateBundlesQuery = {
  name: 'TemplateBundlesQuery',
  query: gql`
    query ($site: [String], $slug: [String]) {
      entryData: entries(site: $site, slug: $slug) {
        slug
        sectionHandle
        typeHandle
        ... on aceDrivingTemplates_bundlesTemplate_Entry {
          hideStickyBanner
          bundlesTemplate {
            ...bundlesTemplate_fragment
          }
        }
      }
        seoContent: entries(site: $site, slug: $slug) {
        ... on aceDrivingTemplates_bundlesTemplate_Entry {
          ...SEOBundlesFragmentEntry
        }
      }
      styles: entries(site: $site, slug: $slug) {
        ... on aceDrivingTemplates_bundlesTemplate_Entry {
          embedStyles
        }
      }
      optimizely: entries(site: $site, slug: $slug) {
        ... on aceDrivingTemplates_bundlesTemplate_Entry {
          optimizelyEnabled
        }
      }
      overrides: entries(site: $site, slug: $slug) {
        ... on aceDrivingTemplates_bundlesTemplate_Entry {
          aceHeaderPageSettings {
            ...SettingsFragment
          }
        }
      }
      globalOptimizely: globalSets(handle: "globalOptimizely") {
        ... on globalOptimizely_GlobalSet {
          optimizelyAceTemplates {
            ... on optimizelyAceTemplates_aceTemplateTypes_BlockType {
              optimizelyEnabled: bundlesTemplateOptimizely
            }
          }
        }
      }
      structuredData: entries(site: $site, slug: $slug) {
        ... on aceDrivingTemplates_bundlesTemplate_Entry {
          structuredData
        }
      }
      header: globalSets(handle: "aceHeader") {
        ... on aceHeader_GlobalSet {
          ...HeaderFragmentEntry
        }
      }
    }

    fragment productEntry_fragment on EntryInterface {
      ... on aceDrivingProducts_xgritProduct_Entry {
        productPageUrl
        productState
        xgritBundleCoupon
        xgritCoupon
        xgritProductDisableRsa
        xgritProductId
        productCategory {
          ... on drivingCategories_Category {
            aceCategoryCode
            title
          }
        }
      }
    }
    ${HeaderFragment.header}
    ${SettingsFragment.settings}
    ${SEOBundlesFragment.seoContent}
    ${BundlesFragment.bundlesTemplate}
  `,
};
