import { DOCUMENT } from '@angular/common';
import type { OnDestroy, OnInit } from '@angular/core';
import { Component, EventEmitter, HostListener, Inject, Input, Output } from '@angular/core';
import { BrandConfigurationService } from '@core-mkt/services/configuration/brand-configuration.service';
import { RequestService } from '@core-mkt/services/url/url.service';
import { faCaretDown, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import type { DropdownItems } from './vision-dropdown';
import { DropdownData } from './vision-dropdown';

@Component({
  selector: 'vision-dropdown',
  templateUrl: './vision-dropdown.component.html',
  styleUrls: ['./vision-dropdown.component.scss'],
})
export class VisionDropdownComponent implements OnInit, OnDestroy {
  @Input() dropdownData: DropdownData;
  @Output() changeEvent = new EventEmitter<string | number | boolean | Event>(); // Event emitter for dropdown change
  dropdownCollapse = false;
  faCaretDown = faCaretDown;
  faChevronDown = faChevronDown;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private brand: BrandConfigurationService,
    private requestService: RequestService,
  ) {}

  get dropdownItems(): DropdownItems[] {
    return this.dropdownData.dropdownItems;
  }

  get dropdownPlaceholder(): string {
    return this.dropdownData.dropdownPlaceholder;
  }

  get dropdownTopLabel(): string {
    return this.dropdownData.dropdownTopLabel;
  }

  get dropdownAlignment(): string {
    if (this.dropdownData.dropdownAlignment !== undefined) {
      return this.dropdownData.dropdownAlignment.replace('mx-auto', 'mx-0 xs:mx-auto text-center');
    } else {
      return 'mx-0 xs:mx-auto text-center';
    }
  }

  toggleDropdown(): void {
    this.dropdownCollapse = !this.dropdownCollapse;
  }

  // Sanitize links that are not relative and are the current brand domain
  sanitizeLinks(): void {
    this.dropdownData?.dropdownItems.forEach((item) => {
      // sanitize if on current brand and checkout or blog is not included in link
      if (
        item.dropdownLink?.includes(this.brand.hostName) &&
        !(item.dropdownLink?.includes('/checkout') || item.dropdownLink?.includes('/blog/'))
      ) {
        item.dropdownLink = item.dropdownLink.replace(/^(?:[^/]*\/){3}/, '/');
      }
      const urlPattern = /^(?:https?|ftp):\/\/\S+$/i;
      // convert relative urls that include checkout or blog into absolute urls
      if (
        !item.dropdownLink?.includes(this.brand.hostName) &&
        !urlPattern.test(item.dropdownLink) &&
        (item.dropdownLink?.includes('/checkout') || item.dropdownLink?.includes('/blog/'))
      ) {
        const newLink: string = item.dropdownLink.replace(this.document.location.origin, '');
        if (this.brand.hostName.includes('aceableagent') && item.dropdownLink.includes('/checkout')) {
          item.dropdownLink = `https://checkout.${this.brand.hostName}${newLink}`;
        } else {
          item.dropdownLink = `https://www.${this.brand.hostName}${newLink}`;
        }
      }
    });
  }

  /**
   * Checks if the user is using Safari on a mobile device.
   * @returns {boolean} True if the user is on a mobile device using Safari.
   */
  isMobileSafari(): boolean {
    const userAgent = navigator.userAgent;
    // Check if browser is Safari
    const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
    // Check if user is on mobile
    const isMobile = /Mobi/i.test(userAgent);
    return isSafari && isMobile;
  }

  openInNewTab(url: string): void {
    // Check if the user is using Safari on a mobile device.
    // If true, open the link in the same tab (Safari doesn't allow opening new tabs with JS).
    // If false, open the link in a new tab.
    if (this.isMobileSafari()) {
      this.document.location.href = url;
    } else {
      window.open(url, '_blank');
    }
  }

  linkNavigationLogic(event: Event): void {
    const searchParams = this.document.location?.search.replace('?', '') ?? '';
    const dropdownLink = (event.target as HTMLSelectElement).value;
    const linkWithSearchParams = dropdownLink.includes('?')
      ? `${dropdownLink}&${searchParams}`
      : `${dropdownLink}?${searchParams}`;
    const urlPattern = /^(?:https?|ftp):\/\/\S+$/i;
    // If link is not current brand and unsanitized or has Open in New Tab enabled
    const isExternalLink =
      (!dropdownLink.includes(this.brand.hostName) && urlPattern.test(dropdownLink)) ||
      (event.target as HTMLSelectElement).options[(event.target as HTMLSelectElement).selectedIndex].className.includes(
        'new-tab',
      );
    if (isExternalLink) {
      // Check if opening new tab for current domain or an outside domain. Search params do not persist on outside domains
      const linkToOpen =
        dropdownLink.includes('.com') && !dropdownLink.includes(this.brand.hostName)
          ? dropdownLink
          : linkWithSearchParams;
      this.openInNewTab(linkToOpen);
    } else {
      const linkToNavigate =
        dropdownLink.includes('/checkout') || dropdownLink.includes('/blog/')
          ? linkWithSearchParams
          : `${this.document.location.origin}${linkWithSearchParams}`;
      this.document.location.href = linkToNavigate;
    }
  }

  resetDropdownIndex(el: string): void {
    const dropdown = this.document.getElementById(el) as HTMLSelectElement;
    if (el) {
      dropdown.selectedIndex = 0;
    }
  }

  onChange(event: Event): void {
    this.linkNavigationLogic(event);
    this.resetDropdownIndex('mobile_select');
  }

  /**
   * Handles the change event for the vision dropdown component.
   *
   * @param value - The new value of the dropdown, which can be a string, number, or boolean.
   * @returns void
   */
  handleChange(value: Event | string | number | boolean): void {
    // Validate if the value is emitted from the select element
    const isEvent = value instanceof Event;
    // Get the value from the select element
    const valueFromSelect = (event.target as HTMLSelectElement)?.value;

    this.changeEvent.emit(isEvent ? valueFromSelect : value);
  }

  ngOnInit(): void {
    this.sanitizeLinks();
    this.document.addEventListener('click', this.onDocumentClick.bind(this));
  }

  ngOnDestroy() {
    this.document.removeEventListener('click', this.onDocumentClick.bind(this));
  }

  @HostListener('click', ['$event'])
  onComponentClick(event: Event) {
    event.stopPropagation();
  }

  onDocumentClick(event: Event) {
    if (this.dropdownCollapse) {
      this.dropdownCollapse = false;
    }
  }

  /**
   * Gets the aria-label value to the anchor element
   * From the link URL
   * Removing dashes and slashes from a link
   * @param link The string with the URL
   */
  getAriaLabelFromUrlLink(link: string): string {
    const sectionList = link?.split('/');
    const lastSection = sectionList[sectionList.length - 2];
    const textAriaLabel = lastSection?.replace(/\-/gi, ' ').trim();
    return textAriaLabel?.replace(/(^\w{1})|(\s+\w{1})/g, (l) => l.toUpperCase());
  }

  getIdToAriaLabelledbyContext(label: string): string {
    const cleanText = this.requestService.getAriaLabelledby(label);
    return cleanText + '-context';
  }

  getTextContext(link: string): string {
    if (link?.startsWith('http')) {
      return this.getAriaLabelFromUrlLink(link);
    }
    return this.requestService.getCompleteTextContextForButton(link);
  }
}
