import { Component, Input } from '@angular/core';
import { RequestService } from '@core-mkt/services/url/url.service';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { HeaderNavLink } from '../header/header';

@Component({
  selector: 'ace-header-navlink',
  templateUrl: './header-nav-link.component.html',
})
export class HeaderNavLinkComponent {
  @Input() data: HeaderNavLink;
  showComplexMenu = false;
  showSimpleMenu = false;
  tabShift: boolean;
  hovered = false;
  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;

  constructor(
    private requestService: RequestService
  ) {}

  get menuSize(): string | undefined {
    return this.data?.menuSize === '340px' ? 'w-[340px]' : 'w-[180px]';
  }

  get isMyAccountLink(): boolean {
    return this.data?.linkLabel === 'My Account';
  }

  /**
   * Show dropdown menus when nav item is focused and enter is pressed
   */
  enterPress(): void {
    this.showSimpleMenu = !!this.data.ddList;
    this.showComplexMenu = !!this.data.complexDdList;
  }

  /**
    * Hide dropdown menus after the last item is unfocused/blurred and tabbed forward.
    * If the last item is tabbed backwards, the menu stays open.
    * If the first item is tabbed backwards, the menu closes.
    @param event The blur event that triggered the function.
    */
  onBlur(event): void {
    const isFirstSimpleItem = event.target.classList.contains('first-simple-item');
    const isLastSimpleItem = event.target.classList.contains('last-simple-item');
    const isFirstColumnFirstItem =
      event.target.parentNode.id === 'first-column' && event.target.classList.contains('first-item');
    const isLastColumnLastItem =
      event.target.parentNode.id === 'last-column' && event.target.classList.contains('last-item');

    // Update the visibility of the menus based on the tabbing direction and item position.
    if ((isLastColumnLastItem && !this.tabShift) || (isFirstColumnFirstItem && this.tabShift)) {
      this.showComplexMenu = false;
    }
    if ((isLastSimpleItem && !this.tabShift) || (isFirstSimpleItem && this.tabShift)) {
      this.showSimpleMenu = false;
    }
  }

  /**
   * Checks if user is tabbing backwards
   * @param event The keydown KeyboardEvent that triggers the function
   */
  onKeyDown(event: KeyboardEvent): void {
    this.tabShift = event.shiftKey && event.key === 'Tab' ? true : false;
  }

  splitLabel(label: string): { firstWord: string; rest: string } {
    const index = label.indexOf(' ');
    if (index === -1) {
      return { firstWord: label, rest: '' };
    }
    return {
      firstWord: label.substring(0, index),
      rest: label.substring(index + 1),
    };
  }
}
