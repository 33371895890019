import { Location } from '@angular/common';
import type { ErrorHandler } from '@angular/core';
import { Injectable } from '@angular/core';
import { EnvService } from '@core-mkt/services/env/env.service';
import type { ConsoleData } from './error-handler';

import type { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import type { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ServerErrorHandlerService implements ErrorHandler, HttpInterceptor {
  visionData: ConsoleData;

  constructor(private location: Location, private env: EnvService) {
    const path = this.location.path();
    const slug = path.substring(1, path.length - 1);

    this.visionData = {
      appParams: this.env.get.xgritApiConfig.baseParams,
      urlPath: path,
      craft: {
        slug: this.env.get.slugPrefix + slug.replace(/\//g, '--'),
        site: this.env.get.brandConfig.craftSite,
      },
    };
  }

  /**
   * This is an interceptor that handles HTTP errors.
   *
   * It intercepts the request, and if there is an error, it will throw a
   * `HttpErrorResponse`.
   *
   * @param request The request to intercept.
   * @param next The next `HttpHandler` in the chain.
   * @returns The `Observable` of the `HttpEvent`.
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }),
    );
  }

  /**
   * handleError catches any unhandled runtime error on the server side and formats it for Coralogix
   *
   * @param {object} error - Native error throwned by Angular
   */
  handleError(error: any): void {
    // error.error can contain extremly long data or sensitive data hence removing it
    delete error.error;
    this.visionData.context = this.getContext(error.status);
    this.visionData.nativeErr = JSON.stringify(error, Object.getOwnPropertyNames(error));
    const jsonErr = JSON.stringify(this.visionData);
    console.error(this.stringParser(jsonErr));
  }

  /**
   * user initiated server side log and formats it for Coralogix
   *
   * @param {any} log - Data passed in by user
   */
  log = (msg) => {
    this.visionData.userInitiatedLog = JSON.stringify(msg);
    const jsonErr = JSON.stringify(this.visionData);
    console.error(this.stringParser(jsonErr));
  };

  /**
   * user initiated server side warning and formats it for Coralogix
   *
   * @param {any} warn - Data passed in by user
   */
  warning = (warn) => {
    this.visionData.userInitiatedLog = JSON.stringify(warn);
    const jsonErr = JSON.stringify(this.visionData);
    console.warn(this.stringParser(jsonErr));
  };

  /**
   * user initiated server side error and formats it for Coralogix
   *
   * @param {any} error - Data passed in by user
   */
  error = (err) => {
    this.visionData.userInitiatedLog = JSON.stringify(err);
    const jsonErr = JSON.stringify(this.visionData);
    console.error(this.stringParser(jsonErr));
  };

  /**
   * Regex containts any special character to remove for cleaniness
   *
   * @param {any} str - String passed in by user
   */
  private stringParser = (str) => {
    return str.replace(/(\\n|\\)/g, '');
  };

  /**
   * get context based on status code. This is used to determine if the error is a runtime error or http error
   *
   * @param {number} status - status code
   */
  private getContext = (status) => {
    switch (status) {
      case undefined:
        return 'Runtime JS Error';
      case 0:
        return 'Http Preflight Error. Check if url request exists or CORS error';
      default:
        return 'Http Error. Please look at the nativeErr property for more details';
    }
  };
}
