<header class="md:bg-ace-pink-600" *ngIf="showHeader">
  <skip-nav [buttonBg]="'bg-vis-regular'"></skip-nav>
  <div class="container flex lex-col items-center !py-4">
    <a href="https://www.aarpdriversafety.org/">
      <img
        class="hidden md:block max-w-none"
        src="https://xgrit-ecom.imgix.net/aarp/AARP-logo-white.png"
        width="153px"
        height="32px"
        alt="AARP Logo"
      />
      <img
        class="md:hidden block max-w-none"
        src="https://xgrit-ecom.imgix.net/aarp/AARP-logo-red.png"
        width="153px"
        height="32px"
        alt="AARP Logo"
      />
    </a>
    <nav aria-label="menu-nav" class="hidden md:flex w-full items-center justify-end text-ace-grey-300 gap-6">
      <a
        class="group flex items-center gap-1"
        [href]="helpLink.linkUrl"
        [attr.data-formelementid]="helpLink.formElementId ? helpLink.formElementId : null"
      >
        <fa-icon class="text-lg" [icon]="faQuestionCircle"></fa-icon>
        <span class="header-button-text">{{ helpLink.linkLabel }}</span>
      </a>
      <a
        class="group flex items-center gap-1"
        [href]="loginLink.linkUrl"
        [attr.target]="loginLink.openNewTab ? '_blank' : ''"
        [attr.data-formelementid]="loginLink.formElementId ? loginLink.formElementId : null"
      >
        <fa-icon class="text-lg" [icon]="faUserCircle"></fa-icon>
        <span class="header-button-text">
          {{ loginLink.linkLabel }}
        </span>
      </a>
    </nav>
    <div class="flex md:hidden flex-col w-full">
      <button class="menu-button flex justify-end items-center gap-1" (click)="showMenu = !showMenu">
        <span *ngIf="!showMenu" class="font-bold text-xs">MENU</span>
        <img
          alt="hamburger-icon"
          [ngClass]="{ hidden: showMenu, block: !showMenu }"
          width="32px"
          height="32px"
          class="w-8 h-8"
          [attr.loading]="'eager'"
          src="https://xgrit-ecom.imgix.net/aarp/AARP_Hamburger_Icon.svg"
        />
        <img
          alt="cross-icon"
          [ngClass]="{ hidden: !showMenu, block: showMenu }"
          width="32px"
          height="32px"
          src="https://xgrit-ecom.imgix.net/aarp/AARP_Cross_Icon.svg"
        />
      </button>
    </div>
  </div>
</header>
<div class="border-b border-ruler">
  <div class="container hidden md:flex !py-0">
    <nav aria-label="header-nav" class="flex items-center w-full gap-8">
      <a
        class="nav-link"
        *ngFor="let link of mainHeaderLinks"
        [href]="link.linkUrl"
        [class.active]="link.linkUrl === currentUrl"
        [attr.target]="link.openNewTab ? '_blank' : ''"
        [attr.data-formelementid]="link.formElementId ? link.formElementId : null"
      >
        {{ link.linkLabel }}
      </a>
    </nav>
    <div class="flex items-center">
      <a
        class="secondary-button whitespace-nowrap px-7 h-11"
        [href]="registerLink.linkUrl"
        [attr.data-formelementid]="registerLink.formElementId ? registerLink.formElementId : null"
      >
        {{ registerLink.linkLabel }}
      </a>
    </div>
  </div>
  <div class="container flex md:hidden gap-3 !pt-2.5 !pb-6">
    <a
      class="p-0 hover:!p-0 secondary-button h-11"
      [href]="loginLink.linkUrl"
      [attr.target]="loginLink.openNewTab ? '_blank' : ''"
      [attr.data-formelementid]="loginLink.formElementId ? loginLink.formElementId : null"
    >
      {{ loginLink.linkLabel }}
    </a>
    <a class="!p-0 hover:!p-0 primary-button h-11" [href]="registerLink.linkUrl">
      {{ registerLink.linkLabel }}
    </a>
  </div>
  <div *ngIf="showMenu" class="container h-screen flex flex-col !py-4 border-t">
    <a
      class="nav-link"
      *ngFor="let link of mainHeaderLinks"
      [href]="link.linkUrl"
      [attr.target]="link.openNewTab ? '_blank' : ''"
      [attr.data-formelementid]="link.formElementId ? link.formElementId : null"
    >
      {{ link.linkLabel }}
    </a>
  </div>
</div>
