import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { InsertableModule } from '@aceable/core';
import { MarketingModule } from '@core-mkt/shared/components/marketing/marketing.module';

import { FooterModule as AAFooterModule } from '@core-mkt/aa/src/app/components/footer/footer.module';
import { FooterModule } from '@core-mkt/brands/ace/src/app/components/footer/footer.module';
import { HeaderModule } from '@core-mkt/brands/ace/src/app/components/header/header.module';
import { BannerModule } from '@core-mkt/shared/components/banner/banner.module';
import { EmailFormModule } from '@core-mkt/shared/components/email-form/email-form.module';
import { GraphicBannerModule } from '@core-mkt/shared/components/graphic-banner/graphic-banner.module';
import { LayoutModule } from '@core-mkt/shared/components/layout/layout.module';
import { ProductCardModule } from '@core-mkt/shared/components/product-card/product-card.module';
import { ReviewsModule } from '@core-mkt/shared/components/reviews/reviews.module';
import { RsaModule } from '@core-mkt/shared/components/rsa/rsa.module';
import { TrustpilotModule } from '@core-mkt/shared/components/trustpilot/trustpilot.module';
import { VisionModalModule } from '@core-mkt/shared/components/vision-modal/vision-modal.module';
import { BreadcrumbModule } from '../../components/breadcrumb/breadcrumb.module';
import { ResourcesModule } from '../../components/resources/resources.module';
import { TemplateCategoryRoutingModule } from './template-category-routing.module';
import { TemplateCategoryComponent } from './template-component/template-category.component';

@NgModule({
  declarations: [TemplateCategoryComponent],
  exports: [TemplateCategoryComponent],
  imports: [
    CommonModule,
    MarketingModule,
    InsertableModule,
    TemplateCategoryRoutingModule,
    InsertableModule,
    HeaderModule,
    FooterModule,
    BannerModule,
    LayoutModule,
    VisionModalModule,
    ReviewsModule,
    TrustpilotModule,
    BreadcrumbModule,
    ProductCardModule,
    RsaModule,
    ResourcesModule,
    GraphicBannerModule,
    EmailFormModule,
    AAFooterModule,
  ],
})
export class TemplateCategoryModule {}
