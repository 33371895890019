/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, Input } from '@angular/core';
import { EnvService } from '@core-mkt/services/env/env.service';
import { RequestService } from '@core-mkt/services/url/url.service';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import type { IconPrefix } from '@fortawesome/fontawesome-svg-core';
import {
  faFacebookSquare,
  faInstagram,
  faLinkedin,
  faTiktok,
  faXTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import type { FooterLinkGroup } from '../footer/footer';

@Component({
  selector: 'footer-link',
  templateUrl: './footer-link.component.html',
  styleUrls: ['./footer-link.component.scss'],
})
export class FooterLinkComponent {
  @Input() links: FooterLinkGroup[];
  @Input() footerLocation: string;
  @Input() alignClass = '';
  @Input() linkClass = '';
  iconPrefix: IconPrefix = 'fab';

  constructor(library: FaIconLibrary, private requestService: RequestService, private env: EnvService,) {
    library.addIcons(faXTwitter, faTiktok, faYoutube, faFacebookSquare, faLinkedin, faInstagram);
  }

  get flexDirection(): string {
    if (this.footerLocation !== 'bottom' && this.footerLocation !== 'social') {
      return 'flex-wrap flex-col';
    }
    if (this.footerLocation === 'bottom') {
      return 'md:flex-row';
    }
    return 'md:flex-wrap md:flex-col';
  }

  get layout(): string {
    if (this.footerLocation === 'bottom') {
      return 'lg:justify-end';
    }
    return 'tablet-halved';
  }

  get urlPartnershipsLink(): string {
    return 'https://' + this.env.get.brandConfig.url + '/driving-partnerships/';
  }

  get ariaContextPartnerships(): string {
    return 'Driving Partnerships';
  }

  get urlSupportLink(): string {
    return 'https://support.aceabledriving.com/hc/en-us/'
  }

  get ariaContextSupport(): string {
    return 'Help Center';
  }

  validateGetAriaLabelledby(link: string, label?: string): string {
    if (this.requestService.isUrlSite(link)) {
      return this.requestService.getSiteDescription(link);
    }
    if (link === this.urlPartnershipsLink) {
      return this.ariaContextPartnerships;
    }
    if (link === this.urlSupportLink) {
      return this.ariaContextSupport;
    }
    if (this.footerLocation === 'bottom' && label) {
      return label;
    }
    return undefined;
  }
}
