import { AceCraftQueries } from '@core-mkt/ace/src/app/craft/craft-queries';
import { AaCraftQueries } from '@core-mkt/brands/aa/src/app/craft/craft-queries';
import { AarpCraftQueries } from '@core-mkt/brands/aarp/src/app/craft/craft-queries';
import { EnvService } from '@core-mkt/services/env/env.service';
import { SingleTemplateCraftQueries } from '@core-mkt/shared/craft/craft-queries';
import type { CraftSiteTemplateMapping } from './craft-site-template-mapping';

/** TODO: refactor conditional query below to point to SingleTemplate Query after migration, remove slugPrefix and useV3 fields from ENV file. */

export const CraftQueries: CraftSiteTemplateMapping[] = [
  {
    site: 'ACE',
    queries: new EnvService().get.useV3 ? SingleTemplateCraftQueries : AceCraftQueries,
  },
  {
    site: 'AA',
    queries: new EnvService().get.useV3 ? SingleTemplateCraftQueries : AaCraftQueries,
  },
  {
    site: 'AARP',
    queries: new EnvService().get.useV3 ? SingleTemplateCraftQueries : AarpCraftQueries,
  },
  {
    site: 'DEC',
    queries: SingleTemplateCraftQueries,
  },
  {
    site: 'INS',
    queries: SingleTemplateCraftQueries,
  },
  {
    site: 'MTG',
    queries: SingleTemplateCraftQueries,
  },
];
