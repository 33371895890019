import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SkipNavComponent } from './skip-nav/skip-nav.component';

@NgModule({
  declarations: [SkipNavComponent],
  imports: [CommonModule],
  exports: [SkipNavComponent],
})
export class SkipNavModule {}
