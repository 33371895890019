<!-- Nav Link -->
<span
  tabindex="0"
  class="header__link relative flex items-center h-full cursor-pointer text-base border-b-[3px] font-normal {{ hovered ? 'font-bold border-b-ace-teal-700' : 'border-b-transparent'}} "
  (mouseleave)="hovered = false"
  (mouseenter)="hovered = true"
  (keydown.enter)="enterPress()"
  *ngIf="data.isDropDown"
>
  {{ data.linkLabel }}
  <img [class.rotate-180]="hovered" class="ml-[6px] w-[9px] h-[24px]" [attr.loading]="'eager'" height="24px" width="9px" src="https://xgrit-ecom.imgix.net/ace/Drop-Down.svg?v=1712674559" alt="" aria-hidden="true" />
</span>
<span
  tabindex="0"
  class="header__link relative flex items-center h-full cursor-pointer text-base border-b-[3px] font-normal {{ hovered ? 'font-bold border-b-ace-teal-700' : 'border-b-transparent'}} "
  (mouseleave)="hovered = false"
  (mouseenter)="hovered = true"
  *ngIf="!data.isDropDown"
 >
  <a
    class="header__link relative flex align-middle"
    [href]="data.linkUrl"
    *ngIf="!data.isDropDown"
    tabindex="0"
    [attr.aria-labelledby]="requestService.getAriaLabelledby(data.linkLabel) + '-nav-label'"
  >
    {{ data.linkLabel }}
    <!-- Hidden Context -->
    <span [attr.id]="requestService.getAriaLabelledby(data.linkLabel) + '-nav-label'" class="sr-only">{{requestService.getCompleteTextContextForButton(data.linkUrl, data.linkLabel)}}</span>
  </a>
</span>
<!-- Simple DropDown -->
<div
  (mouseleave)="hovered = false; showSimpleMenu = false"
  (mouseenter)="hovered = true"
  id="header__dd-simple"
  class="z-30 absolute top-full py-v-spacing sm:px-v-spacing shadow-header bg-white rounded-b-md {{ menuSize }}"
  [class.hidden]="(!data.ddList || !hovered) && !showSimpleMenu "
  [ngClass]="{ 'text-right': isMyAccountLink, 'right-0': isMyAccountLink }"
>
  <div *ngFor="let link of data.ddList; let last = last; let first = first">
    <div class="{{ last ? 'mb-0' : 'mb-4' }}">
      <a class="text-base font-medium text-ace-grey-900 hover:text-ace-teal-800 block w-full {{ last ? 'last-simple-item' : first ? 'first-simple-item' : '' }}"
        [href]="link.linkUrl"
        (blur)="onBlur($event)"
        (keydown)="onKeyDown($event)"
        tabindex="0"
        [attr.aria-labelledby]="requestService.getAriaLabelledby(link.linkLabel) + '-nav-label'"
        >
        <span [innerHTML]="link.linkLabel"></span>
        <!-- Hidden Context -->
        <span [attr.id]="requestService.getAriaLabelledby(link.linkLabel) + '-nav-label'" class="sr-only">{{requestService.getCompleteTextContextForButton(link.linkUrl, link.linkLabel)}}</span>
      </a>
    </div>
  </div>
</div>
