<!-- Card Reviews -->
<div
  class="card-reviews"
  [class]="
    backgroundInfo && backgroundInfo.url ? textColorOverride + 'bg-no-repeat bg-center bg-cover' : backgroundInfo
  "
  [style.backgroundImage]="backgroundInfo && backgroundInfo.url && 'url(' + backgroundInfo.url + ')'"
>
  <div class="container wysiwyg {{ containerClasses }}">
    <div *ngIf="title" class="wysiwyg tab:px-v-spacing max-w-vision mx-auto" [innerHTML]="title"></div>
    <div class="flex flex-col md:flex-row items-baseline">
      <div
        class="w-auto md:w-1/3 flex-col py-v-spacing sm:px-v-spacing mb-v-spacing bg-white rounded border"
        [class.md:mr-v-spacing]="!last"
        *ngFor="let review of cardReviews; let last = last"
      >
        <div class="reviewer-img">
          <img
            class="rounded-full mx-auto"
            width="100"
            height="100"
            [src]="review.image.src"
            [alt]="review.image.altText ? review.image.altText : ''"
          />
        </div>
        <div class="text-center text-ace-teal-600 py-v-spacing">{{ review.name }}</div>
        <p class="italic font-light text-vis-regular">{{ review.review }}</p>
      </div>
    </div>
  </div>
</div>
