import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import type { MetaDefinition } from '@angular/platform-browser';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BrandConfigurationService } from '../configuration/brand-configuration.service';
import { EnvService } from '../env/env.service';
import type { SeoData } from './seo-data';

@Injectable({
  providedIn: 'root',
})
export class ServerSeoDataService {
  fullUrl: string;

  constructor(
    @Inject(DOCUMENT) private dom,
    private titleService: Title,
    private meta: Meta,
    private bfs: BrandConfigurationService,
    private readonly router: Router,
    private env: EnvService,
  ) {
    // @ts-ignore
    //To be refactored in phase 3 once separate folders are created
    const hostname = 'https://' + this.env.get.brandConfig.url;
    this.fullUrl = `${hostname}${this.router.url}`;
  }

  private setCanonicalUrl(canonicalUrl: string): void {
    if (canonicalUrl) {
      const link: HTMLLinkElement = this.dom.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', canonicalUrl);
      this.dom.head.appendChild(link);
    }
  }

  private createMetadataArray(seoData: SeoData): MetaDefinition[] {
    if (!seoData) {
      return;
    }

    const { description, keywords, ogUrl, ogTitle, ogType, ogImage, noindex, nofollow } = seoData;
    const robots = `${noindex ? 'noindex' : 'index'}, ${nofollow ? 'nofollow' : 'follow'}`;
    const defaultOgImage = this.env.get.defaultOgImage;

    const metaTags = [
      { name: 'description', content: description },
      { name: 'keywords', content: keywords },
      { name: 'robots', content: robots },

      // Social verifications
      {
        name: 'facebook-domain-verification',
        content: this.env.get.facebookDomainVerification,
      },
      {
        name: 'google-site-verification',
        content: this.env.get.googleSiteVerification,
      },

      /**
       * Open Graph
       * @see https://ogp.me/
       */
      { property: 'og:description', content: description }, // Not a Craft field
      { property: 'og:locale', content: 'en_US' }, // Not a Craft field
      { property: 'og:site_name', content: this.bfs.name }, // Not a Craft field
      { property: 'og:image', content: ogImage || defaultOgImage },
      { property: 'og:type', content: ogType || 'website' },
      { property: 'og:title', content: ogTitle || seoData.pageTitle },
      { property: 'og:url', content: ogUrl || seoData.canonical || this.fullUrl },

      /**
       * Twitter
       * @see https://developer.twitter.com/en/docs/twitter-for-websites/cards/overview/markup
       */
      { property: 'twitter:card', content: 'summary_large_image' },
      { property: 'twitter:image', content: ogImage || defaultOgImage },
      { property: 'twitter:site', content: this.env.get.twitterHandle },
    ];

    return metaTags.filter((tag) => tag.content); // Filter for truthy values
  }

  private setMetadata(seoData: SeoData): void {
    this.meta.addTags(this.createMetadataArray(seoData), true);
  }

  private setTitle(pageTitle: string): void {
    this.titleService.setTitle(pageTitle);
  }

  setSeoProperties(seoData: SeoData): void {
    this.setTitle(seoData?.pageTitle);
    this.setMetadata(seoData);
    this.setCanonicalUrl(seoData?.canonical || this.fullUrl);
  }
}
