import { brandConfig } from '@core-mkt/brands/aa/config';
import type { Env } from '@core-mkt/services/env/env';

export const environment: Env = {
  production: false,
  envMode: 'stage',
  adobeAnalytics: '',
  craftApiUrl: 'https://craft.ecom-stage.aceable.com/api/',
  xgritCheckoutUrl: 'https://checkout.ecom-stage.aceableagent.com/checkout/',
  slugPrefix: 'real-estate--',
  useV3: false,
  xgritApiConfig: {
    url: 'https://stg.xgritapi.com/api/1/',
    aceauth: 'Basic MDY5MGI2MDc4ZjBmNTUwOTJlMmFiZDNlMmY3ODk3Zjg6',
    baseParams: {
      appType: 'RE',
      platform: 'MKT',
      brandId: 'AA',
    },
  },
  defaultOgImage: 'https://xgrit-ecom.imgix.net/ace/default-og-image-real-estate.jpg',
  optimizelyId: '15103740412',
  optimizelyHandle: 'optimizelyAceAgentTemplates',
  brandConfig,
  sentryDsn: 'https://cc152459679944568ad4c19827511af1@o1007654.ingest.sentry.io/6239874',
  segmentId: 'cl6xF8w1l2g3pFA0Y5mZ71EtaBOnxqZ1',
  segmentCdnProxy: 'sgprox.xgritapi.com', //Also need to change the preload on index files
  segmentApiProxy: 'sgapiprox.xgritapi.com',
  twitterHandle: '@aceableagent',
  facebookDomainVerification: 'lgon5jyv12wnetpqhj6r6y0jf7zx1j',
  googleSiteVerification: 'gzm5e4WGRt9yDaBadAI6HY00S6p--bVqibzx5Ocs_ok',
  wistiaVerification: 'Bearer f70f44da17d8dd9a2a09df5c84c1ea2ba9f23d1b5edea43f9a2862cfe1fc99fd',
  ipifyUrl: '',
  xccApiUrl: '',
  gladlyHelpCenterEnvironment: {
    api: 'https://aceable.us-1.gladly.com',
    orgId: 'VJOTwOvcQ6WJr85bp0qL0g',
    brandId: 'NNV2pYNGQy2yUjo5bOluRw',
    cdn: 'https://cdn.gladly.com',
    selector: 'gladly-help-center',
  },
};
