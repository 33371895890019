import type { CraftQuery } from '@core-mkt/craft/craft-query';
import type { CraftTemplateType } from '@core-mkt/craft/craft-template-types';
import { TemplateSingleQuery } from '@core-mkt/shared/templates/single-template/template-component/template-single-query';
import { Template404Query } from './template-404-query';
import { TemplateBundlesQuery } from './template-bundles-query';
import { TemplateCategoryQuery } from './template-category-query';
import { TemplateDisambigQuery } from './template-disambig-query';
import { TemplateGenericQuery } from './template-generic-query';
import { TemplateHomepageQuery } from './template-homepage-query';
import { TemplateProductQuery } from './template-product-query';

export const AceCraftQueries = new Map<CraftTemplateType, CraftQuery>([
  ['aceHomepageTemplate', TemplateHomepageQuery],
  ['genericTemplate', TemplateGenericQuery],
  ['categoryTemplate', TemplateCategoryQuery],
  ['productTemplate', TemplateProductQuery],
  ['disambigTemplate', TemplateDisambigQuery],
  ['bundlesTemplate', TemplateBundlesQuery],
  ['404Template', Template404Query],
  ['visionTemplateEntry', TemplateSingleQuery],
]);
