import type { OnInit } from '@angular/core';
import { Component, HostBinding, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import type { PageDataResponse } from '@core-mkt/resolvers/page-data/page-data-response';
import { cloneDeep } from 'lodash-es';

import type { HeaderNavLink } from './header';
import { headerData, myAccountData } from './header-data';

@Component({
  selector: 'ace-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @HostBinding('class.sticky-header') headerSticky = false;
  private _activateMobileMenu = false;
  headerData: HeaderNavLink[] = headerData;
  myAccountData: HeaderNavLink[] = myAccountData;
  resolverData: PageDataResponse;
  showDefaultNav = true;

  constructor(private route: ActivatedRoute, private renderer: Renderer2) {
    if (this.route.snapshot) {
      this.resolverData = cloneDeep(this.route.snapshot.data.pageData);
    }
  }

  ngOnInit(): void {
    if (this.resolverData.craftData?.headerData) {
      this.headerSticky = this.resolverData.craftData.headerData.isNavSticky;
    }
    if (this.resolverData?.craftData?.headerData?.hideNav) {
      this.showDefaultNav = false;
    }
  }

  get headerLogo(): string {
    return 'https://xgrit-ecom.imgix.net/v3_images/transforms/_350x89_crop_center-center_none/ace_ins_logo.svg?v=1730836120&auto=compress,format';
  }

  get activateMobileMenu(): boolean {
    return this._activateMobileMenu;
  }

  set activateMobileMenu(value: boolean) {
    this._activateMobileMenu = value;
    this.setBodyScroll(!value);
  }

  private setBodyScroll(enable: boolean): void {
    if (enable) {
      this.renderer.removeClass(document.body, 'overflow-hidden');
    } else {
      this.renderer.addClass(document.body, 'overflow-hidden');
    }
  }

  toggleMobileMenu(): void {
    this.activateMobileMenu = !this.activateMobileMenu;
  }
}
