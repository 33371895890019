import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { CarouselItem } from './study-plan-builder.models';
import { ProductBuilderData } from './study-plan-builder.models';

@Component({
  selector: 'study-plan-builder',
  templateUrl: './study-plan-builder.component.html',
})
export class StudyPlanBuilderComponent implements OnInit {
  @Input() data: ProductBuilderData;
  hasCarousel = false;
  hasWysiwygContent = false;
  hasProductBuilderForm = false;
  carouselData: CarouselItem[];
  wysiwygContent: string;

  ngOnInit(): void {
    //TODO: This is a typo on V2 that is already fixed for V3 we remove this line when we remove V2
    if (this.data.wyswygContent) {
      this.data.wysiwygContent = this.data.wyswygContent;
    }
    if (this.data.carousel && this.data.carousel.length > 0) {
      this.carouselData = this.data.carousel;
      this.hasCarousel = true;
    } else if (this.data.imageCarousel?.length > 0) {
      this.carouselData = this.data.imageCarousel;
      this.hasCarousel = true;
    }
    if (this.data.wysiwygContent && this.data.wysiwygContent !== null && this.data.wysiwygContent?.length > 0) {
      this.hasWysiwygContent = true;
      this.wysiwygContent = this.data.wysiwygContent;
    } else if (this.data.wysiwygContent !== null && this.data.wysiwygContent?.length > 0) {
      this.wysiwygContent = this.data.wyswygContent;
    }
    this.hasProductBuilderForm = this.data.productBuilder?.length > 0;
  }
}
