import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ProductPickerModule } from '../product-picker/product-picker.module';
import { VisionButtonModule } from '../vision-button/vision-button.module';
import { VisionSelectModule } from '../vision-select/vision-select.module';
import { SideProductPickerComponent } from './side-product-picker/side-product-picker.component';

@NgModule({
  declarations: [SideProductPickerComponent],
  exports: [SideProductPickerComponent],
  imports: [CommonModule, VisionSelectModule, VisionButtonModule, ProductPickerModule, FontAwesomeModule],
})
export class SideProductPickerModule {}
