import { Injectable } from '@angular/core';
import type { FormPayload } from '@core-mkt/shared/components/email-form/email-form/email-form';
import axios from 'axios';
import { VerticalService } from '../vertical/vertical.service';

@Injectable({
  providedIn: 'any',
})
export class IterableService {
  constructor(private readonly vertical: VerticalService) {}
  /**
   * Adds a user to the mailing list by making a POST request to our AWS API Gateway proxy for Iterable.
   *
   * @param {FormPayload} data - The data to be sent in the request payload
   * @param {string} brandId - Brand ID that is added to payload. Valid IDs are 'ACE', 'DEC', 'IDS', 'FLVS'
   * @returns {Promise<any>} Resolves with the response data if successful
   * @throws {Error} If brandId is invalid or if the request fails
   *
   */
  async addToMailList(data: FormPayload, brandId: string): Promise<any> {
    const baseURL = 'https://3qm6hbevde.execute-api.us-east-1.amazonaws.com/default/iterable-list-subscribe';
    const validBrandIds = ['ACE', 'AA', 'INS', 'MTG', 'DEC', 'IDS', 'FLVS'];
    const brandsToReplace = ['AA', 'INS', 'MTG'];
    // AA, INS, MTG are not valid brandIds in the scope of the lambda/xgrit. We need to map AA back to ACE before sending to lambda
    const mappedBrandId = brandsToReplace.includes(brandId) ? 'ACE' : brandId;

    if (!validBrandIds.includes(brandId)) {
      console.error(`Invalid brandId: ${brandId}`);
      throw new Error(`Invalid brandId: ${brandId}`);
    }

    const payload = {
      ...data,
      brandId: mappedBrandId,
    };

    try {
      const response = await axios.post(baseURL, payload);

      // In rare cases, Iterable will return a 200 status code with a body indicating failure.
      if (response.data.failCount === 1) {
        throw new Error('User could not be added.');
      }
      return response.data;
    } catch (error: any) {
      console.error('Subscription failed:', error.message);
      throw new Error(`Subscription failed: ${error.message}`);
    }
  }

  async updateUser(data: any): Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
      api_key: this.vertical.getVerticalInfo().iterableApiKey,
    };
    const baseURL = 'https://api.iterable.com/api/users/update';
    const response = await axios.post(baseURL, data, { headers });
    return response;
  }
}
