/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const CategoryFragment = {
  categoryTemplate: gql`
    fragment categoryTemplate_fragment on categoryTemplate_MatrixField {
      ... on categoryTemplate_productCards_BlockType {
        backgroundColor
        backgroundImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        hideCta
        ctaStyle
        sectionTitle
        textColor
        useCompactCards
        productCardList {
          ... on productCardList_BlockType {
            cardBackgroundColor
            cardTextColor
            ctaLabel
            ctaLink
            ctaNewTab
            ctaNofollow
            learnMoreLink
            productEntry {
              ...productEntry_fragment
            }
            textContent
            learnMoreLabel
            learnMoreNewTab
            learnMoreNofollow
            starRating
            starCaption
            showSavings
            cardTitle
          }
        }
        typeHandle
      }
      ... on categoryTemplate_graphicBanner_BlockType {
        typeHandle
      }
      ... on categoryTemplate_productFilterCards_BlockType {
        backgroundColor
        backgroundImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        filterType
        ctaStyle
        useCompactCards
        productCardList {
          ... on productCardList_BlockType {
            cardBackgroundColor
            cardTextColor
            ctaLabel
            ctaLink
            ctaNewTab
            ctaNofollow
            learnMoreLink
            productEntry {
              ...productEntry_fragment
            }
            textContent
            learnMoreLabel
            learnMoreNewTab
            learnMoreNofollow
            starRating
            starCaption
            showSavings
            cardTitle
          }
        }
        sectionTitle
        textColor
        typeHandle
      }
      ... on categoryTemplate_trustpilot_BlockType {
        id
        showHideTrustpilot
        trustpilotDisplayType
        dataSku
        dataName
        typeHandle
      }
      ... on categoryTemplate_layout_BlockType {
        typeHandle
        backgroundColor
        backgroundImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        textColor
        image {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        imageAlignment
        layoutContent
        layoutRightContent
        layoutTitle
        showTrustpilot
        dropdownTopLabel
        dropdownPlaceholder
        dropdown {
          ... on dropdown_BlockType {
            dropdownLabel
            dropdownLink
            dropdownLinkNewTab
            dropdownLinkNofollow
          }
        }
        buttonLayout
        buttonAlignment
        buttons {
          ... on buttons_BlockType {
            buttonLabel
            buttonLink
            buttonLinkNewTab
            buttonLinkNofollow
            buttonType
          }
        }
        highlightedTextModal1 {
          ... on highlightedTextModal1_BlockType {
            modalContent
          }
        }
        highlightedTextModal2 {
          ... on highlightedTextModal2_BlockType {
            modalContent
          }
        }
      }
      ... on categoryTemplate_reviews_BlockType {
        typeHandle
        reviewType
        reviewTitle
        textColor
        backgroundColor
        backgroundImage {
          url
        }
      }
      ... on categoryTemplate_multiColumn_BlockType {
        tailwindClasses
        typeHandle
        backgroundColor
        backgroundImage {
          url
        }
        textColor
        componentTitle
        columnList: aceCategoryColumnList {
          ... on aceCategoryColumnList_BlockType {
            columnContent
            dropdownTitle
            dropdownAlignment
            dropdown {
              dropdownLabel
              dropdownLink
              dropdownLinkNewTab
              dropdownLinkNofollow
            }
          }
        }
        twoColumnDistribution
        highlightedTextModal1 {
          ... on highlightedTextModal1_BlockType {
            modalContent
          }
        }
        highlightedTextModal2 {
          ... on highlightedTextModal2_BlockType {
            modalContent
          }
        }
      }
      ... on categoryTemplate_multiCard_BlockType {
        typeHandle
        textColor
        backgroundColor
        textColor
        backgroundImage {
          url
        }
        componentTitle
        cardList {
          ... on cardList_BlockType {
            cardImage {
              url
            }
            imageShape
            cardContent
            cardButtonType
            cardButtonLabel
            cardButtonLink
            cardButtonLinkNewTab
            cardButtonLinkNofollow
          }
        }
      }
      ... on categoryTemplate_rsa_BlockType {
        rsaBullets {
          ... on rsaBullets_BlockType {
            bulletItem
          }
        }
        rsaCtaLabel
        rsaCtaLink
        rsaCtaLinkNewTab
        rsaCtaLinkNofollow
        rsaCtaStyle
        rsaDescription
        rsaImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        rsaTitle
        typeHandle
      }
      ... on categoryTemplate_layoutCard_BlockType {
        typeHandle
        backgroundColor
        textColor
        layoutContent
        cardAlignment
        cardType
        contentCardTitle
        cardImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        cardContent
        cardButtonType
        cardButtonLabel
        cardButtonLink
        cardButtonLinkNewTab
        cardButtonLinkNofollow
      }
      ... on categoryTemplate_accordion_BlockType {
        typeHandle
        accordionDisplayType
        accordionItems {
          ... on accordionItems_BlockType {
            itemTitle
            itemDescription
            showDescription
            accordionSectionTitle {
              ... on accordionSectionTitle_accordionSectionTitle_BlockType {
                sectionTitle
              }
            }
            accordionImage {
              url
              ... on aceImagesS3_Asset {
                altText
              }
            }
          }
        }
        accordionTitle
        accordionLeftContent
        buttonLabel
        buttonLink
        buttonLinkNewTab
        buttonLinkNofollow
        buttonType
      }
      ... on categoryTemplate_anchor_BlockType {
        typeHandle
        anchorName
      }

      ... on categoryTemplate_anchorButton_BlockType {
        typeHandle
        backgroundColor
        buttonAlignment
        buttonLabel
        scrollTo
        buttonStyle
        buttonArrowDirection
      }
      ... on categoryTemplate_breadcrumbs_BlockType {
        typeHandle
        backgroundColor
        textColor
        hideOnMobile
        breadcrumbsList {
          ... on breadcrumbsList_BlockType {
            breadcrumbLink
            breadcrumbText
          }
        }
      }
      ... on categoryTemplate_sliderCard_BlockType {
        typeHandle
        backgroundColor
        textColor
        sliderTitle
        sliderDescription
        displayAllCardMobile
        slidesCards {
          ... on slidesCards_BlockType {
            slideImage {
              url
              ... on aceImagesS3_Asset {
                altText
                height
                width
              }
            }
            slideContent
            slideButtonStyle
            slideButtonLabel
            slideButtonLink
            slideButtonNewTab
            slideButtonNofollow
          }
        }
      }
      ... on categoryTemplate_imageColumn_BlockType {
        typeHandle
        backgroundColor
        textColor
        image {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        imageAlignment
        contentCardTitle
        layoutContent
      }
      ... on categoryTemplate_standAloneCta_BlockType {
        typeHandle
        backgroundColor
        ctaLabel
        ctaLink
        ctaNewTab
        ctaNofollow
        ctaType
        displayPrice
        pricingText
        textColor
      }
      ... on categoryTemplate_tabComponent_BlockType {
        backgroundColor
        tabbedContentTitle
        typeHandle
        tab {
          ... on tab_BlockType {
            isActive
            tabContentList {
              ... on tabContentList_accordionItem_BlockType {
                itemDescription
                itemTitle
                showDescription
                accordionSectionTitle {
                  ... on accordionSectionTitle_BlockType {
                    sectionTitle
                  }
                }
              }
            }
            tabTitle
          }
        }
      }
      ... on categoryTemplate_resources_BlockType {
        typeHandle
        resourcesBackgroundColor
        resourcesBackgroundImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        resourcesTextColor
        resourcesTitle
        resourceCards {
          ... on resourceCards_BlockType {
            resourceImage {
              url
              ... on aceImagesS3_Asset {
                altText
              }
            }
            resourceBackgroundColor
            resourceTextContent
            resourceCtaStyle
            resourceCtaLabel
            resourceCtaLink
            resourceCtaLinkNewTab
            resourceCtaLinkNofollow
          }
        }
      }
      ... on categoryTemplate_emailForm_BlockType {
        backgroundColor
        backgroundImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        ctaLabel
        ctaType
        formContent
        formType
        hideContentOnMobile
        formVertical
        showNameField
        showParentEmailField
        showPhoneNumberField
        hideEmailField
        emailFormImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        imageAlignment
        subscriptionListIds
        textColor
        typeHandle
      }
    }
  `,
};
