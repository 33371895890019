/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const SliderFragment = {
  Slider: gql`
    fragment SliderQuery on baseComponents_slider_BlockType {
      asideContent
      lineBreak_content
      lineBreak_styles
      sideContentStateSeal {
        url
        ... on images_Asset {
          altText
        }
      }
      sliderAlignment
      sliderSlides {
        ... on sliderSlides_BlockType {
          slideContent
          slideImage {
            url
            ... on images_Asset {
              altText
              width
              height
            }
          }
          slideImageLink
          slideLinkNewTab
          slideLinkNofollow
        }
      }
      sliderTitle
      styles
      typeHandle
    }
  `,
};
