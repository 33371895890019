import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { QuizWidget } from './quiz-widget';

import type { SafeResourceUrl } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';
import { ScriptLoaderService } from '@core-mkt/services/script-loader/script-loader.service';
import type { ComponentTheme, ThemeInterface } from '@core-mkt/services/theme-parser/theme-parser.service';
import { ThemeParserService } from '@core-mkt/services/theme-parser/theme-parser.service';

@Component({
  selector: 'quiz-widget',
  templateUrl: './quiz-widget.component.html',
})
export class QuizWidgetComponent implements OnInit {
  @Input() data: QuizWidget;
  theme: ThemeInterface;
  agolixBaseUrl = 'https://app.agolix.com/assessment/';
  agolixIframeUrl: SafeResourceUrl;
  vendor: Vendor;
  customHeight: number;
  public constructor(
    private sls: ScriptLoaderService,
    private tps: ThemeParserService,
    private sanitizer: DomSanitizer,
  ) {}

  get vendorAgolix(): boolean {
    return this.vendor === Vendor.AGOLIX;
  }

  get vendorCommonNinja(): boolean {
    return this.vendor === Vendor.COMMONNINJA;
  }

  get widgetID(): string {
    return this.data.externalWidgetId;
  }

  get componentHeight(): string {
    return `height: ${this.customHeight || 1000}px`;
  }

  ngOnInit(): void {
    const themeInput: ComponentTheme = {
      styles: this.data.styles,
      backgroundColor: this.data.backgroundColor,
      backgroundImage: null,
      textColor: '',
      columnCount: 0,
      columnDistribution: '',
    };
    this.theme = this.tps.getThemeClasses(themeInput);
    this.vendor = (this.data.vendor as Vendor) || (this.data.vendorWidget as Vendor);
    this.customHeight = this.data.componentHeight || this.data.componentWidgetHeight;
    if (this.vendor === Vendor.COMMONNINJA) {
      this.sls.injectScriptByType('quizWidget', this.widgetID);
    }
    if (this.vendor === Vendor.AGOLIX) {
      this.agolixIframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.agolixBaseUrl}${this.widgetID}`);
    }
  }
}

enum Vendor {
  AGOLIX = 'agolix',
  COMMONNINJA = 'commonninja',
}
