/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const TabFragment = {
  TabComponent: gql`
    fragment TabQuery on baseComponents_tabComponent_BlockType {
      tabbedContentTitle
      tab {
        ... on tab_BlockType {
          isActive
          tabTitle
        }
      }
      tabAccordion1 {
        ... on tabAccordion1_BlockType {
          itemDescription
          accordionSectionTitle
          itemTitle
          showDescription
        }
      }
      tabAccordion2 {
        ... on tabAccordion2_BlockType {
          itemDescription
          accordionSectionTitle
          itemTitle
          showDescription
        }
      }
      tabAccordion3 {
        ... on tabAccordion3_BlockType {
          itemDescription
          accordionSectionTitle
          itemTitle
          showDescription
        }
      }
      tabAccordion4 {
        ... on tabAccordion4_BlockType {
          itemDescription
          accordionSectionTitle
          itemTitle
          showDescription
        }
      }
      styles
      typeHandle
    }
  `,
};
