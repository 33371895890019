<div [className]="background + ' bg-no-repeat bg-center bg-cover'">
  <div class="container">
    <div
      class="w-full rounded rounded-full h-5 bg-ace-grey-400 text-vis-regular relative"
    >
      <div
        class="rounded rounded-full h-5 w-1/2 text-center align-middle bg-ace-pink-600"
        [style]="getWidthPercentage"
      ></div>
      <p class="text-xs absolute top-0 left-1/2 transform -translate-x-1/2">{{ qMarker }}</p>
    </div>
    <div class="w-full rounded rounded-xs mt-v-spacing">
      <div *ngIf="q" class="w-full bg-white text-vis-regular p-v-spacing">
        <div *ngIf="q && !q.isAnswer">
          <p class="text-ace-teal-400 font-medium text-base" id="question__number">Question {{ q.id }}:</p>
          <h3 class="text-vis-regular my-v-spacing font-bold text-lg" id="question__title">{{ q.question }}</h3>
          <ul>
            <ng-template ngFor let-a [ngForOf]="q.answers">
              <li class="p-1 w-full text-base font-thin hover:bg-ace-grey-400">
                <label class="w-full flex cursor-pointer">
                  <input
                    type="radio"
                    name="answers"
                    value="{{ a.category }}"
                    class="h-4 w-4 mr-v-spacing mt-1"
                    id="{{ a.id }}"
                    (change)="selectAnswerChange(a.category)"
                  />
                  <p class="w-full text-base">{{ a.text }}</p>
                </label>
              </li>
            </ng-template>
          </ul>
          <button
            *ngIf="selectedAnswer !== ''"
            class="mt-v-spacing h-8 p-2 cursor-pointer inline-flex items-center justify-center rounded-full text-vis-reverse bg-ace-pink-600 hover:bg-ace-pink-400"
            (click)="nextQuestion()"
          >
          {{ this.questions.length === this.currentQuestion ? 'See Results' : 'Next Question' }}
          </button>
        </div>
      </div>
      <div *ngIf="!q">
        <div class="w-full p-v-spacing bg-white">
          <h2 class="text-ace-teal-400 font-medium">View Your Results</h2>
          <h3 class="text-vis-regular text-md">Each of the colors represents a general behavior style as described here. Scroll down to learn more about your results!</h3>

          <div class="text-center">
            <div class="flex mb-v-spacing">
              <span class="percentage text-vis-reverse h-4 mr-v-spacing rounded-full bg-[#4cceac] green">{{categoryTotals['green'] ? ((categoryTotals['green'] / questions.length) | percent) : '0%'}}</span>
              <div class="text-align-left">
                <p><b>Correct &amp; Complete:</b> A precise and analytical approach; accuracy above all</p>
              </div>
            </div>
            <div class="flex mb-v-spacing">
              <span class="percentage text-vis-reverse h-4 mr-v-spacing rounded-full bg-[#eb505e] red">{{categoryTotals['red'] ? ((categoryTotals['red'] / questions.length) | percent) : '0%'}}</span>
              <div class="text-align-left">
                <p><b>Drive &amp; Direct:</b> A competitive and confident manner; comfortable in leadership</p>
              </div>
            </div>
            <div class="flex mb-v-spacing">
              <span class="percentage text-vis-reverse h-4 mr-v-spacing rounded-full bg-[#7543e4] purple">{{categoryTotals['purple'] ? ((categoryTotals['purple'] / questions.length) | percent) : '0%'}}</span>
              <div class="text-align-left">
                <p><b>Steady &amp; Support:</b> A stable and helping temperament; a true team player</p>
              </div>
            </div>
            <div class="flex mb-v-spacing">
              <span class="percentage text-vis-reverse h-4 mr-v-spacing rounded-full bg-[#ffaf18] yellow">{{categoryTotals['yellow'] ? ((categoryTotals['yellow'] / questions.length) | percent) : '0%'}}</span>
              <div class="text-align-left">
                <p><b>Effect &amp; Engage:</b> An enthusiastic and social inclination; loves collaboration</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
 