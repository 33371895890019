import type { OnChanges, OnInit } from '@angular/core';
import { Directive, HostBinding, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import type { PageDataResponse } from '@core-mkt/resolvers/page-data/page-data-response';
import type { Banner } from '@core-mkt/shared/components/banner/banner/banner';
import { mockRoute } from '@core-mkt/shared/components/banner/banner/banner-mock-data';
import { cloneDeep } from 'lodash-es';
import { BrandConfigurationService } from '../configuration/brand-configuration.service';
import { DateProxy } from '../countdown/date-proxy';
import { getParsedLinkString, getQueryParamString } from './query-helpers';

@Directive({
  selector: 'a[href]',
})
export class QueryParamDirective implements OnInit, OnChanges {
  @HostBinding('attr.href') hrefAttr = '';
  @Input() href: string;
  queryParams;
  resolverData: PageDataResponse;
  pageData: Banner = null;
  isOfferTime: boolean = null;

  constructor(private route: ActivatedRoute, private brand: BrandConfigurationService) {
    if (!this.route.snapshot.data) {
      this.resolverData = mockRoute.snapshot.data.pageData;
    } else {
      if (this.route.snapshot.data.pageData) {
        this.resolverData = cloneDeep(this.route.snapshot.data.pageData);
        this.pageData = this.resolverData.craftData.header?.aceHeaderPromoBanner
          ? this.resolverData.craftData.header.aceHeaderPromoBanner[0]
          : this.resolverData.craftData.header?.realEstateHeaderPromoBanner[0];
      }
    }
    this.isOfferTime = this.isOfferTimeRange();
  }

  ngOnInit(): void {
    this.queryParams = this.route.snapshot.queryParams;
    this.setHref();
  }

  ngOnChanges() {
    this.setHref();
  }

  setHref(): void {
    const hostName = this.brand.hostName;
    const siblingSites = this.brand.siblingSites;
    const queryString = getQueryParamString(this.queryParams);
    const parsedLink = getParsedLinkString(this.href, queryString, hostName, siblingSites);
    this.hrefAttr = this.addOverWriteCoupon(parsedLink);
  }

  addOverWriteCoupon(parsedLink: string): string {
    // Checking if `this.pageData` is defined first to ask for `overwriteCoupon`
    if (!this.isOfferTime || (this.pageData && !this.pageData.overwriteCoupon)) {
      return parsedLink;
    }
    if ((parsedLink.includes('checkout') || parsedLink.includes('productId')) && !parsedLink.includes('undefined')) {
      const url = new URL(parsedLink);
      const params = new URLSearchParams(url.search);
      if (this.pageData && this.pageData.overwriteCoupon) {
        params.set('couponCode', this.pageData.overwriteCoupon);
      }
      url.search = params.toString();
      return url.toString();
    }

    return parsedLink;
  }

  private isOfferTimeRange = (): boolean => {
    let endTime = null;
    let startTime = null;
    const proxy = new DateProxy();

    if (this.pageData?.promotionEndsDateTime && this.pageData?.promotionStartDateTime) {
      startTime = new Date(this.pageData?.promotionStartDateTime).valueOf();
      const resetEndDate = this.pageData?.promotionReset[0]?.promotionEndsDateTime;
      endTime = resetEndDate
        ? new Date(resetEndDate).valueOf()
        : new Date(this.pageData?.promotionEndsDateTime).valueOf();
    } else {
      if (!this.pageData?.promotionEndsDateTime && !this.pageData?.promotionStartDateTime) {
        startTime = proxy.date.setHours(0, 0, 0, 0);
        endTime = proxy.date.setHours(24, 0, 0, 0);
      } else {
        return false;
      }
    }

    return startTime <= proxy.now && proxy.now <= endTime;
  };
}
