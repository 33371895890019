<div class="bg-vis-dark text-vis-reverse" id="rsaBundle">
  <div class="container">
    <div class="flex flex-col lg:flex-row items-center">
      <div class="rsa-content w-full lg:w-1/2 py-v-spacing xs:p-v-spacing">
        <div *ngIf="title" id="rsa-title" class="wysiwyg pb-4 mx-auto" [innerHTML]="title"></div>
        <div *ngIf="description" id="rsa-description" class="wysiwyg pb-4  mx-auto" [innerHTML]="description"></div>
        <ul id="rsa-bullets" class="grid grid-cols-2 pb-8 mx-auto flex flex-wrap w-full justify-start">
          <li *ngFor="let bullet of bullets; let last = last"
            class="lg:px-2 sm:text-baselg w-auto {{ !last ? 'pb-3' : '' }}">
            <fa-icon [icon]="faCheck" class="text-ace-teal-700 mr-4 fa-1x"> </fa-icon>
            {{ bullet.bulletItem }}
          </li>
        </ul>
        <vision-button class="w-fit" defaultStyle="primary" *ngIf="hasCta" [data]="ctaButtonData"></vision-button>
      </div>
      <div class="rsa-image w-full lg:w-1/2 py-v-spacing xs:p-v-spacing">
        <img 
          class="mx-auto" 
          src="{{ imgixService.buildImgixUrl(image.url) }}"
          [alt]="image.altText ? image.altText : ''" 
        />
      </div>
    </div>
  </div>
</div>