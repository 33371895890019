<span class="{{ data.ctaType }}-button video-button {{ icon }}">
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="play-icon">
    <path 
    class="video-icon-fill"
    d="M10 0.399902C4.70082 0.399902 0.400024 4.7007 0.400024 9.9999C0.400024 15.2991 4.70082 19.5999 10 19.5999C15.2992 19.5999 19.6 15.2991 19.6 9.9999C19.6 4.7007 15.2992 0.399902 10 0.399902ZM7.60002 12.5631V7.4367C7.60002 6.6783 8.44482 6.2175 9.07842 6.6303L13.0624 9.1935C13.648 9.5679 13.648 10.4319 13.0624 10.8063L9.07842 13.3695C8.44482 13.7823 7.60002 13.3215 7.60002 12.5631Z" 
    />
  </svg>
  <span>{{ text }}</span>
</span>

<!-- Spinner loading icon -->
<div class="spinner-body flex justify-center items-center h-screen fixed top-0 left-0 right-0 bottom-0 w-full z-50 hidden overflow-hidden" id="video-cta-spinner">
  <div class="spinner-border animate-spin inline-block w-10 h-10 rounded-full" role="status">
    <span class="visually-hidden">
        <svg
          className="animate-spin -inline-block w-8 h-8 border-4 rounded-full"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            cx="12"
            cy="12"
            r="10"
            stroke="white"
            strokeWidth="4"
          ></circle>
          <path
            fill="white"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
    </span>
  </div>
</div>