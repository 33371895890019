import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';

@Component({
  selector: 'skip-nav',
  templateUrl: './skip-nav.component.html',
})
export class SkipNavComponent {
  @Input() buttonBg: string = null;
  isVisible = false;
  isUsed = false;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  /**
   * Handles the focus event for the skip navigation component.
   * If the component has not been used yet, it sets the visibility to true.
   */
  handleFocus() {
    if (!this.isUsed) {
      this.isVisible = true;
    }
  }

  /**
   * Handles the blur event by setting the visibility state to false.
   * This method is typically used to hide the skip navigation link
   * when it loses focus.
   */
  handleBlur() {
    this.isVisible = false;
  }

  /**
   * Handles the skip to content functionality by preventing the default event,
   * finding the header element, and focusing on the next sibling element after the header.
   *
   * @param event - The event triggered by the skip to content action.
   *
   * The function performs the following steps:
   * 1. Prevents the default event behavior.
   * 2. Finds the header element by querying for 'ace-header' or 'aarp-header'.
   * 3. Gets the next sibling element after the header.
   * 4. If the next sibling element is an HTMLElement, it sets the `isUsed` state to true and `isVisible` state to false.
   * 5. Sets the `tabindex` attribute to '-1' if not already set.
   * 6. Focuses on the next sibling element and scrolls it into view smoothly.
   * 7. Resets the `isUsed` state after a short delay of 100 milliseconds.
   */
  skipToContent(event: Event) {
    event.preventDefault();
    let header: Element;

    // Find the header element
    if (this.document.querySelector('ace-header')) {
      header = this.document.querySelector('ace-header');
    } else if (this.document.querySelector('aarp-header')) {
      header = this.document.querySelector('aarp-header');
    }

    // Get the next sibling element after the header
    const nextElement = header?.nextElementSibling;

    if (nextElement instanceof HTMLElement) {
      this.isUsed = true;
      this.isVisible = false;

      // Set tabindex if not already set
      if (!nextElement.hasAttribute('tabindex')) {
        nextElement.setAttribute('tabindex', '-1');
      }

      nextElement.focus();
      nextElement.scrollIntoView({ behavior: 'smooth' });

      // Reset the isUsed state after a short delay
      setTimeout(() => {
        this.isUsed = false;
      }, 100);
    }
  }
}
