/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const EmailFormFragment = {
  EmailForm: gql`
    fragment EmailFormQuery on baseComponents_emailForm_BlockType {
      backgroundImage {
        url
        ... on images_Asset {
          altText
        }
      }
      formVertical
      ctaLabel
      ctaType
      formContent
      formType
      lineBreak_mainContent
      lineBreak_styles
      showNameField
      showParentEmailField
      showPhoneNumberField
      hideEmailField
      emailFormImage {
        url
        ... on images_Asset {
          alt
        }
      }
      imageAlignment
      styles
      subscriptionListIds
      typeHandle
    }
  `,
};
