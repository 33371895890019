<div class="lg2:hidden block">
  <div class="bg-vis-dark text-black z-30 py-0 w-full top-mobil-nav flex flex-row" [ngClass]="!hidden ? 'hidden' : 'block'">
      <div class="w-3/5 flex my-auto">
        <a class="inline-block" href="/" title="Drivers Ed Home">
          <img
            src="https://dec-mkt.imgix.net/dec-white-logo.svg?v=1736201652?auto=compress,format"
            alt="DriversEd.com"
            height="27px"
            width="290px"
          />
        </a>
      </div>
      <div class="w-1/5"></div>
      <div class="w-1/5 flex justify-end my-auto">
        <div [class.open]="!hidden" class="fixed-sidebar-toggle" (click)="mobileMenuToggle()" (keydown.enter)="mobileMenuToggle()" tabindex="0">
          <a class="navicon-button cursor-pointer inline-block relative">
            <img 
              [attr.loading]="'eager'" 
              width="32px" 
              height="32px" 
              class="w-8 h-8" 
              src="https://dec-mkt.imgix.net/dec-hamburguer-white.svg?v=1736201651?auto=compress,format"
              alt="Open navigation menu"
            />
          </a>
        </div>
      </div>
  </div>

  <div class="overlay z-30" [ngClass]="hidden ? 'hidden' : 'block'" (click)="mobileMenuToggle()"></div>

  <div
    class="z-40 bg-white text-black fixed h-full w-full overflow-auto"
    [class.is-active]="!hidden"
    [ngClass]="hidden ? 'hidden' : 'block'"
    (keydown.esc)="mobileMenuToggle()"  
  >
    <div class="text-left bg-white text-black">
      <div class="text-left">
        <div class="flex flex-row w-full top-mobil-nav">
          <span class="w-3/5 my-auto">
            <a class="inline-block" href="/" title="Drivers Ed Home">
              <img
                src="https://dec-mkt.imgix.net/images/logo.png?auto=compress,format"
                alt="DriversEd.com"
                height="27px"
                width="290px"
              />
            </a>
          </span>
          <div class="w-1/5"></div>
          <span class="w-1/5 flex justify-end my-auto">        
            <div [class.open]="!hidden" class="fixed-sidebar-toggle" (click)="mobileMenuToggle()" (keydown.enter)="mobileMenuToggle()" (keydown.esc)="mobileMenuToggle()" tabindex="0">
              <a class="navicon-button cursor-pointer inline-block relative">
                <img 
                  [attr.loading]="'eager'" 
                  width="32px" 
                  height="32px" 
                  class="w-8 h-8" 
                  src="https://xgrit-ecom.imgix.net/ace/X.svg?v=1712674314"
                  alt="Close navigation menu"
                />
              </a>
            </div>
          </span>
        </div>
        <ul class="bg-white text-black">
          <li *ngIf="displayMenu" class="text-black bg-vis-grey-1">
            <span
              class="font-bold cursor-pointer flex flex-row px-6 w-full py-6  {{ hidden ? '':'border-b'}}"
              (click)="mobileStatesToggle()"
              (keydown.enter)="mobileStatesToggle()"
              tabindex="0"
            >
              <i class="text-vis-brand-2 w-2/3 not-italic">{{ stateChange(state) }}</i>
              <span class="w-1/3 flex justify-end">
                <img src="https://dec-mkt.imgix.net/mobil.down-arrow.svg?v=1736202940?auto=compress,format" 
                  class=""
                  tabindex="-1" 
                  aria-label="" 
                  alt=""
                />
              </span>
            </span>
          </li>
          <li
            *ngIf="stateSelectionList && displayMenu"
            style="max-height: 260px"
            class="overflow-auto bg-vis-grey-1 px-12"
            [class.hidden]="!statesHidden"
          >
            <ul>
              <li *ngFor="let item of stateSelectionList">
                <a href="{{ item.href }}" class="cursor-pointer py-1 block text-black"> {{ item.text }}</a>
              </li>
            </ul>
          </li>
          <ng-template ngFor let-group [ngForOf]="menuGroupList">
            <li *ngIf="group[0] && displayMenu">
              <a
                [ngClass]="{ underline: group[0].isCurrentItem }"
                class="cursor-pointer font-semibold  block top-border text-black px-6 {{group.length > 1 ? 'pt-3.5 pb-1.5' : 'py-6'}}"
                href="{{ group[0].href }}"
                >{{ group[0].text }}</a
              >
              <ul *ngIf="group.length > 1" class="flex-col">
                <ng-template ngFor let-subItem let-i="index" [ngForOf]="group.slice(1)">
                  <li>
                    <a
                      [ngClass]="{ underline: subItem.isCurrentItem }"
                      class="bg-dec-blue-800 block text-black py-2.5 px-12"
                      href="{{ subItem.href }}"
                      >{{ subItem.text }}</a
                    >
                  </li>
                </ng-template>
              </ul>
            </li>
          </ng-template>
          <li>
            <a
              href="https://driversed.com/support/"
              class="cursor-pointer font-semibold py-3 px-6 block top-border bottom-border text-black"
            >
              Help Center
            </a>
          </li>
          <li>
            <div class="cursor-pointer p-6">
              <a
                href="https://app.driversed.com/user/signin/"
                class="primary-button v-button"
              >
                Log In
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
