<div class="fixed inset-0 bg-vis-dark/50 z-50 flex justify-center items-center" #privacyManagerOverlay>
    <div class="w-full lg:w-1/2 bg-white text-vis-regular shadow-md py-v-spacing px-v-spacing relative rounded" #privacyManager>
      <div (click)="close()" class="float-right mt-3 sm:mt-0">
        <fa-icon class="cursor-pointer text-ace-gray-500" [icon]="faTimes"></fa-icon>
      </div>
        <div class="py-v-spacing px-v-spacing">
      <div class="font-bold">
        <h2 class="mr-1 mb-2 text-xl sm:text-3xl">{{title}}</h2>
      </div>
    <div>
      <p>
        {{privacyContent}}
      </p>
      <p class="mt-3">
        {{privacyContentDirections}}
      </p>
        <div class="my-4 flex items-center lg:items-end justify-between">
          <p class="font-bold">We may sell or share your personal information on this website:</p>
          <div>
            <label class="toggle mr-2">
              <input class="toggle-input" type="checkbox" [checked]="doNotSell === 0" (change)="updateLightSwitch($event.target.checked)"/>
              <span class="toggle-label" data-off="OFF" data-on="ON"></span>
              <span class="toggle-handle" [ngClass]="{'switch-on': doNotSell}"></span>
          </label>
          </div>
        </div>
        <p class="mt-4">
          *Please note: This setting will be saved for this browser. If you use a different browser or clear your cookies, you will need to set your preference again.
        </p>
        <div class="w-full lg:w-[163px] xs:mr-v-spacing lg:mr-0 xl:mr-v-spacing mt-v-spacing">
          <button class="primary-button" (click)="savePrivacySetting()">Save & Exit</button>
        </div>
      </div>
    </div>
</div>
  </div>
  