/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const CareerCenterFragment = {
  CareerCenter: gql`
    fragment CareerCenterQuery on baseComponents_careerCenter_BlockType {
      careerCenterSideContent
      careerCenterTitle
      filterDefaultLabel
      filterTitle
      styles
      typeHandle
      warning
      careerCenterArticleCards {
        ... on careerCenterArticleCards_BlockType {
          image {
            url
            ... on images_Asset {
              altText
            }
          }
          relatedResourceContent
          relatedResourceLink
          careerCenterArticleCards {
            ... on careerCenterCategories_Category {
              aceCategoryCode
              title
            }
          }
        }
      }
    }
  `,
};
