<div [ngClass]="getClassObject" class="flex p-4 featured-content ">
    <img
          *ngIf="data.featuredContentIcon.length > 0 && data.featuredContentIcon[0].url !== null"
          [src]="data.featuredContentIcon[0].url"
          alt="Featured Content Icon"
          class="mr-4 object-contain fc-img"
          width="48"
          height="48"
        />
<p class="flex items-center fc-text" [innerHTML]="data.featuredContentText"></p>
</div>
