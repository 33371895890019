<a
  class="{{styleClass}}-button v-button {{ !isFullWidth ? 'w-min whitespace-nowrap' : '' }}"
  [ngClass]="className"
  href="{{buttonLink}}"
  [attr.data-formelementid]="dataFormElementId ? dataFormElementId : null"
  [attr.target]="target"
  [rel]="rel"
  [attr.aria-labelledby]="getIdToAriaLabelledbyContext(text, buttonLink)"
>
  <span class="md:whitespace-nowrap"
  [ngClass]="buttonSizeClass"
  [class.tertiary-no-underline]="useIcon && buttonStyle ===  'tertiary'">
    {{text}}
    <fa-icon
      *ngIf="linkIcon && useIcon && buttonStyle==='tertiary'"
      class="arrow-right fa-1x pl-1"
      [icon]="linkIcon"
    >
    </fa-icon>
  </span>
  <!-- Hidden Context -->
  <span [attr.id]="getIdToAriaLabelledbyContext(text, buttonLink)" class="sr-only">{{getTextContext(buttonLink)}}</span>
</a>
