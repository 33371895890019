<div class="bg-ace-gray-100" *ngIf="showHideFooter">
  <div class="mx-auto px-2 md:py-12 py-16 text-center max-w-6xl">
    <div class="py-12">
      <ul class="flex justify-center">
        <li class="md:px-4 px-2">
          <a href="https://www.facebook.com/DriversEd" target="_blank" rel="noopener noreferrer"
            ><img
              img-lazy
              src="https://dec-mkt.imgix.net/images/fb-logo-grey.png?auto=compress,format"
              width="40"
              height="40"
          /></a>
        </li>
        <li class="md:px-4 px-2">
          <a href="https://x.com/driversedcom" target="_blank" rel="noopener noreferrer"
            ><img
              img-lazy
              src="https://dec-mkt.imgix.net/images/x-logo-grey.svg?auto=compress,format"
              width="40"
              height="40"
          /></a>
        </li>
        <li class="md:px-4 px-2">
          <a href="https://www.instagram.com/driversed/" target="_blank" rel="noopener noreferrer"
            ><img
              img-lazy
              src="https://dec-mkt.imgix.net/images/ig-logo-grey.png?auto=compress,format"
              width="40"
              height="40"
          /></a>
        </li>
      </ul>
    </div>
    <div class="pt-4 flex flex-col" data-test="footerMenu">
      <hr class="my-2" />
      <div *ngFor="let footerLinks of linkSections; let i = index">
        <dec-link-section [data]="footerLinks"></dec-link-section>
      </div>
    </div>
    <div class="pt-12 pb-2" data-test="trustSeals">
      <ul class="flex justify-center list-container">
        <li class="md:px-4 px-1">
          <a
            href="https://www.mcafeesecure.com/verify?host=www.driversed.com"
            target="_blank"
            rel="noreferrer noopener nofollow"
          >
            <img
              img-lazy
              class="footer__image"
              height="40"
              width="83"
              src="https://dec-mkt.imgix.net/images/mcafee-logo-grey.png?auto=compress,format"
            />
          </a>
        </li>
        <li class="md:px-4 px-1">
          <a
            href="https://www.bbb.org/us/ca/sunnyvale/profile/general-interest-schools/driversedcom-1216-368907"
            target="_blank"
            rel="noreferrer noopener nofollow"
          >
            <img
              img-lazy
              class="footer__image"
              src="https://dec-mkt.imgix.net/images/bbb-logo-grey.png?auto=compress,format"
              height="40"
              width="83"
            />
          </a>
        </li>
      </ul>
    </div>
    <p class="pt-16 pb-14 pb-md-4 text-ace-gray-300">
      © 1997-{{ copyrightYear }} DriversEd.com. All rights reserved. Please see our
      <a
        href="https://driversed.com/about-us/privacy-policy/"
        class="underline"
        target="_blank"
        rel="noreferrer noopener"
        >privacy policy</a
      >
      for more details.
    </p>
  </div>
</div>
