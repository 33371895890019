<button
  *ngIf="link.isDropDown"
  [class]="
    'w-full text-base leading-[27px] font-body collapse-link flex align-middle p-v-spacing text-ace-grey-800 justify-between ' +
    (collapsed ? 'border-b border-ace-grey-400 font-bold' : 'font-extrabold')
  "
  (click)="collapsed = !collapsed"
>
  {{ link.linkLabel }}
  <fa-icon aria-label="DropDown arrow" aria-hidden="true" class="ml-1 text-base" [icon]="collapsed ? faCaretDownLegacy : faCaretUpLegacy"></fa-icon>
</button>
<a
  *ngIf="!link.isDropDown"
  class="text-base leading-[27px] font-body collapse-link flex align-middle p-v-spacing text-dark border-b border-ace-grey-400 font-bold"
  href="{{ link.linkUrl }}"
  target="{{ link.openNewTab ? '_blank' : '_self' }}"
>
  {{ link.linkLabel }}
</a>
<div class="pl-10 collapse-link__item border-b border-ace-grey-400 pb-v-spacing" *ngIf="!collapsed">
  <a
    *ngFor="let linkItem of link.items"
    [class]="'block py-2.5 ' + (linkItem.arrowLink ? 'text-ace-teal-700 flex items-center gap-1.5 self-stretch px-0 py-3' : 'text-dark')"
    [href]="linkItem.linkUrl"
  >
    <span [innerHTML]="linkItem.linkLabel"></span>
    <span [class]="(!linkItem.arrowLink && 'hidden')" aria-label="arrow all state" aria-hidden="true">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.45007 12.9333C7.29451 12.7889 7.21673 12.6083 7.21673 12.3916C7.21673 12.175 7.29451 11.9889 7.45007 11.8333L10.5001 8.78332H3.26673C3.04451 8.78332 2.8584 8.70832 2.7084 8.55832C2.5584 8.40832 2.4834 8.22221 2.4834 7.99998C2.4834 7.77776 2.5584 7.59165 2.7084 7.44165C2.8584 7.29165 3.04451 7.21665 3.26673 7.21665H10.5001L7.45007 4.14998C7.29451 4.00554 7.21673 3.82221 7.21673 3.59998C7.21673 3.37776 7.29451 3.18887 7.45007 3.03332C7.60562 2.88887 7.79173 2.81665 8.0084 2.81665C8.22507 2.81665 8.41118 2.88887 8.56673 3.03332L12.9834 7.44998C13.0612 7.52776 13.1195 7.61387 13.1584 7.70832C13.1973 7.80276 13.2167 7.89998 13.2167 7.99998C13.2167 8.09998 13.1973 8.19721 13.1584 8.29165C13.1195 8.38609 13.0612 8.47221 12.9834 8.54998L8.56673 12.9666C8.41118 13.1222 8.22507 13.1944 8.0084 13.1833C7.79173 13.1722 7.60562 13.0889 7.45007 12.9333Z" fill="#169AA9"/>
      </svg>        
    </span>
  </a>
</div>
