/* eslint-disable prettier/prettier */
import { ProductFragment } from '@core-mkt/shared/craft/product-fragment';
import * as gql from 'fake-tag';
import { HeaderFragment, SettingsFragment } from './fragments/header-fragment-query';
import { SEOProductFragment } from './fragments/seo-fragment-query';

export const TemplateProductQuery = {
  name: 'TemplateProductQuery',
  query: gql`
    query ($site: [String], $slug: [String]) {
      entryData: entries(site: $site, slug: $slug) {
        slug
        sectionHandle
        typeHandle
        ... on aceRealEstateTemplates_productTemplate_Entry {
          hideStickyBanner
          productTemplate {
            ...productTemplate_fragment
          }
        }
      }

      product: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_productTemplate_Entry {
          aceRealEstateProduct {
            ... on aceRealEstateProducts_xgritProduct_Entry {
              aceRealEstateProductCategory {
                ... on realEstateCategories_Category {
                  aceCategoryCode
                  title
                }
              }
              productPageUrl
              productState
              xgritBundleCoupon
              xgritCoupon
              xgritProductDisableRsa
              xgritProductId
            }
          }
        }
      }

      feefo: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_productTemplate_Entry {
          feefoProductId
        }
      }

      seoContent: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_productTemplate_Entry {
          ...RealEstateSEOFragmentEntry
        }
      }

      styles: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_productTemplate_Entry {
          embedStyles
        }
      }

      optimizely: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_productTemplate_Entry {
          optimizelyEnabled
        }
      }

      globalOptimizely: globalSets(handle: "globalOptimizely") {
        ... on globalOptimizely_GlobalSet {
          optimizelyAceAgentTemplates {
            ... on optimizelyAceAgentTemplates_aceAgentTemplateTypes_BlockType {
              optimizelyEnabled: productAATemplateOpti
            }
          }
        }
      }

      structuredData: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_productTemplate_Entry {
          structuredData
        }
      }

      overrides: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_productTemplate_Entry {
          aceHeaderPageSettings {
            ...SettingsFragment
          }
        }
      }

      header: globalSets(handle: "aceHeader") {
        ... on aceHeader_GlobalSet {
          ...HeaderFragmentEntry
        }
      }
    }

    fragment productEntry_fragment on EntryInterface {
      ... on aceRealEstateProducts_xgritProduct_Entry {
        productPageUrl
        productState
        xgritBundleCoupon
        xgritCoupon
        xgritProductDisableRsa
        xgritProductId
        aceRealEstateProductCategory {
          ... on realEstateCategories_Category {
            aceCategoryCode
            title
          }
        }
      }
    }
    ${HeaderFragment.header}
    ${SettingsFragment.settings}
    ${SEOProductFragment.seoContent}
    ${ProductFragment.productTemplate}
  `,
};
