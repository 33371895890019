<div
  *ngIf="theme && hasContent"
  class="font-body bg-cover home-hero overflow-hidden {{ theme?.root }}"
  [ngStyle]="heroStyle"
>
  <div class="container !py-6 lg:!py-0 {{ theme?.container }}">
    <div class="flex lg:items-center {{ theme?.columnOrder }} relative">
      <div class="home-hero__content {{ contentWidth }}">
        <div
          *ngIf="data.heroTitle && data.heroTitle.length !== 0"
          class="pt-v-spacing pb-4 sm:px-v-spacing wysiwyg mx-auto sm:mx-0 w-auto"
          [innerHTML]="data.heroTitle"
        ></div>
        <div class="pb-4 sm:px-v-spacing" *ngIf="data.showTrustpilot">
          <div class="flex flex-wrap content-start gap-[8px]">
            <img
              class="h-[28px] w-[150px]"
              height="28"
              width="150"
              [alt]="TrustScoreImageAltText"
              [src]="TrustScoreImageUrl"
            />
            <div *ngIf="trustpilotVerified" class="flex items-center text-xs xxsm:text-sm xs:text-base">
              <span class="mr-2.5"
                ><span class="font-extrabold">{{ trustpilotTrustScore }}/5</span> Stars</span
              >
              <span
                ><span class="font-extrabold">{{ trustpilotNumberOfReviews | number }}</span> Reviews</span
              >
            </div>
          </div>
        </div>
        <div
          *ngIf="data.heroContent && data.heroContent.length !== 0"
          class="pb-[32px] sm:px-v-spacing home-hero__content wysiwyg mx-auto sm:mx-0 w-auto"
          [innerHTML]="data.heroContent"
        ></div>
        <div
          [ngClass]="!productPickerContent ? 'sm:grid-cols-2 xl:grid-cols-2' : ''"
          class="pb-v-spacing sm:px-v-spacing grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2"
        >
          <!-- Product Picker Component -->
          <product-picker *ngIf="productPickerContent" [data]="productPickerContent"></product-picker>
          <!-- Buttons -->
          <ng-template *ngIf="!productPickerContent" ngFor let-b [ngForOf]="buttons">
            <div class="w-full button__home_hero z-0">
              <a
                class="flex bg-white h-[5.75rem] py-2 px-4 rounded items-center button-shadow hover:bg-pink-100 cursor-pointer"
                [attr.target]="b.buttonLinkNewTab ? '_blank' : ''"
                [attr.rel]="
                  b.buttonLinkNewTab && b.buttonLinkNofollow
                    ? 'nofollow noopener noreferrer'
                    : b.buttonLinkNewTab && !b.buttonLinkNofollow
                    ? 'noopener noreferrer'
                    : b.buttonLinkNofollow
                    ? 'nofollow'
                    : ''
                "
                href="{{ b.buttonLink }}"
                [attr.aria-labelledby]="getIdToAriaLabelledbyContext(b.buttonTitle)"
              >
                <img
                  *ngIf="b.buttonIcon && b.buttonIcon.length > 0"
                  src="{{ b.buttonIcon[0].url }}"
                  width="60"
                  height="60"
                  class="button-icon w-[60px] h-[60px] text-[.5rem]"
                  [alt]="b.buttonIcon[0].altText ? b.buttonIcon[0].altText : ''"
                />
                <div class="items-center w-full ml-3">
                  <h2 class="font-bold text-baselg m-0 text-vis-regular button__home_title leading-[1.688rem]">
                    {{ b.buttonTitle }}
                  </h2>
                  <small class="text-ace-grey-600 button__home_subtitle">{{ b.buttonSubtitle }}</small>
                </div>
                <div>
                  <fa-icon
                    class="text-ace-pink-600 fa-1x flex justify-center items-center w-8 h-8"
                    [icon]="faChevronRight"
                  >
                  </fa-icon>
                </div>
                <!-- Hidden Context -->
                <span [attr.id]="getIdToAriaLabelledbyContext(b.buttonTitle)" class="sr-only">{{requestService.getTextContext(b.buttonLink)}}</span>
              </a>
            </div>
          </ng-template>
        </div>
      </div>
      <div *ngIf="data.heroImage && data.heroImage.length > 0" class="w-1/2 home-hero__image hidden md:block">
        <div
          class="home-hero__image-wrapper relative transform -translate-x-1/2 -translate-y-[3.75rem] {{
            imageBleedClass
          }}"
          style="width: max-content"
        >
          <img
            #heroImg
            width="670"
            height="464"
            class="h-auto w-[670px] lg:w-[770px]"
            [src]="heroImageUrl"
            [alt]="data.heroImage[0].altText ? data.heroImage[0].altText : ''"
          />
        </div>
      </div>
    </div>
    <div
      *ngIf="data.heroImage && data.heroImage.length > 0 && showImageOnMobile"
      class="home-hero__image mx-auto pt-v-spacing md:hidden block"
    >
      <div class="home-hero__image-wrapper">
        <img
          #heroImg
          width="268"
          height="464"
          class="h-full sm:h-auto w-auto"
          [src]="heroImageUrl"
          [alt]="data.heroImage[0].altText ? data.heroImage[0].altText : ''"
        />
      </div>
    </div>
  </div>
</div>
