import { DOCUMENT } from '@angular/common';
import type { AfterViewInit, OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, HostListener, Inject, Input, Renderer2 } from '@angular/core';
import { GeolocationService } from '@core-mkt/services/geolocation/geolocation.service';
import type { ComponentTheme, ThemeInterface } from '@core-mkt/services/theme-parser/theme-parser.service';
import { ThemeParserService } from '@core-mkt/services/theme-parser/theme-parser.service';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import { faCaretDown, faChevronDown, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { cloneDeep } from 'lodash-es';
import type { AnswerOverride, CourseSection, FAQData } from './faq';
import { Faqs } from './faq';
import { FAQQuestionData, faqStates } from './faqs-data';

@Component({
  selector: 'aarp-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit, AfterViewInit {
  @Input() data: Faqs;
  theme: ThemeInterface;
  hasContent = true;
  faqs = FAQQuestionData;
  faPlus = faPlus;
  faMinus = faMinus;
  faCaretDown = faCaretDown;
  faChevronDown = faChevronDown;
  dropdownCollapse = false;
  selectedItem: string;
  selectedState = '';
  selectedStateAbbr: string;
  activeQuestionIndices: number[][] = [];
  statesObject = faqStates.states;
  originalFaqData: FAQData;
  filteredData: FAQData;
  // Loading state for geolocation
  public isLoadingState: boolean;

  constructor(
    private redactor: WysiwygRedactorService,
    private themeParser: ThemeParserService,
    public geolocation: GeolocationService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) public document: Document,
  ) {
    this.geolocation?.isLoading.subscribe((isLoading) => {
      this.isLoadingState = isLoading;
    });
  }

  get faqTitle(): string {
    return this.data.faqTitle;
  }

  get faqSubTitle(): string {
    return this.data.faqSubTitle;
  }

  get dropdownPlaceholder(): string {
    return this.selectedItem || this.statesObject[0].name;
  }

  ngOnInit(): void {
    this.geolocation.loadLocationCookie();
    if (!this.data) {
      this.hasContent = false;
      return;
    }

    this.originalFaqData = cloneDeep(this.faqs);
    this.filteredData = this.faqs;

    this.filteredData.sections = this.filteredData.sections.map((section) => {
      section['attributeTitle'] = section.title.replace(/\s+/g, '_').toUpperCase();
      return section;
    });

    const themeInput: ComponentTheme = {
      styles: this.data.styles,
      backgroundColor: this.data.backgroundColor,
      backgroundImage: this.data.backgroundImage[0],
      textColor: this.data.textColor,
      columnCount: 0,
      columnDistribution: '',
    };
    this.theme = this.themeParser.getThemeClasses(themeInput);

    this.geolocation.stateAbbr.subscribe((stateAbbr) => {
      this.selectedState = this.geolocation.getStateName(stateAbbr);
      this.selectedItem =
        typeof stateAbbr === 'undefined'
          ? this.statesObject[0].name
          : this.statesObject.find((item) => item.name === this.selectedState)?.name || this.dropdownPlaceholder;

      this.onChange(stateAbbr);
    });
  }

  ngAfterViewInit(): void {
    this.preventAnchorRedirects();
  }

  private preventAnchorRedirects(): void {
    const anchors = this.document.querySelectorAll('.faq-heading');
    anchors.forEach((anchor) => {
      this.renderer.listen(anchor, 'click', (event) => {
        event.preventDefault();
      });
    });
  }

  toggleAnswer(question: any): void {
    question.showAnswer = !question.showAnswer;
  }

  onChange(stateAbbr: string): void {
    this.selectedItem = this.geolocation.getStateName(stateAbbr);
    this.selectedState = this.geolocation.getStateName(stateAbbr);
    this.selectedStateAbbr = stateAbbr;
    this.dropdownCollapse = false;
    // Update the cookie value if user selects a different state
    this.geolocation.updateLocationCookie(stateAbbr);
    const overQuestions = this.data.answerOverride.filter((answer) => answer.state === stateAbbr);
    this.filteredData.sections = this.applyOverrides(this.originalFaqData.sections, overQuestions);
    this.cdr.detectChanges();
    this.preventAnchorRedirects();
  }

  toggleDropdown(event: MouseEvent): void {
    event.stopPropagation();
    this.dropdownCollapse = !this.dropdownCollapse;
  }

  @HostListener('document:click', ['$event'])
  closeDropdown(): void {
    if (this.dropdownCollapse) {
      this.dropdownCollapse = false;
    }
  }

  applyOverrides(sections: CourseSection[], overrides: AnswerOverride[]): CourseSection[] {
    const updatedSections: CourseSection[] = JSON.parse(JSON.stringify(sections)) as CourseSection[];

    for (const section of updatedSections) {
      for (const question of section.questions) {
        const override = overrides.find((o) => {
          const match =
            this.geolocation.getStateName(o.state) === this.selectedState && o.questionNumber == question.id.toString();
          return match;
        });

        if (override) {
          question.answer = override.override;
        }
      }
    }

    return updatedSections;
  }
}
