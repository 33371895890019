import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import type { VisionModal } from './vision-modal/vision-modal';

@Injectable({
  providedIn: 'root',
})
export class VisionModalService {
  private modalContentSubject = new Subject<VisionModal>();
  modalContent$ = this.modalContentSubject.asObservable();

  openModal(update: VisionModal) {
    this.modalContentSubject.next(update);
  }
}
