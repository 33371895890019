<div class="{{ theme?.root }} bg-no-repeat bg-center bg-cover" [style]="theme?.bgImage">
  <div
    class="flex flex-col m-auto px-v-spacing py-4 max-w-[540px] md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px] {{emailFormWrapperClasses}}"
    [class.lg:flex-row]="emailImageExists && fullForm"
    [ngClass]="fullForm ? 'container' : 'email-form'">
    <div
      class="flex md:items-left flex-col {{ fullForm && 'pt-0 pb-v-spacing' }}"
      [class.justify-center]="!hasContent || emailImageExists"
      [class.md:flex-row]="!fullForm"
      [ngClass]="{'lg:w-1/2': data.emailFormImage.length > 0 && fullForm}"
      >
      <div
        *ngIf="hasContent"
        class="wysiwyg w-full content-center"
        [class.sm:p-v-spacing]="fullForm"
        [class.py-v-spacing]="fullForm"
        [class.lg:mb-0]="!fullForm"
        [class.md:pl-v-spacing]="!fullForm"
        [class.md:pr-3]="!fullForm"
        [class.text-center]="data.emailFormImage && !data.emailFormImage.length > 0 && fullForm"
        [ngClass]="{ 'hidden md:block': hideContentOnMobile }"
        [innerHTML]="formContent"
      ></div>
      <div
        class="w-full p-x-spacing pv-3 content-center"
        [ngClass]="{
          'flex flex-col items-center py-v-spacing tab:px-v-spacing max-w-vision mx-auto': !hasContent,
          textHidden: hideContentOnMobile
        }"
        >
        <div *ngIf="formSubmitted" class="parent-form__content md:mr-v-spacing font-bold text-vis-subtle {{ fullForm && 'py-v-spacing sm:px-v-spacing text-center mt-3'}}">
          <p class="{{ !fullForm && 'h-[60px] flex items-center justify-start pt-3 md:pt-0 md:justify-center'}}">Thank you for subscribing!</p>
        </div>
        <form
          class="mx-auto flex justify-end items-center text-vis-regular relative {{ formClasses }}"
          [class.hidden]="formSubmitted"
          [class.lg:ml-0]="emailImageExists && fullForm"
          [class.max-w-[560px]]="!emailImageExists && fullForm"
          [formGroup]="signUpForm"
          (ngSubmit)="onSubmit()"
          novalidate
        >
        <!-- Loop through form fields -->
          <div *ngFor="let field of formFields; let i = index" class="w-full relative flex flex-col" [class.mb-3]="fullForm" [class.sm:mr-3]="!fullForm">
            <div class="relative">
            <input
              class="py-v-spacing outline-none text-sm text-vis-regular transition-all duration-200 {{ fullForm ? 'h-[52px]' : 'h-[60px]' }}"
              [class.sm:w-auto]="!fullForm || (fullForm && !data.showNameField && !data.showParentEmailField)"
              [class.sm:mr-3]="!fullForm"
              [id]="'input__' + field.name"
              [placeholder]="field.label"
              [formControlName]="field.name"
              [type]="field.type"
              [ngClass]="{
                'mb-2': hasError(field.name) && i < formFields.length - 1 && fullForm
              }"
            />

            <!-- Error icon (red circle with X) -->
            <span *ngIf="hasError(field.name)">
              <fa-icon
                class="text-lg leading-[22px] absolute text-ace-red-600 pointer-events-none form-error-icon top-1/2 transform -translate-y-1/2 right-4"
                [icon]="faTimesCircle"

              ></fa-icon>
            </span>
          </div>
            <!-- Error message -->
            <span
              *ngIf="hasError(field.name)"
              class="text-ace-red-600 text-xs form-error mt-2"
              [ngClass]="{
                'absolute top-full left-0': !fullForm,
                'block': fullForm
              }"
              [innerText]="getErrorMessage(field.name)">
            </span>
          </div>

          <!-- Submit button -->
          <span class="{{ !fullForm ? 'text-right sm:ml-3 mt-3 sm:mt-0 sm:ml-0 lg:mr-6 w-full sm:w-auto' : 'w-full' }}">
            <input
              type="submit"
              class="px-v-spacing py-[18px] {{ ctaType }}-button cursor-pointer"
              [class.w-auto]="ctaType === 'tertiary'"
              [class.sm:!w-fit]="!fullForm"
              [value]="ctaLabel"
            />
          </span>

          <!-- Try again later error message -->
          <span class="text-ace-red-600 text-xs mt-2 {{ !fullForm ? 'absolute top-full left-0' : '' }}" *ngIf="postErr">
            There was an issue with your subscription. Please try again later.
          </span>
        </form>
      </div>
    </div>
    <div
    *ngIf="emailImageExists && fullForm"
    class="w-full lg:w-1/2 sm:p-v-spacing flex items-center justify-center {{ imgAlign.includes('flex-row-reverse') ? 'lg:pl-10' : 'lg:pr-10' }}"
      >
      <!-- Image -->
      <img
        *ngIf="data.emailFormImage.length > 0 && data.emailFormImage[0].url !== null"
        [src]="data.emailFormImage[0].url"
        [alt]="getAlt()"
        class=" object-cover pt-4 lg:pt-0"
        width="534"
        height="534"
      />
    </div>
  </div>
</div>
