export interface SitesDictionary {
  domain: string;
  description: string;
}

export const brandSites: SitesDictionary[] = [
  {
    domain: 'https://www.aceable.com/',
    description: 'Aceable'
  },
  {
    domain: 'https://www.aceableagent.com/',
    description: 'AceableAgent'
  },
  {
    domain: 'https://insurance.aceable.com/',
    description: 'Insurance'
  },
  {
    domain: 'http://mortgage.aceable.com/',
    description: 'Mortgage'
  }
];
