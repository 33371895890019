import type { BrandConfig } from '../../app/interfaces/brand-config';
import { iconConfig } from './iconConfig';

export const brandConfig: BrandConfig = {
  id: 'AA',
  name: 'AceableAgent',
  appName: 'AceableAgent',
  url: 'www.aceableagent.com',
  hostName: 'aceableagent.com',
  showFee: false,
  feeLabel: '',
  helpPage: 'https://www.aceableagent.com/support/',
  siblingSites: ['aceable.com', 'insurance.aceable.com', 'mortgage.aceable.com'],
  cdiParamKey: 'aa',
  craftSite: 'ace',
  vertical: 'real-estate',
  productHandler: 'aceRealEstateProduct',
  bannerHandler: 'realEstateHeaderPromoBanner',
  cdiParamValueWhenEnabled: 'cd99',
  trustpilotId: '58dd65e50000ff00059f8a38',
  trustpilotApiKey: 'Rk3yLGM8QWIj2ecbmB4s0rnG82OsU5PS',
  iterableApiKey: 'e5d3eabc1d4e40af9b52cf5c3cb8beac',
  ambassadorGroupId: '20',
  pricingText: 'Starting at',
  checkClassName: '',
  productCategoryHandle: 'aceRealEstateProductCategory',
  iconConfig: iconConfig,
  ctaText: 'Get My License',
  rsaImageFooterText: '',
  hubspotID: '8812b0a9-131e-4f90-8139-043c3fb1d357',
};
