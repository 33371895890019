<div *ngIf="trigger" class="container fixed top-0 sm:top-1/2 z-[999] left-1/2">
  <div class="modal p-v-spacing bg-white text-vis-regular pt-11 relative">
    <fa-icon [icon]="faTimes" class="fa-1x absolute exit-icon text-ace-teal-800 cursor-pointer" (click)="toggleModal()"></fa-icon>
    <img class="pb-8 pl-0 sm:pl-v-spacing" src="https://xgrit-ecom.imgix.net/ace/Gift_illustration.svg?v=1677355458"/>
    <h2 class="mb-3 sm:px-v-spacing">{{modalData.modalTitle}}</h2>
    <p class="text-vis-subtle pb-8 sm:px-v-spacing">{{modalData.modalContent}}</p>
      <div class="modal-cta__container sm:px-v-spacing sm:pb-v-spacing w-1/2 sm:w-full">
        <span class="modal-cta font-extrabold" (click)="toggleModal()">{{modalData.modalCTA}}</span>
      </div>
  </div>
</div>
<div *ngIf="trigger" class="bg bg-opacity-50"></div>
