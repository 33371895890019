import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LazyImgModule } from '@core-mkt/services/lazy-load/lazy-load.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SwiperModule } from 'swiper/angular';
import { VisionButtonModule } from '../vision-button/vision-button.module';
import { VisionSelectModule } from '../vision-select/vision-select.module';
import { ProductFormComponent } from './study-plan-builder/product-form/product-form.component';
import { SliderComponent } from './study-plan-builder/slider/slider.component';
import { StudyPlanBuilderComponent } from './study-plan-builder/study-plan-builder.component';
import { WyswygContentComponent } from './study-plan-builder/wyswyg-content/wyswyg-content.component';

@NgModule({
  declarations: [StudyPlanBuilderComponent, SliderComponent, WyswygContentComponent, ProductFormComponent],
  exports: [SliderComponent, WyswygContentComponent, ProductFormComponent],
  imports: [CommonModule, SwiperModule, LazyImgModule, VisionSelectModule, VisionButtonModule, FontAwesomeModule],
})
export class StudyPlanBuilderModule {}
