import { DOCUMENT } from '@angular/common';
import type { AfterContentChecked } from '@angular/core';
import { ChangeDetectorRef, Component, Inject, Input } from '@angular/core';
import { VisionVideoButton } from './vision-video-button';

@Component({
  selector: 'vision-video-button',
  templateUrl: './vision-video-button.component.html',
  styleUrls: ['./vision-video-button.component.scss'],
})
export class VisionVideoButtonComponent implements AfterContentChecked {
  @Input() defaultStyle = 'secondary'; // This property can be used in case there's no value coming from craft
  @Input() defaultText = 'Watch Overview'; // Same as defaultStyle
  @Input() data: VisionVideoButton; // Description of the values on the interface file.

  readonly definedStyles = ['primary', 'secondary', 'tertiary'];
  readonly darkBackgrounds = ['bg-vis-dark', 'bg-ace-teal-600'];
  icon: string;

  constructor(@Inject(DOCUMENT) private document: Document, private changeDetector: ChangeDetectorRef) {}

  get text(): string {
    return this.data?.ctaLabel ? this.data?.ctaLabel : this.defaultText;
  }

  get buttonStyle(): string {
    // Gets the selected style for the button. or the default one.
    return this.definedStyles.includes(this.data?.ctaType) ? this.data?.ctaType : this.defaultStyle;
  }

  get wistiaID(): string {
    return this.data?.wistiaId;
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
}
