import { Component, Input } from '@angular/core';
import { faArrowLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { HeaderNavLink } from '../header/header';

@Component({
  selector: 'collapse-link',
  templateUrl: './collapse-link.component.html',
  styleUrls: ['./collapse-link.component.scss'],
})
export class CollapseLinkComponent {
  @Input() link: HeaderNavLink;
  collapsed = true;
  faCaretRight = faChevronRight;
  faArrowLeft = faArrowLeft;

  constructor() {}
}
