import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { StatesDictionary } from 'libs/common/src/lib/geolocation/aarp-us-states.dictionary';
import { usStates } from 'libs/common/src/lib/geolocation/aarp-us-states.dictionary';
import { BehaviorSubject, type Observable, map } from 'rxjs';

import { EnvService } from '@core-mkt/services/env/env.service';
import { BrandConfigurationService } from '../configuration/brand-configuration.service';
import { CookieService } from '../cookie/cookie.service';
import type { UserLocationResponse } from './../../interfaces/geolocation';

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {
  public readonly cookieName: string = 'state';
  private isLoading$ = new BehaviorSubject<boolean>(false);
  private stateAbbr$ = new BehaviorSubject<string>(null);
  private usStates: StatesDictionary[] = usStates;
  cookieDomain: string | undefined = this.isProd ? `.${this.brand.hostName}` : undefined;

  constructor(
    private http: HttpClient,
    private brand: BrandConfigurationService,
    private cookie: CookieService,
    private env: EnvService,
  ) {}

  get isProd(): boolean {
    return this.env.get.envMode === 'prod';
  }

  /**
   * Sets the loading state of the geolocation service.
   * @param state - The loading state to set.
   */
  public setIsLoadingState(state: boolean) {
    this.isLoading$.next(state);
  }

  /**
   * Gets the loading status of the geolocation service.
   * @returns An Observable that emits a boolean value indicating whether the service is currently loading.
   */
  get isLoading(): Observable<boolean> {
    return this.isLoading$.asObservable();
  }

  /**
   * Sets the state abbreviation.
   * @param state - The state abbreviation to set.
   */
  public setStateAbbr(state: string) {
    this.stateAbbr$.next(state);
  }

  /**
   * Gets the state abbreviation as an observable.
   * @returns An observable that emits the state abbreviation.
   */
  get stateAbbr(): Observable<string> {
    return this.stateAbbr$.asObservable();
  }

  /**
   * Retrieves the user's location based on their IP address.
   * @returns An Observable that emits a UserLocationResponse object.
   */
  public getUserLocation(): Observable<UserLocationResponse> {
    return this.http.get<UserLocationResponse>('https://www.aarpdriversafety.org/whereami');
  }

  /**
   * Generates a cookie with the given name and value.
   * @param name The name of the cookie.
   * @param value The value of the cookie.
   */
  public setLocationCookie(value: string): void {
    this.cookie.setCookie(this.cookieName, value, 7, '/', this.cookieDomain);
  }

  /**
   * Retrieves the value of the cookie with the given name.
   * @param name The name of the cookie to retrieve.
   * @returns The value of the cookie, or null if the cookie is not found.
   */
  public getLocationCookie(): string | null {
    const locationCookie = this.cookie.getCookie(this.cookieName);
    if (locationCookie && locationCookie !== 'undefined') {
      this.setStateAbbr(locationCookie);
      return locationCookie;
    }
  }

  /**
   * Removes the cookie with the given name.
   * @param name The name of the cookie to remove.
   */
  public removeLocationCookie(): void {
    this.cookie.deleteCookie(this.cookieName, '/', this.cookieDomain);
  }

  /**
   * Updates the value of a cookie with the given name.
   * @param value The new value for the cookie.
   */
  public updateLocationCookie(value: string): void {
    // First Remove the current cookie
    this.removeLocationCookie();
    // Then add the cookie with the new value
    this.setLocationCookie(value);
  }

  /**
   * Retrieves the name of a state based on its abbreviation.
   * @param stateAbbr - The abbreviation of the state.
   * @returns The name of the state, or undefined if no matching state is found.
   */
  public getStateName(stateAbbr: string): string {
    return this.usStates.find((usState) => usState.abbreviation === stateAbbr)?.name;
  }

  /**
   * Retrieves the abbreviation of a US state based on its name.
   * @param stateName - The name of the US state.
   * @returns The abbreviation of the US state, or undefined if not found.
   */
  public getStateAbbr(stateName: string): string {
    return this.usStates.find((usState) => usState.name === stateName)?.abbreviation;
  }

  public loadLocationCookie(): void {
    if (!this.getLocationCookie()) {
      this.getUserLocationSubscription();
    } else {
      this.setStateAbbr(this.getLocationCookie().toString());
    }
  }

  public getUserLocationSubscription(): void {
    this.setIsLoadingState(true);

    this.getUserLocation()
      .pipe(
        map((location: UserLocationResponse) => {
          return location.stateabbr;
        }),
      )
      .subscribe((state) => {
        this.setLocationCookie(state);
        this.setStateAbbr(state);

        this.setIsLoadingState(false);
      });
  }
}
