import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { ComponentTheme, ThemeInterface } from '@core-mkt/services/theme-parser/theme-parser.service';
import { ThemeParserService } from '@core-mkt/services/theme-parser/theme-parser.service';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import type { Accordion } from '../../accordion/accordion/accordion';
import type { TabInfo } from './tabbed-content';
import { TabContent } from './tabbed-content';

@Component({
  selector: 'tabbed-content',
  templateUrl: './tabbed-content.component.html',
  styleUrls: ['./tabbed-content.scss'],
})
export class TabComponent implements OnInit {
  @Input() data: TabContent;
  activeIndex = 0;
  theme: ThemeInterface;
  firstTabTitle = '<h3>How It Works</h3>';
  secondTabTitle = '<h3>FAQ</h3>';
  tabs: TabInfo[] = [];

  constructor(private redactor: WysiwygRedactorService, private themeParser: ThemeParserService) {}

  get tabbedContentTitle(): string {
    return this.data.tabbedContentTitle;
  }

  ngOnInit(): void {
    this.loadTabs();
    const themeInput: ComponentTheme = {
      styles: this.data.styles,
      backgroundColor: this.data.backgroundColor,
      backgroundImage: null,
      textColor: '',
      columnCount: 0,
      columnDistribution: '',
    };
    this.theme = this.themeParser.getThemeClasses(themeInput);
    for (let i = 0; i < this.tabs.length; i++) {
      if (this.tabs[i].isActive) {
        this.activeIndex = i;
        break;
      }
    }
    this.data.tabbedContentTitle = this.redactor.bypassSanitizer(this.data.tabbedContentTitle);
  }

  loadTabs(): void {
    const accordionData: Accordion = {
      styles: [],
      accordionTitle: null,
      accordionDisplayType: 'simple',
      buttonType: null,
      buttonLabel: null,
      buttonLink: null,
      buttonLinkNewTab: false,
      buttonLinkNofollow: false,
      accordionLeftContent: null,
      accordionItems: [],
    };

    if (this.data.tab.length === 0) {
      const newTabItem: TabInfo = {
        tabTitle: this.firstTabTitle,
        tabContentList: [],
        accordion: accordionData,
        isActive: false,
      };
      this.parseForTabList(newTabItem);
    } else {
      this.data.tab.forEach((tabItem, idx) => {
        this.parseForTabList(tabItem, idx);
      });
    }
  }

  parseForTabList(tabItem: TabInfo, idx: number = 0): void {
    if (idx === 0 && (!tabItem.tabTitle || (tabItem.tabTitle && tabItem.tabTitle.length == 0))) {
      tabItem.tabTitle = this.firstTabTitle;
    }
    if (idx === 1 && (!tabItem.tabTitle || (tabItem.tabTitle && tabItem.tabTitle.length == 0))) {
      tabItem.tabTitle = this.secondTabTitle;
    }
    let accordionArray = tabItem.tabContentList;
    if (!accordionArray || accordionArray.length === 0) {
      accordionArray = [];
      this.data[`tabAccordion${idx + 1}`]?.forEach((item) => {
        const titleValue = item.accordionSectionTitle;
        delete item.accordionSectionTitle;
        item.accordionSectionTitle = [{ sectionTitle: titleValue }];
        accordionArray.push(item);
      });
    }
    tabItem.showContent = accordionArray.length > 0;
    tabItem.accordion = {
      styles: this.data.styles,
      accordionTitle: null,
      accordionDisplayType: 'simple',
      buttonType: null,
      buttonLabel: null,
      buttonLink: null,
      buttonLinkNewTab: false,
      buttonLinkNofollow: false,
      accordionLeftContent: null,
      accordionItems: accordionArray,
    };
    tabItem.tabTitle = this.redactor.bypassSanitizer(tabItem.tabTitle);

    this.tabs.push(tabItem);
  }

  onChange(index): void {
    this.activeIndex = parseInt(index);
  }
}
