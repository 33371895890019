import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { QueryParamModule } from '@core-mkt/services/query-param/query-param.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DesktopMenuComponent } from './desktop-menu/desktop-menu.component';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';

@NgModule({
  declarations: [DesktopMenuComponent, MobileMenuComponent],
  exports: [DesktopMenuComponent],
  imports: [CommonModule, FontAwesomeModule, QueryParamModule],
})
export class DesktopMenuModule {}
