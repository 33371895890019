/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const QuizWidgetFragment = {
  Quiz: gql`
    fragment QuizWidgetQuery on baseComponents_externalWidget_BlockType {
      styles
      vendorWidget
      externalWidgetId
      componentWidgetHeight
      typeHandle
    }
  `,
};
