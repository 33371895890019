<div class="tabbed-content font-body bg-cover {{ theme?.root }}">
  <div class="container {{ theme?.container }}">
    <div
      *ngIf="tabbedContentTitle"
      class="wysiwyg py-v-spacing sm:p-v-spacing max-w-vision mx-auto text-center"
      [innerHTML]="tabbedContentTitle"
    ></div>
    <div
      *ngIf="tabs && tabs.length > 1"
      class="tabbed-content__cta-wrapper py-v-spacing sm:p-v-spacing max-w-vision flex mx-auto justify-center items-stretch"
    >
      <button
        *ngFor="let tabItem of tabs; let index = index; let first = first; let last = last"
        class="
          flex-grow
          justify-center
          items-center
          tabbed-content-cta
          py-v-spacing
          sm:p-v-spacing
          wysiwyg
          {{ index !== activeIndex && tabs.length > 1 ? data.styles + ' hover:font-bold' : '' }}
          {{ tabs.length > 2 ? 'hidden lg:block' : '' }}
          {{
          (data?.backgroundColor && data?.backgroundColor.includes('text-white')) ||
          (data?.styles && data?.styles.includes('text-vis-reverse'))
            ? 'text-vis-reverse'
            : 'text-ace-teal-800'
        }}
        "
        (click)="activeIndex = index"
        [ngClass]="{
          '!font-extrabold': index === activeIndex && tabs.length > 1,
          'text-vis-reverse dark-active-tab':
            index === activeIndex &&
            tabs.length > 1 &&
            ((data?.backgroundColor && data?.backgroundColor.includes('text-white')) ||
              (data?.styles && data?.styles.includes('text-vis-reverse'))),
          'text-ace-teal-800 active-tab':
            index === activeIndex &&
            tabs.length > 1 &&
            !(
              (data?.backgroundColor && data?.backgroundColor.includes('text-white')) ||
              (data?.styles && data?.styles.includes('text-vis-reverse'))
            ),
          'cursor-pointer rounded-md': tabs.length > 1
        }"
        [innerHTML]="tabItem.tabTitle"
      ></button>
      <div *ngIf="tabs.length > 2" class="p-v-spacing w-full select-container lg:hidden bg-ace-grey-400 rounded-md">
        <div class="rounded-md border border-vis-muted bg-ace-grey-100">
          <select
            class="text-sm w-full rounded-md cursor-pointer select-none h-auto bg-ace-grey-100 text-vis-regular"
            (change)="onChange($event.target.value)"
            [(ngModel)]="activeIndex"
          >
            <option *ngFor="let tabItem of tabs; let i = index" [value]="i" [innerHTML]="tabItem.tabTitle"></option>
          </select>
        </div>
      </div>
    </div>
    <div
      class="tabbed-content__tab-wrapper py-v-spacing sm:p-v-spacing max-w-vision mx-auto"
      *ngFor="let tabItem of tabs; let index = index"
      [class.hidden]="index !== activeIndex"
    >
      <accordion
        class="tabbed-content__tab -mt-6 block"
        *ngIf="tabItem.showContent"
        [data]="tabItem.accordion"
        isChild="true"
      ></accordion>
    </div>
  </div>
</div>
