<div *ngIf="hasContent" class="font-body bg-cover multi-card {{ theme?.root }}" [style]="theme?.bgImage">
  <div class="container {{ theme?.container }}">
    <div
      *ngIf="hasTitle"
      class="py-v-spacing tab:px-v-spacing tab:px-v-spacing max-w-vision mx-auto wysiwyg multi-card__title"
      [innerHTML]="data.componentTitle"
    ></div>
    <div
      [ngClass]="{
        'more-than-three-cards': data.cardList.length > 3,
        'mx-auto': data.cardList.length === 1,
        'py-v-spacing tab:px-v-spacing': data.cardList.length === 1,
        'max-w-vision': data.cardList.length === 1
      }"
      class="flex flex-col md:flex-row text-vis-regular multi-card__card-wrapper"
    >
      <div
        *ngFor="let card of data.cardList; let index = index"
        class="bg-white p-v-spacing md:w-full xs:mb-v-spacing rounded multi-card__card"
      >
        <div
          *ngIf="card.cardImage[0]"
          class="{{ card.imageShape }} mx-auto w-full card-image bg-cover bg-center"
          style="background-image: url({{ card.cardImage[0].url }})"
        ></div>
        <div class="wysiwyg-card mt-3" [innerHTML]="card.cardContent"></div>
        <div
          *ngIf="!!card.cardButtonLabel && !!card.cardButtonLink"
          class="button-section my-3 text-center multi-card__cta flex"
        >
          <vision-button defaultStyle="primary" [data]="card.cardButtonData"></vision-button>
        </div>
      </div>
    </div>
  </div>
</div>
