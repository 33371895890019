import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import type { ActivatedRouteSnapshot, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { Router } from '@angular/router';
import { CraftQueryService } from '@core-mkt/services/craft-query/craft-query.service';
import { EnvService } from '@core-mkt/services/env/env.service';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Response } from 'express';
import type { PageDataResponse } from './page-data-response';

@Injectable()
export class PageDataResolver {
  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private cqs: CraftQueryService,
    private transferState: TransferState,
    private router: Router,
    private env: EnvService,
    @Optional() @Inject(RESPONSE) private response: Response,
  ) {}

  // Replaces the occurrence of double dashes with a forward slash in a string.
  public slugToPath(path: string): string {
    return path.replace('--', '/');
  }

  // Recursively construct the full path of this route.
  recursiveFullPath(route: ActivatedRouteSnapshot, currentUrl = ''): string {
    if (!route.url.length) {
      return currentUrl;
    } else {
      if (currentUrl === '') {
        currentUrl = route.url.join('');
      } else {
        currentUrl = route.url.join('') + '/' + currentUrl;
      }
      return this.recursiveFullPath(route.parent, currentUrl);
    }
  }

  /*
   *   Create craft slug from urlParts for apollo query
   */
  fullPathFromArray(urlParts: Array<UrlSegment>): string {
    const prefix = this.env.get.slugPrefix;

    //All craft entries requiere a slug. Single's without a prefix should be name 'home'
    if (urlParts.length === 0 && prefix === '') {
      return 'home';
    }

    return prefix + urlParts.filter((part) => part.path !== '').join('/');
  }

  // Each top level key's value is an array by default with Craft's GraphQl function, convert these array values into their first value for convience.
  // For example "Menu": [{}] becomes "Menu": {}.
  processCraftResponse(craftData): object {
    const keys = Object.keys(craftData);
    const processedData = keys.reduce((accum, curr) => {
      accum[curr] = craftData[curr][0];
      return accum;
    }, {});

    return processedData;
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<PageDataResponse | null> {
    // Recursively get the full path of the current route using the ActivatedRouteSnapshot.
    const fullPath = this.fullPathFromArray(route.url);
    // Store resolver returned data at CRAFT_DATA_KEY in the browser using the transfer state api...
    // so that the browser does not also have to make product API calls.
    const CRAFT_DATA_KEY = makeStateKey<PageDataResponse>('craft-data-' + fullPath);

    // If the transfer state already has the resolver data key, CRAFT_DATA_KEY, then...
    // fetch the data and return return it.
    // If the transfer state does not already have the data, then do the work of the resolver.
    if (this.transferState.hasKey(CRAFT_DATA_KEY)) {
      const craftDataFromState = this.transferState.get<PageDataResponse>(CRAFT_DATA_KEY, null);
      // Remove the transfer key / data so that the next page load will make fresh api calls.
      this.transferState.remove(CRAFT_DATA_KEY);

      return craftDataFromState;
    } else {
      // The token and slug in query parameters are used for Craft's preview mode.
      const { token, slug } = route.queryParams;
      // Page data resolver is used for both normal rendering and rendering of a Craft page preview while content authors edit pages.
      // How we handle preview mode is a bit complicated and happens in two stages.
      // In stage 1, we know that we need to render a page as a preview. This is possible when the token and slug are present in query string params and when the path of the page is "preview".
      // - We simply need to redirect to the page with the path computed from the slug, and pass the token as a query string param.
      // In stage 2, have the slug from the full path and the token that Craft needs to get the preview data for the entry.
      // - We make the craft query and render the page normally.
      let craftPreviewMode = false;
      let craftPreviewModeStage1 = false;
      let craftPreviewModeStage2 = false;
      if (token && slug && fullPath === 'preview') {
        craftPreviewMode = true;
        craftPreviewModeStage1 = true;
      } else if (token) {
        craftPreviewMode = true;
        craftPreviewModeStage2 = true;
      }

      if (craftPreviewMode && craftPreviewModeStage1) {
        const previewPath = this.slugToPath(slug);
        this.router.navigate([`/${previewPath}/?token=${token}`], { queryParamsHandling: 'merge' });
        return null;
      }

      // Use the craft query service to get the page data.
      // The craft entry to query is determined from the full path.
      const site = this.env.get.brandConfig.craftSite;
      let craftQueryServiceError;
      let query = await this.cqs.getQuery(fullPath, token, site, slug).catch((err) => {
        craftQueryServiceError = err;
      });

      if (craftQueryServiceError || query === null) {
        if (isPlatformServer(this.platformId)) {
          this.response.status(404);
        }
        return null;
      }

      query = query.data;
      let processedData: PageDataResponse = null;

      // Format the craft data a bit for convience.
      const craftData = this.processCraftResponse(query);

      // If the query was successful, initialize the the 'processedData' object.
      // processedData is what the resolver will return
      if (query && Object.keys(query).length) {
        processedData = {
          craftData,
        };
      }

      // If we are on the server, set the data in the transferState api so that it can get picked up in the browser.
      if (isPlatformServer(this.platformId)) {
        this.transferState.set(CRAFT_DATA_KEY, processedData);
        if (this.transferState.hasKey(CRAFT_DATA_KEY)) {
          const craftDataFromState = this.transferState.get<PageDataResponse>(CRAFT_DATA_KEY, null);
        }
      }

      return processedData;
    }
  }
}
