import { InsertableModule } from '@aceable/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FooterModule as AAFooterModule } from '@core-mkt/aa/src/app/components/footer/footer.module';
import { EmailFormModule } from '@core-mkt/shared/components/email-form/email-form.module';
import { MarketingModule } from '@core-mkt/shared/components/marketing/marketing.module';
import { VisionModalModule } from '@core-mkt/shared/components/vision-modal/vision-modal.module';
import { AccordionModule } from '../../components/accordion/accordion.module';
import { AnchorButtonModule } from '../../components/anchor-button/anchor-button.module';
import { AnchorModule } from '../../components/anchor/anchor.module';
import { BreadcrumbModule } from '../../components/breadcrumb/breadcrumb.module';
import { LayoutModule } from '../../components/layout/layout.module';
import { MultiCardModule } from '../../components/multi-card/multi-card.module';
import { MultiColumnModule } from '../../components/multi-column/multi-column.module';
import { ProductCardModule } from '../../components/product-card/product-card.module';
import { ResourcesModule } from '../../components/resources/resources.module';
import { ReviewsModule } from '../../components/reviews/reviews.module';
import { SliderCardModule } from '../../components/slider-card/slider-card.module';
import { SliderModule } from '../../components/slider/slider.module';
import { StudyPlanBuilderModule } from '../../components/study-plan-builder/study-plan-builder.module';
import { TabContentModule } from '../../components/tabbed-content/tabbed-content.module';
import { TopProductModule } from '../../components/top-product/top-product.module';
import { TopSectionModule } from '../../components/top-section/top-section.module';
import { TrustpilotModule } from '../../components/trustpilot/trustpilot.module';
import { TemplateDisambigComponent } from './template-component/template-disambig.component';
import { TemplateDisambigRoutingModule } from './template-disambig-routing.module';

@NgModule({
  declarations: [TemplateDisambigComponent],
  exports: [TemplateDisambigComponent],
  imports: [
    CommonModule,
    MarketingModule,
    InsertableModule,
    VisionModalModule,
    TemplateDisambigRoutingModule,
    TopProductModule,
    AnchorModule,
    AnchorButtonModule,
    TabContentModule,
    BreadcrumbModule,
    ReviewsModule,
    MultiColumnModule,
    LayoutModule,
    MultiCardModule,
    TrustpilotModule,
    AccordionModule,
    ResourcesModule,
    ProductCardModule,
    SliderModule,
    SliderCardModule,
    TopSectionModule,
    EmailFormModule,
    AAFooterModule,
    StudyPlanBuilderModule,
  ],
})
export class TemplateDisambigModule {}
