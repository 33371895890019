import type { HeaderNavLink } from './header';

export const headerData: HeaderNavLink[] = [
  {
    linkUrl: '#',
    linkLabel: 'Driving',
    isDropDown: true,
    menuSize: '340px',
    ddList: [
      {
        linkUrl: '/drivers-ed/',
        linkLabel: 'Drivers Ed',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/permit-test-prep-courses/',
        linkLabel: 'Permit Test Prep',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/defensive-driving/',
        linkLabel: 'Defensive Driving',
        newTab: false,
        nofollow: false,
      },
    ],
  },
  {
    linkUrl: 'https://www.aceableagent.com/',
    linkLabel: 'Real Estate',
    menuSize: '340px',
    isDropDown: false,
  },
  {
    linkUrl: 'https://insurance.aceable.com/',
    linkLabel: 'Insurance',
    menuSize: '340px',
    isDropDown: false,
  },
  {
    linkUrl: '/mortgage/',
    linkLabel: 'Mortgage',
    menuSize: '340px',
    isDropDown: false,
  },
  {
    linkUrl: '#',
    linkLabel: 'Our Company',
    menuSize: '340px',
    isDropDown: true,
    ddList: [
      {
        linkUrl: '/company/',
        linkLabel: 'Our Mission and Values',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/company/aceable-brands/',
        linkLabel: 'Aceable Brands',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/company/why-choose-aceable/',
        linkLabel: 'Why Choose Us',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/driving-partnerships/',
        linkLabel: 'Partnerships',
        newTab: false,
        nofollow: false,
      },
    ],
  },
];

export const myAccountData: HeaderNavLink[] = [
  {
    linkUrl: '#',
    linkLabel: 'My Account',
    menuSize: '180px',
    isDropDown: true,
    ddList: [
      {
        linkUrl: 'https://driving.aceable.com/user/signin',
        linkLabel: 'Sign In',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: 'https://support.aceabledriving.com/hc/en-us/',
        linkLabel: 'Help Center',
        newTab: false,
        nofollow: false,
      },
    ],
  },
];
