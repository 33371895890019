/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const HomeFragment = {
  aceHomepageTemplate: gql`
    fragment homepageTemplate_fragment on aceHomepageTemplate_MatrixField {
      ... on aceHomepageTemplate_homeHero_BlockType {
        productPickerTable {
          ... on productPickerCategories_Category {
            productPickerTable {
              state
              productName
              productLink
              noFollow
              noReferrer
              modal
            }
          }
        }
        backgroundColor
        backgroundImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        heroContent
        heroTitle
        showTrustpilot
        showImageOnMobile
        heroImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        imageAlignment
        textColor
        typeHandle
        buttonList {
          ... on buttonList_BlockType {
            buttonIcon {
              url
              ... on aceImagesS3_Asset {
                altText
              }
            }
            buttonTitle
            buttonSubtitle
            buttonLink
            buttonLinkNewTab
            buttonLinkNofollow
          }
        }
      }
      ... on aceHomepageTemplate_graphicBanner_BlockType {
        typeHandle
      }
      ... on aceHomepageTemplate_productFilterCards_BlockType {
        backgroundColor
        backgroundImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        filterType
        ctaStyle
        useCompactCards
        productCardList {
          ... on productCardList_BlockType {
            cardBackgroundColor
            cardTextColor
            ctaLabel
            ctaLink
            ctaNewTab
            ctaNofollow
            learnMoreLink
            productEntry {
              ...productEntry_fragment
            }
            textContent
            learnMoreLabel
            learnMoreNewTab
            learnMoreNofollow
            starRating
            starCaption
            showSavings
            cardTitle
          }
        }
        sectionTitle
        textColor
        typeHandle
      }
      ... on aceHomepageTemplate_accordion_BlockType {
        typeHandle
        accordionDisplayType
        accordionItems {
          ... on accordionItems_BlockType {
            itemTitle
            itemDescription
            showDescription
            accordionSectionTitle {
              ... on accordionSectionTitle_accordionSectionTitle_BlockType {
                sectionTitle
              }
            }
            accordionImage {
              url
              ... on aceImagesS3_Asset {
                altText
              }
            }
          }
        }
        accordionTitle
        accordionLeftContent
        buttonLabel
        buttonLink
        buttonLinkNewTab
        buttonLinkNofollow
        buttonType
      }
      ... on aceHomepageTemplate_anchor_BlockType {
        typeHandle
        anchorName
      }
      ... on aceHomepageTemplate_anchorButton_BlockType {
        typeHandle
        backgroundColor
        buttonAlignment
        buttonLabel
        scrollTo
        buttonStyle
        buttonArrowDirection
      }
      ... on aceHomepageTemplate_productCards_BlockType {
        backgroundColor
        backgroundImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        hideCta
        ctaStyle
        useCompactCards
        productCardList {
          ... on productCardList_BlockType {
            cardBackgroundColor
            cardTextColor
            ctaLabel
            ctaLink
            ctaNewTab
            ctaNofollow
            learnMoreLink
            productEntry {
              ...productEntry_fragment
            }
            textContent
            learnMoreLabel
            learnMoreNewTab
            learnMoreNofollow
            starRating
            starCaption
            showSavings
            cardTitle
          }
        }
        sectionTitle
        textColor
        typeHandle
      }

      ... on aceHomepageTemplate_resources_BlockType {
        typeHandle
        resourcesBackgroundColor
        resourcesBackgroundImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        resourcesTextColor
        resourcesTitle
        resourceCards {
          ... on resourceCards_BlockType {
            resourceImage {
              url
              ... on aceImagesS3_Asset {
                altText
              }
            }
            resourceBackgroundColor
            resourceTextContent
            resourceCtaStyle
            resourceCtaLabel
            resourceCtaLink
            resourceCtaLinkNewTab
            resourceCtaLinkNofollow
          }
        }
      }
      ... on aceHomepageTemplate_layout_BlockType {
        typeHandle
        productPickerTable {
          ... on productPickerCategories_Category {
            productPickerTable {
              state
              productName
              productLink
              noFollow
              noReferrer
              modal
            }
          }
        }
        backgroundColor
        backgroundImage {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        textColor
        image {
          url
          ... on aceImagesS3_Asset {
            altText
          }
        }
        imageAlignment
        layoutTitle
        showTrustpilot
        layoutContent
        layoutRightContent
        dropdownPlaceholder
        dropdownTopLabel
        dropdown {
          ... on dropdown_BlockType {
            dropdownLabel
            dropdownLink
            dropdownLinkNewTab
            dropdownLinkNofollow
          }
        }
        buttonLayout
        buttonAlignment
        buttons {
          ... on buttons_BlockType {
            buttonLabel
            buttonLink
            buttonLinkNewTab
            buttonLinkNofollow
            buttonType
          }
        }
        highlightedTextModal1 {
          ... on highlightedTextModal1_BlockType {
            modalContent
          }
        }
        highlightedTextModal2 {
          ... on highlightedTextModal2_BlockType {
            modalContent
          }
        }
      }
      ... on aceHomepageTemplate_multiColumn_BlockType {
        tailwindClasses
        typeHandle
        backgroundColor
        backgroundImage {
          url
        }
        textColor
        componentTitle
        columnList: columnList {
          ... on columnList_BlockType {
            columnContent
            dropdownTitle
            dropdownAlignment
            dropdown {
              dropdownLabel
              dropdownLink
              dropdownLinkNewTab
              dropdownLinkNofollow
            }
          }
        }
        twoColumnDistribution
        highlightedTextModal1 {
          ... on highlightedTextModal1_BlockType {
            modalContent
          }
        }
        highlightedTextModal2 {
          ... on highlightedTextModal2_BlockType {
            modalContent
          }
        }
      }
      ... on aceHomepageTemplate_reviews_BlockType {
        typeHandle
        reviewType
        reviewTitle
        textColor
        backgroundColor
        backgroundImage {
          url
        }
      }
      ... on aceHomepageTemplate_sliderCard_BlockType {
        typeHandle
        backgroundColor
        textColor
        sliderTitle
        sliderDescription
        displayAllCardMobile
        slidesCards {
          ... on slidesCards_BlockType {
            slideImage {
              url
              ... on aceImagesS3_Asset {
                altText
                height
                width
              }
            }
            slideContent
            slideButtonStyle
            slideButtonLabel
            slideButtonLink
            slideButtonNewTab
            slideButtonNofollow
          }
        }
      }
    }
  `,
};
