import { Injectable } from '@angular/core';
import axios from 'axios';
import { IterableService } from './iterable.service';

import type { FormPayload } from '@core-mkt/shared/components/email-form/email-form/email-form';

@Injectable({
  providedIn: 'any',
})
export class ServerIterableService extends IterableService {
  async addToMailList(data: FormPayload): Promise<any> {
    return axios.post('');
  }

  async updateUser(data: any): Promise<any> {
    return axios.post('');
  }
}
