<div *ngIf="theme && hasContent" class="font-body bg-cover {{ theme?.root }}">
  <div class="text-center py-12 lg:py-[4.5rem] bg-ace-grey-300 px-6">
    <h1 *ngIf="faqTitle" class="text-5xl mb-2">{{ faqTitle }}</h1>
    <p *ngIf="faqSubTitle" class="text-baselg font-normal">{{ faqSubTitle }}</p>
  </div>
  <div class="flex flex-col sm:flex-row border-b-2 border-ace-grey-400 justify-center py-5 px-6">
    <span class="inline-flex items-center text-baselg font-bold leading-7 mr-5 mb-2">Filter information by state</span>
    <span>
      <!-- Loading indicator -->
      <div
        class="w-full font-bold cursor-pointer text-baselg text-left text-vis-regular xs:inline-flex relative bg-white border border-gray-800 rounded-md p-5 pr-20 leading-6 gap-2 cursor-pointer bg-no-repeat bg-right bg-center"
        *ngIf="isLoadingState"
      >
        <loading-indicator label="Loading state..."></loading-indicator>
      </div>
      <div *ngIf="!isLoadingState" class="dropdown inline-block relative text-center hidden sm:block">
        <button class="dropdown-button relative leading-6 w-[335px]" (click)="toggleDropdown($event)">
          <span class="mr-2 dropdown-text">{{ dropdownPlaceholder }}</span>
          <div class="dropdown-items">
            <span class="dropdown-item-line"></span>
            <fa-icon [icon]="faCaretDown" class="fa-xs dropdown-icon dropdown-caret"> </fa-icon>
            <fa-icon [icon]="faChevronDown" class="fa-xs dropdown-chevron"> </fa-icon>
          </div>
        </button>
        <ul
          class="dropdown-menu max-h-[20rem] dropdown-section absolute text-left overflow-y-auto bg-white rounded-md w-[335px] shadow-sm drop-shadow-sm z-50 w-full"
          [ngClass]="{ hidden: !dropdownCollapse, block: dropdownCollapse }"
        >
          <li
            *ngFor="let state of statesObject"
            class="dropdown-item cursor-pointer"
            (click)="onChange(state.abbv)"
            (keyup.enter)="onChange(state.abbv)"
            [attr.tabindex]="dropdownCollapse ? '0' : '-1'"
          >
            <label class="text-vis-regular">
              {{ state.name }}
            </label>
          </li>
        </ul>
      </div>
      <select
        *ngIf="!isLoadingState"
        id="state selection"
        aria-label="State Selection"
        #states
        (change)="onChange(states.value)"
        class="sm:hidden xs:inline-flex w-full font-bold cursor-pointer text-baselg text-left text-vis-regular xs:inline-flex relative bg-white border border-gray-800 rounded-md p-5 pr-20 leading-6 gap-2 cursor-pointer bg-no-repeat bg-right bg-center"
      >
        <option
          *ngFor="let state of statesObject"
          value="{{ state.abbv }}"
          label="{{ state.name }}"
          [selected]="state.abbv === selectedStateAbbr"
        >
          {{ state.name }}
        </option>
      </select>
    </span>
  </div>
  <div class="container !max-w-[52.625rem] theme.container">
    <div class="mb-[4.5rem] md:mb-[7.5rem] last:mb-0" *ngFor="let section of filteredData.sections">
      <h2 class="text-left text-lg mb-0 py-6">{{ section.title }}</h2>

      <div
        class="py-6 border-t-2 border-ace-grey-400 last:border-b-2"
        *ngFor="let question of section.questions; let i = index"
      >
        <a
          [attr.data-formelementid]="'DRIVER-SAFETY-BTN-CLK-FAQ-' + section.title + '-POS' + (i + 1)"
          class="faq-heading"
          href="#"
        >
          <h3
            class="cursor-pointer flex justify-between gap-x-3 text-md font-bold mb-0"
            (click)="toggleAnswer(question)"
          >
            <span class="flex-1">{{ question.question }}</span>
            <!-- faMinus -->
            <svg
              *ngIf="!question.showAnswer"
              class="w-[1.5rem] shrink-0 m-auto h-full"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 19.25C11.7167 19.25 11.4792 19.1542 11.2875 18.9625C11.0958 18.7708 11 18.5333 11 18.25V13H5.75C5.46667 13 5.22917 12.9042 5.0375 12.7125C4.84583 12.5208 4.75 12.2833 4.75 12C4.75 11.7167 4.84583 11.4792 5.0375 11.2875C5.22917 11.0958 5.46667 11 5.75 11H11V5.75C11 5.46667 11.0958 5.22917 11.2875 5.0375C11.4792 4.84583 11.7167 4.75 12 4.75C12.2833 4.75 12.5208 4.84583 12.7125 5.0375C12.9042 5.22917 13 5.46667 13 5.75V11H18.25C18.5333 11 18.7708 11.0958 18.9625 11.2875C19.1542 11.4792 19.25 11.7167 19.25 12C19.25 12.2833 19.1542 12.5208 18.9625 12.7125C18.7708 12.9042 18.5333 13 18.25 13H13V18.25C13 18.5333 12.9042 18.7708 12.7125 18.9625C12.5208 19.1542 12.2833 19.25 12 19.25Z"
                fill="#121212"
              />
            </svg>
            <!-- faPlus -->
            <svg
              *ngIf="question.showAnswer"
              class="w-[1.5rem] shrink-0 m-auto h-full"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.75 13C5.46667 13 5.22917 12.9042 5.0375 12.7125C4.84583 12.5208 4.75 12.2833 4.75 12C4.75 11.7167 4.84583 11.4792 5.0375 11.2875C5.22917 11.0958 5.46667 11 5.75 11H18.25C18.5333 11 18.7708 11.0958 18.9625 11.2875C19.1542 11.4792 19.25 11.7167 19.25 12C19.25 12.2833 19.1542 12.5208 18.9625 12.7125C18.7708 12.9042 18.5333 13 18.25 13H5.75Z"
                fill="#121212"
              />
            </svg>
          </h3>
        </a>
        <h4
          class="my-2 text-baselg font-normal leading-7 [&_ul]:list-disc [&_ul]:ml-8 [&_p]text-vis-regular"
          *ngIf="question.showAnswer"
          [innerHTML]="question.answer"
        ></h4>
      </div>
    </div>
  </div>
</div>
