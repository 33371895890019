<div class="price-selector-wrapper border-t border-b border-ruler">
  <div class="price-selector max-w-[618px] mx-auto flex flex-col sm:flex-row p-6">
    <span class="text-baselg sm:mr-6 font-bold sm:text-right self-center">{{ selectorInstruction }}</span>
    <!-- Loading indicator -->
    <div
    class="w-full font-bold cursor-pointer text-baselg text-left text-vis-regular xs:inline-flex relative bg-white border border-gray-800 rounded-md p-5 leading-1 gap-2 cursor-pointer bg-no-repeat bg-right bg-center"
    *ngIf="isLoadingState"
    >
      <loading-indicator label="Loading state..."></loading-indicator>
    </div>
    
    <div *ngIf="!isLoadingState" class="dropdown inline-block relative text-center hidden sm:block">
      <button class="dropdown-button relative leading-6 w-[335px]" (keyup.enter)="toggleDropdown($event)"
        (click)="toggleDropdown($event)">
        <span class="mr-2 dropdown-text">{{ dropdownPlaceholder }}</span>
        <div class="dropdown-items">
          <span class="dropdown-item-line"></span>
          <fa-icon [icon]="faCaretDown" class="fa-xs dropdown-icon dropdown-caret"> </fa-icon>
          <fa-icon [icon]="faChevronDown" class="fa-xs dropdown-chevron"> </fa-icon>
        </div>
      </button>
      <div class="h-2"></div>
      <ul
        class="dropdown-menu max-h-[20rem] dropdown-section absolute text-left overflow-y-auto bg-white rounded-md w-[335px] shadow-sm drop-shadow-sm z-50 w-full"
        [ngClass]="{ hidden: !dropdownCollapse, block: dropdownCollapse }">
        <ng-template ngFor let-d [ngForOf]="selectorList">
          <li class="dropdown-item" (click)="updateProduct(d)" (keyup.enter)="updateProduct(d)">
            <label class="text-vis-regular">
              {{ d.state }}
            </label>
          </li>
        </ng-template>
      </ul>
    </div>
    <select *ngIf="!isLoadingState" (change)="updateProductMobile($event.target.value)" aria-label="State Selection" id="mobile_select"
      class="sm:hidden xs:inline-flex relative appearance-none">
      <option *ngFor="let d of selectorList; let index = index" [value]="index" [selected]="d.state === selectedState" label="{{ d.state }}">
        {{ d.state }}
      </option>
    </select>
  </div>
</div>
<product-card *ngIf="!isLoadingState && showProductCard" [data]="selectorProductCard"
  horizontalAlignment="center"></product-card>
