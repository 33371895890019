<div class="msg-404 bg-gradient-to-r from-ace-teal-600 to-ace-teal-alt text-vis-regular">
  <div class="msg-404__wrapper container">
    <h1
      class="mt-0 text-vis-reverse msg-404__title1 mb-12 py-v-spacing tab:px-v-spacing max-w-vision mx-auto font-medium text-center"
    >
      Oops! Wrong address!
    </h1>
    <h2
      class="mt-0 msg-404__title2 font-medium py-v-spacing tab:px-v-spacing max-w-vision mx-auto mb-v-spacing text-center"
    >
      We couldn't find the page you were looking for.
    </h2>
    <div class="msg-404__info flex md:flex-row flex-col py-v-spacing tab:px-v-spacing max-w-vision mx-auto bg-white">
      <div class="msg-404__explanation grow {{ checkClassName }}">
        <h3 class="text-md mb-v-spacing italic font-semibold">This is either because:</h3>
        <ul class="mb-v-spacing msg-404__bullets">
          <li class="mb-v-spacing">
            There is an error in the URL entered into your web browser. Please check the URL and try again.
          </li>
          <li class="mb-v-spacing">The page you are looking for has been moved or deleted.</li>
        </ul>
      </div>
      <div class="msg-404__helpful-links">
        <h3 class="text-md mb-v-spacing font-semibold">Helpful Links</h3>
        <a class="underline block text-ace-teal-800 font-medium text-md mb-v-spacing" href="https://www.aceable.com/"
          >Homepage</a
        >
        <a class="underline block text-ace-teal-800 font-medium text-md mb-v-spacing" href="https://help.aceable.com/"
          >Help Center</a
        >
      </div>
    </div>
  </div>
</div>
