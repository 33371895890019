import type { LearnMore } from './learn-more';

export const learnModeMockData: LearnMore = {
  styles: ['bg-ace-teal-600 text-vis-regular'],
  backgroundColor: 'bg-ace-teal-600 text-vis-regular',
  textColor: 'text-vis-reverse',
  componentPadding: 'py-8',
  learnMoreImage: [
    {
      url: 'https://xgrit-ecom.imgix.net/ace/Aceable-Online-Defensive-Driving.svg?v=1648053872&auto=format',
      altText: 'Aceable Online Defensive Driving Mobile Course',
    },
  ],
  learnMoreContent: `<p class='font-bold text-lg'>A Short Headline Can Go Here</p>
  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>`,
  ctaStyle:
    'cta-md bg-ace-pink-600 hover:bg-ace-pink-400 text-vis-reverse inline-flex items-center justify-center text-center p-3',
  ctaLabel: 'Learn More',
  ctaNofollow: true,
  ctaNewTab: true,
  ctaLink: 'https://www.aceable.com',
  layout: 'Col', // Row Col
};
