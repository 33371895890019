<div class="footer__{{ footerLocation }}-container {{ alignClass }} {{ flexDirection }} flex">
  <a
    class="{{ footerLocation === 'bottom' ? 'pb-0' : 'pb-3' }} py-1 text-sm leading-6 hover:text-ace-teal-600 hover:underline flex {{ layout }} {{ linkClass }}"
    *ngFor="let link of links; let first = first; let last = last"
    [href]="link.linkUrl"
    [attr.target]="link.newTab ? '_blank' : null"
    [attr.nofollow]="link.nofollow"
    [attr.aria-label]="!validateGetAriaLabelledby(link.linkUrl, link.linkText) ? (link.linkText + (link.newTab ? ' (opens in a new tab)' : '')) : null"
    [attr.aria-labelledby]="validateGetAriaLabelledby(link.linkUrl, link.linkText) ? requestService.getAriaLabelledby(link.linkText) + '-nav-label' : null"
  >
    <fa-icon
      *ngIf="link.icon"
      class="mr-3 h-4 w-4 self-center -mt-1 justify-self-end fa-1x"
      [icon]="[iconPrefix, link.icon]"
    ></fa-icon>
    <span class="md:inline" [class.hidden]="footerLocation === 'social'">{{ link.linkText }}</span>
    <!-- Hidden Context -->
    <span *ngIf="validateGetAriaLabelledby(link.linkUrl, link.linkText)" [attr.id]="requestService.getAriaLabelledby(link.linkText) + '-nav-label'" class="sr-only">{{validateGetAriaLabelledby(link.linkUrl, link.linkText)}}</span>
    <span *ngIf="footerLocation === 'bottom'" class="mx-[10px] divider">|</span>
  </a>
</div>