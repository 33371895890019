/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const StudyPlanBuilderFragment = {
  StudyPlanBuilder: gql`
    fragment StudyPlanBuilderQuery on baseComponents_productBuilder_BlockType {
      typeHandle
      wysiwygContent
      imageCarousel {
        url
        width
        height
        ... on images_Asset {
          altText
        }
      }
      productBuilder {
        ... on productBuilder_Category {
          productBuilderTable {
            state
            productId
            courseType
            duration
            coupon
          }
        }
      }
    }
  `,
};
