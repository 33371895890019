/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const MultiColumnFragment = {
  MultiColumn: gql`
    fragment MultiColumnQuery on baseComponents_multiColumn_BlockType {
      backgroundImage {
        url
        ... on images_Asset {
          altText
        }
      }
      columnList {
        ... on columnList_BlockType {
          columnContent
          dropdown {
            dropdownLabel
            dropdownLink
            dropdownLinkNewTab
            dropdownLinkNofollow
          }
          dropdownAlignment
          dropdownButtonType
          dropdownTitle
        }
      }
      componentTitle
      highlightedTextModal1 {
        ... on highlightedTextModal1_BlockType {
          modalContent
        }
      }
      highlightedTextModal2 {
        ... on highlightedTextModal2_BlockType {
          modalContent
        }
      }
      lineBreak_mainContent
      lineBreak_modals
      lineBreak_styles
      styles
      twoColumnDistribution
      typeHandle
    }
  `,
};
