import { DOCUMENT } from '@angular/common';
import type { OnInit } from '@angular/core';
import { Component, HostListener, Inject, Input } from '@angular/core';
import type { FormGroup } from '@angular/forms';
import { FormBuilder, Validators } from '@angular/forms';
import { EnvService } from '@core-mkt/services/env/env.service';
import { IterableService } from '@core-mkt/services/iterable/iterable.service';
import { StorageService } from '@core-mkt/services/storage/storage.service';
import { VerticalService } from '@core-mkt/services/vertical/vertical.service';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import type { FormPayload } from '@core-mkt/shared/components/email-form/email-form/email-form';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { EmailCapture } from '../footer/footer';
@Component({
  selector: 'email-capture',
  templateUrl: './email-capture.component.html',
})
export class EmailCaptureComponent implements OnInit {
  @Input() data: EmailCapture;
  faTimes = faTimes;
  emailForm: FormGroup;
  isSubmited = false;
  isEmailInvalid = false;
  window: Window;
  buttonLabelDefault = 'Get My Discount';
  display: string;
  displayAfterSubmit: string;
  couponDefault = 'CAREER20';
  dismissedString = 'dismissed-';
  path: string;
  showEmailCapture = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private vertical: VerticalService,
    private readonly formBuilder: FormBuilder,
    private redactor: WysiwygRedactorService,
    private iterable: IterableService,
    private readonly storageService: StorageService,
    private env: EnvService,
  ) {
    this.emailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
    this.window = this.document.defaultView;
  }

  get coupon(): string {
    return this.data?.coupon || this.couponDefault;
  }

  get displayContent(): string {
    return this.display;
  }

  get displayContentAfterSubmit(): string {
    return this.displayAfterSubmit;
  }

  get buttonLabel(): string {
    return this.data?.buttonLabel || this.buttonLabelDefault;
  }

  ngOnInit(): void {
    this.path = this.vertical.getVerticalInfo().vertical;
    this.display = this.redactor.bypassSanitizer(this.data?.displayContent);
    this.displayAfterSubmit = this.redactor.bypassSanitizer(this.data?.displayContentAfterSubmit);
  }

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    this.showEmailCapture = false;
  }

  async submit(): Promise<void> {
    const emailRegEx = /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (!emailRegEx.test(this.emailForm.value.email)) {
      this.isEmailInvalid = true;
      return;
    }
    this.isEmailInvalid = false;
    const iterableData: FormPayload = {
      listId: 94171,
      subscribers: [
        {
          dataFields: {},
          email: this.emailForm.value.email,
        },
      ],
      type: 'RE',
    };

    await this.iterable
      .addToMailList(iterableData, this.env.get.brandConfig.id)
      .catch(() => {
        this.isSubmited = false;
      })
      .then(() => {
        this.isSubmited = true;
      });
  }

  close(): void {
    this.storageService.set(`${this.dismissedString}-${this.coupon}`, 'true');
    this.showEmailCapture = false;
  }
}
