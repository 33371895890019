<div class="ambassador-referral {{ theme?.root }} bg-cover bg-no-repeat bg-center">
  <div class="ambassador-referral container {{ theme?.container }}">
    <div class="flex flex-col lg:flex-row items-center">
      <div class="ambassador-referral-content pb-v-spacing sm:p-v-spacing {{ image && image.url ? 'lg:w-1/2' : ''}}">
        <div *ngIf="referralTitle" class="wysiwyg pb-4 referral-title" [innerHTML]="referralTitle"></div>
        <div *ngIf="referralContent" class="wysiwyg pb-8 referral-content" [innerHTML]="referralContent"></div>
        <vision-dropdown *ngIf="dropdownTitle && dropdown.length > 0" [dropdownData]="dropdownData"
          (changeEvent)="handleCampaignChange($event)"></vision-dropdown>
      </div>
      <div *ngIf="image && image.url" class="ambassador-referral-image pt-v-spacing sm:p-v-spacing lg:w-1/2">
        <img
          *ngIf="image && image.url"
          src="{{ imgixService.buildImgixUrl(image.url) }}"
          [alt]="image.altText ? image.altText : ''"
          width="507"
          height="534"
        />
      </div>
    </div>
  </div>
</div>
