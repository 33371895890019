/* eslint-disable prettier/prettier */
import { ProductFragment } from '@core-mkt/shared/craft/product-entry-fragment';
import * as gql from 'fake-tag';

export const PricingTableFragment = {
  pricingTable: gql`
    fragment pricingTableQuery on baseComponents_pricingTable_BlockType {
      guaranteeText
      bundles {
      ... on bundles_BlockType {
        bottomContent
        ctaLabel
        ctaLinkNewTab
        ctaLinkNofollow
        ctaLinkOverride
        ctaStyle
        principalContent
        product {
          ...ProductEntry
        }
        topContent
        topContentStyles
      }
    }
    bundleTitleOne
    bundleTitleTwo
    bundleTitleThree
    pricingTableItems {
      ... on pricingTableItems_BlockType {
        principalContent
        productOne
        productTwo
        productThree
      }
    }
    lineBreak_PricingTableItems
    lineBreak_bundlesMainContent
    lineBreak_styles
    styles
    typeHandle
    }
  ${ProductFragment.product}`,
};
