import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { InsertableModule } from '@aceable/core';
import { MarketingModule } from '@core-mkt/shared/components/marketing/marketing.module';

import { QuizModule } from '@core-mkt/brands/aa/src/app/components/quiz/quiz.module';
import { AccordionModule } from '@core-mkt/shared/components/accordion/accordion.module';
import { BannerModule } from '@core-mkt/shared/components/banner/banner.module';
import { BreadcrumbModule } from '@core-mkt/shared/components/breadcrumb/breadcrumb.module';
import { EmailFormModule } from '@core-mkt/shared/components/email-form/email-form.module';
import { GraphicBannerModule } from '@core-mkt/shared/components/graphic-banner/graphic-banner.module';
import { LayoutModule } from '@core-mkt/shared/components/layout/layout.module';
import { MultiColumnModule } from '@core-mkt/shared/components/multi-column/multi-column.module';
import { ProductCardModule } from '@core-mkt/shared/components/product-card/product-card.module';
import { QuizWidgetModule } from '@core-mkt/shared/components/quiz-widget/quiz-widget.module';
import { ResourcesModule } from '@core-mkt/shared/components/resources/resources.module';
import { VisionModalModule } from '@core-mkt/shared/components/vision-modal/vision-modal.module';
import { FooterModule } from '../../components/footer/footer.module';
import { HeaderModule } from '../../components/header/header.module';
import { PracticeTestModule } from '../../components/practice-test/practice-test.module';
import { TemplatePracticeComponent } from './template-component/template-practice.component';
import { TemplatePracticeRoutingModule } from './template-practice-routing.module';

@NgModule({
  declarations: [TemplatePracticeComponent],
  exports: [TemplatePracticeComponent],
  imports: [
    CommonModule,
    VisionModalModule,
    MarketingModule,
    InsertableModule,
    EmailFormModule,
    TemplatePracticeRoutingModule,
    HeaderModule,
    FooterModule,
    BannerModule,
    BreadcrumbModule,
    MultiColumnModule,
    LayoutModule,
    AccordionModule,
    ResourcesModule,
    PracticeTestModule,
    QuizModule,
    QuizWidgetModule,
    GraphicBannerModule,
    ProductCardModule,
  ],
})
export class TemplatePracticeModule {}
