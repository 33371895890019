/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const SideProductPickerFragment = {
SideProductPicker: gql`
    fragment SideProductPickerQuery on baseComponents_sideProductPicker_BlockType {
        typeHandle
        productPicker {
        ... on productPicker_BlockType {
            styles
            ctaLabel
            ctaType
            productPickerHeading
            productCategory {
            ... on productPickerCategories_Category {
                productPickerTable {
                noFollow
                noReferrer
                modal
                productLink
                productName
                state
                }
            }
            }
        }
        }
        sideContent
        mobileBannerOptions {
            ... on mobileBannerOptions_BlockType {
                displayMobileBanner
                styles
                bannerText
                ctaLabel
                ctaType
            }
        }
    }
  `,
};
