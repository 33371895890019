/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';
import { HeaderFragment, SettingsFragment } from './fragments/header-fragment-query';
import { SEOPracticeTestREFragment } from './fragments/seo-fragment-query';

export const TemplatePracticeQuery = {
  name: 'TemplateProductQuery',
  query: gql`
    query ($site: [String], $slug: [String]) {
      entryData: entries(site: $site, slug: $slug) {
        slug
        sectionHandle
        typeHandle
        ... on aceRealEstateTemplates_practiceTestTemplate_Entry {
          id
          hideStickyBanner
          practiceTestTemplate {
            ... on practiceTestTemplate_breadcrumbs_BlockType {
              typeHandle
              backgroundColor
              textColor
              hideOnMobile
              breadcrumbsList {
                ... on breadcrumbsList_BlockType {
                  breadcrumbLink
                  breadcrumbText
                }
              }
            }
            ... on practiceTestTemplate_graphicBanner_BlockType {
              typeHandle
            }
            ... on practiceTestTemplate_sliderCard_BlockType {
              typeHandle
              backgroundColor
              textColor
              sliderTitle
              sliderDescription
              displayAllCardMobile
              slidesCards {
                ... on slidesCards_BlockType {
                  slideImage {
                    url
                    ... on aceImagesS3_Asset {
                      altText
                      height
                      width
                    }
                  }
                  slideContent
                  slideButtonStyle
                  slideButtonLabel
                  slideButtonLink
                  slideButtonNewTab
                  slideButtonNofollow
                }
              }
            }
            ... on practiceTestTemplate_multiColumn_BlockType {
              tailwindClasses
              backgroundColor
              backgroundImage {
                url
                ... on aceImagesS3_Asset {
                  altText
                }
              }
              columnList: columnList {
                ... on columnList_BlockType {
                  columnContent
                  dropdownTitle
                  dropdownAlignment
                  dropdown {
                    dropdownLabel
                    dropdownLink
                    dropdownLinkNewTab
                    dropdownLinkNofollow
                  }
                }
              }
              componentTitle
              textColor
              twoColumnWidthDistribution
              highlightedTextModal1 {
                ... on highlightedTextModal1_BlockType {
                  modalContent
                }
              }
              highlightedTextModal2 {
                ... on highlightedTextModal2_BlockType {
                  modalContent
                }
              }
              typeHandle
            }
            ... on practiceTestTemplate_accordion_BlockType {
              accordionDisplayType
              accordionItems {
                ... on accordionItems_BlockType {
                  itemDescription
                  itemTitle
                  accordionSectionTitle {
                    ... on accordionSectionTitle_accordionSectionTitle_BlockType {
                      sectionTitle
                    }
                  }
                  accordionImage {
                    url
                    ... on aceImagesS3_Asset {
                      altText
                    }
                  }
                }
              }
              accordionTitle
              accordionLeftContent
              buttonLabel
              buttonLink
              buttonLinkNewTab
              buttonLinkNofollow
              buttonType
              typeHandle
            }
            ... on practiceTestTemplate_anchor_BlockType {
              typeHandle
              anchorName
            }
            ... on practiceTestTemplate_anchorButton_BlockType {
              typeHandle
              backgroundColor
              buttonAlignment
              buttonLabel
              scrollTo
              buttonStyle
              buttonArrowDirection
            }
            ... on practiceTestTemplate_emailForm_BlockType {
              backgroundColor
              backgroundImage {
                url
                ... on aceImagesS3_Asset {
                  altText
                }
              }
              ctaLabel
              ctaType
              formContent
              formVertical
              formType
              showNameField
              showParentEmailField
              showPhoneNumberField
              hideEmailField
              emailFormImage {
                url
                ... on aceImagesS3_Asset {
                  altText
                }
              }
              imageAlignment
              subscriptionListIds
              textColor
              typeHandle
            }
            ... on practiceTestTemplate_resources_BlockType {
              typeHandle
              resourcesBackgroundColor
              resourcesBackgroundImage {
                url
                ... on aceImagesS3_Asset {
                  altText
                }
              }
              resourcesTextColor
              resourcesTitle
              resourceCards {
                ... on resourceCards_BlockType {
                  resourceImage {
                    url
                    ... on aceImagesS3_Asset {
                      altText
                    }
                  }
                  resourceBackgroundColor
                  resourceTextContent
                  resourceCtaStyle
                  resourceCtaLabel
                  resourceCtaLink
                  resourceCtaLinkNewTab
                  resourceCtaLinkNofollow
                }
              }
            }
            ... on practiceTestTemplate_tabComponent_BlockType {
              backgroundColor
              tabbedContentTitle
              typeHandle
              tab {
                ... on tab_BlockType {
                  isActive
                  tabContentList {
                    ... on tabContentList_accordionItem_BlockType {
                      itemDescription
                      itemTitle
                      showDescription
                      accordionSectionTitle {
                        ... on accordionSectionTitle_BlockType {
                          sectionTitle
                        }
                      }
                    }
                  }
                  tabTitle
                }
              }
            }
            ... on practiceTestTemplate_layout_BlockType {
              backgroundColor
              buttonAlignment
              buttonLayout
              dropdownTopLabel
              dropdownPlaceholder
              imageAlignment
              layoutTitle
              layoutContent
              layoutRightContent
              typeHandle
              textColor
              image {
                url
                ... on aceImagesS3_Asset {
                  altText
                }
              }
              backgroundImage {
                url
                ... on aceImagesS3_Asset {
                  altText
                }
              }
              buttons {
                ... on buttons_BlockType {
                  buttonLabel
                  buttonLink
                  buttonLinkNewTab
                  buttonLinkNofollow
                  buttonType
                }
              }
              dropdown {
                ... on dropdown_BlockType {
                  dropdownLabel
                  dropdownLink
                  dropdownLinkNewTab
                  dropdownLinkNofollow
                }
              }
              highlightedTextModal1 {
                ... on highlightedTextModal1_BlockType {
                  modalContent
                }
              }
              highlightedTextModal2 {
                ... on highlightedTextModal2_BlockType {
                  modalContent
                }
              }
            }
            ... on practiceTestTemplate_productCards_BlockType {
              backgroundImage {
                url
                ... on aceImagesS3_Asset {
                  altText
                }
              }
              backgroundColor
              textColor
              ctaStyle
              useCompactCards
              sectionTitle
              hideCta
              productCardList {
                ... on productCardList_BlockType {
                  cardBackgroundColor
                  cardTextColor
                  ctaLabel
                  ctaLink
                  ctaNewTab
                  ctaNofollow
                  learnMoreLink
                  productEntry {
                    ...productEntry_fragment
                  }
                  textContent
                  learnMoreLabel
                  learnMoreNewTab
                  learnMoreNofollow
                  starRating
                  starCaption
                  showSavings
                  cardTitle
                }
              }
              typeHandle
            }
            ... on practiceTestTemplate_practiceTest_BlockType {
              typeHandle
              showPracticeTest
              state
              jsonQuestions {
                url
              }
              contrastColor
              backgroundColor
              backgroundImage {
                url
                ... on aceImagesS3_Asset {
                  altText
                }
              }
              textColor
              resultsButtonAceLabel
              resultsButtonAceLink
              resultsButtonAceLinkNewTab
              resultsButtonAceLinkNofollow
              resultsButtonPrepAgentLabel
              resultsButtonPrepAgentLink
              resultsButtonPrepAgentLinkNewTab
              resultsButtonPrepAgentLinkNofollow
            }
            ... on practiceTestTemplate_quiz_BlockType {
              typeHandle
              backgroundColor
            }
            ... on practiceTestTemplate_externalWidget_BlockType {
              typeHandle
              backgroundColor
              externalWidgetId
              vendor
              componentHeight
            }
          }
        }
      }

      feefo: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_productTemplate_Entry {
          feefoProductId
        }
      }

      seoContent: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_practiceTestTemplate_Entry {
          ...RealEstateSEOFragmentEntry
        }
      }

      styles: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_practiceTestTemplate_Entry {
          embedStyles
        }
      }

      optimizely: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_practiceTestTemplate_Entry {
          optimizelyEnabled
        }
      }

      overrides: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_practiceTestTemplate_Entry {
          aceHeaderPageSettings {
            ...SettingsFragment
          }
        }
      }

      structuredData: entries(site: $site, slug: $slug) {
        ... on aceRealEstateTemplates_practiceTestTemplate_Entry {
          structuredData
        }
      }

      globalOptimizely: globalSets(handle: "globalOptimizely") {
        ... on globalOptimizely_GlobalSet {
          optimizelyAceAgentTemplates {
            ... on optimizelyAceAgentTemplates_aceAgentTemplateTypes_BlockType {
              optimizelyEnabled: practiceTestAATemplateOpti
            }
          }
        }
      }

      header: globalSets(handle: "aceHeader") {
        ... on aceHeader_GlobalSet {
          ...HeaderFragmentEntry
        }
      }
    }
    fragment productEntry_fragment on EntryInterface {
      ... on aceRealEstateProducts_xgritProduct_Entry {
        productPageUrl
        productState
        xgritBundleCoupon
        xgritCoupon
        xgritProductDisableRsa
        xgritProductId
        aceRealEstateProductCategory {
          ... on realEstateCategories_Category {
            aceCategoryCode
            title
          }
        }
      }
    }
    ${HeaderFragment.header}
    ${SettingsFragment.settings}
    ${SEOPracticeTestREFragment.seoContent}
  `,
};
