import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TemplateDynamicComponent } from './template-component/template-dynamic.component';
import { TemplateDynamicRoutingModule } from './template-dynamic-routing.module';

import { TemplateCareerCenterModule } from '@core-mkt/brands/aa/src/app/templates/template-career-center/template-career-center.module';
import { TemplatePracticeModule } from '@core-mkt/brands/aa/src/app/templates/template-practice/template-practice.module';
import { TemplateAarpModule } from '@core-mkt/brands/aarp/src/app/templates/template-aarp/template-aarp.module';
import { TemplateSingleModule } from '@core-mkt/shared/templates/single-template/template-st.module';
import { Template404Module } from '@core-mkt/shared/templates/template-404/template-404.module';
import { TemplateBundlesModule } from '@core-mkt/shared/templates/template-bundles/template-bundles.module';
import { TemplateCategoryModule } from '@core-mkt/shared/templates/template-category/template-category.module';
import { TemplateDisambigModule } from '@core-mkt/shared/templates/template-disambig/template-disambig.module';
import { TemplateGenericModule } from '@core-mkt/shared/templates/template-generic/template-generic.module';
import { TemplateHomepageModule } from '@core-mkt/shared/templates/template-homepage/template-homepage.module';
import { TemplateProductModule } from '@core-mkt/shared/templates/template-product/template-product.module';

@NgModule({
  declarations: [TemplateDynamicComponent],
  imports: [
    CommonModule,
    TemplateDynamicRoutingModule,
    TemplateHomepageModule,
    TemplateGenericModule,
    TemplateCategoryModule,
    TemplateProductModule,
    TemplateDisambigModule,
    Template404Module,
    TemplateBundlesModule,
    TemplateSingleModule,
    TemplatePracticeModule,
    TemplateCareerCenterModule,
    TemplateAarpModule,
  ],
})
export class TemplateDynamicModule {}
