<!-- Mobile Banner -->
<div *ngIf="displayMobileBanner && productPickerContent" class="lg:hidden py-v-spacing -mb-3 {{ mobileBannerTheme?.root }}">
  <div class="container !py-0">
  <div class="mobile-banner flex flex-row items-center justify-between">
    <h3 class="banner-heading !mb-0 mr-6" [innerHTML]="bannerText"></h3>
    <span (click)="toggleModal()" class="{{bannerCtaType}}-button !px-6 min-w-[177px] max-w-[177px]" [innerHTML]="bannerCtaLabel"></span>
  </div>
</div>
</div>

<div class="container flex lg:flex-row {{displayMobileBanner ?  'flex-col' : 'flex-col-reverse'}}">
  <!-- Side Content + Product Picker -->
  <div class="side-content wysiwyg px-0 py-v-spacing {{ !productPickerContent ? 'tab:px-v-spacing py-v-spacing max-w-vision mx-auto' : 'lg:px-v-spacing' }}" [innerHTML]="sideContent"></div>
  <div *ngIf="productPickerContent" class="product-picker-container pb-v-spacing lg:p-v-spacing lg:ml-v-spacing {{ displayMobileBanner ? 'hidden lg:block' : '' }}">
    <div class="{{ pickerTheme?.root }} p-8 rounded-md lg:min-w-[348px] sticky top-[60px]">
      <h3 class="picker-heading mb-v-spacing" [innerHTML]="productPickerHeading"></h3>
      <product-picker [data]="productPickerContent" [ctaButtonData]="ctaButtonData"></product-picker>
    </div>
  </div>

  <!-- Modal Background -->
  <div *ngIf="showModal" class="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center z-50" (click)="toggleModal()">
    <!-- Modal Content -->
    <div class="{{ pickerTheme?.root }} p-8 rounded-md relative" (click)="$event.stopPropagation()">
      <h3 class="picker-heading mb-v-spacing w-3/4" [innerHTML]="productPickerHeading"></h3>
      <fa-icon
          tabindex="0" role="button" aria-label="Close modal" aria-hidden="true"
          class="text-lg h-6 w-6 leading-none text-ace-grey-600 absolute right-4 top-4 cursor-pointer"
          (click)="toggleModal()"
          (keydown.enter)="toggleModal()"
          (keydown.space)="toggleModal(); $event.preventDefault()"
          [icon]="faTimes"
        ></fa-icon>
      <product-picker [data]="productPickerContent" [ctaButtonData]="ctaButtonData"></product-picker>
    </div>
  </div>
</div>
