import { Injectable } from '@angular/core';
import { environment } from '@core-mkt/environments/environment';
import type { BrandConfig } from '@core-mkt/interfaces/brand-config';

@Injectable({
  providedIn: 'root',
})
export class BrandConfigurationService {
  private brandConfig: BrandConfig | undefined;

  constructor() {}

  async loadConfiguration(): Promise<BrandConfig> {
    try {
      this.brandConfig = environment.brandConfig;
      return this.brandConfig;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  get config(): BrandConfig | undefined {
    return this.brandConfig;
  }

  get id(): string | undefined {
    return this.brandConfig?.id;
  }

  get lowercaseId(): string | undefined {
    return this.brandConfig?.id.toLowerCase();
  }

  get name(): string | undefined {
    return this.brandConfig?.name;
  }

  get url(): string | undefined {
    return this.brandConfig?.url;
  }

  get hostName(): string | undefined {
    return this.brandConfig?.hostName;
  }

  get siblingSites(): string[] {
    return this.brandConfig?.siblingSites || [];
  }

  get cdiParamKey(): string | undefined {
    return this.brandConfig?.cdiParamKey;
  }

  get cdiParamValueWhenEnabled(): string | undefined {
    return this.brandConfig?.cdiParamValueWhenEnabled;
  }

  get trustpilotId(): string | undefined {
    return this.brandConfig?.trustpilotId;
  }

  get trustpilotApiKey(): string | undefined {
    return this.brandConfig?.trustpilotApiKey;
  }
}
