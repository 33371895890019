import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EnvService } from '@core-mkt/services/env/env.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import type { ModalContent } from './ambassador-data';
import { modalContentData } from './ambassador-data';

@Component({
  selector: 'ambassador-modal',
  templateUrl: './ambassador-modal.component.html',
  styleUrls: ['./ambassador-modal.component.scss'],
})
export class AmbassadorModalComponent implements OnInit {
  trigger: boolean;
  faTimes = faTimes;
  modalData: ModalContent;
  brandConfiguration: string;

  constructor(private route: ActivatedRoute, private env: EnvService) {}

  ngOnInit(): void {
    this.brandConfiguration = this.env.get.brandConfig.id.toLocaleLowerCase();
    this.modalData = modalContentData[this.brandConfiguration];
    if (this.modalData) {
      const amb = this.route.snapshot.queryParamMap?.get('campaignid');
      if (amb === this.modalData.ambassadorCampaign) {
        this.trigger = true;
      }
    }
  }

  private toggleModal(): void {
    this.trigger = !this.trigger;
  }
}
