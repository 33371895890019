<div
  *ngIf="rightContent && !categoryWidgets"
  class="wysiwyg table w-full"
  [innerHTML]="rightContent"
></div>
<category-widget *ngIf="categoryWidgets" [data]="data" [categoryWidgets]="categoryWidgets"></category-widget>
<div
  *ngIf="bottomRightContent" (click)="activateModalWrapper($event)"
  class="wysiwyg noborder w-full flex text-base mt-v-spacing"
>
  <div *ngFor="let content of bottomRightContent" class="flex w-full my-1">
    <span class="top-product__img-container">
    <img
        class="top-product__img w-full lg:order-none order-last"
        *ngIf="
        content.sealIcon &&
          content.sealIcon.length > 0 &&
          content.sealIcon[0].url !== null
        "
        [src]="imgixService.buildImgixUrl(content.sealIcon[0].url)"
        [alt]="content.sealIcon[0].altText"
        width="60"
        height="60"
        preloadImg
      />
    </span>
    <span [innerHTML]="content.sealIconText" class="flex text-sm items-center"></span>
  </div>
</div>
