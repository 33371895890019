import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { InsertableModule } from '@aceable/core';
import { MarketingModule } from '@core-mkt/shared/components/marketing/marketing.module';

import { QuizModule } from '@core-mkt/brands/aa/src/app/components/quiz/quiz.module';
import { AccordionModule } from '@core-mkt/shared/components/accordion/accordion.module';
import { BannerModule } from '@core-mkt/shared/components/banner/banner.module';
import { BreadcrumbModule } from '@core-mkt/shared/components/breadcrumb/breadcrumb.module';
import { CareerCenterModule } from '@core-mkt/shared/components/career-center/career-center.module';
import { EmailFormModule } from '@core-mkt/shared/components/email-form/email-form.module';
import { GraphicBannerModule } from '@core-mkt/shared/components/graphic-banner/graphic-banner.module';
import { LayoutModule } from '@core-mkt/shared/components/layout/layout.module';
import { LearnMoreModule } from '@core-mkt/shared/components/learn-more/learn-more.module';
import { MultiColumnModule } from '@core-mkt/shared/components/multi-column/multi-column.module';
import { QuizWidgetModule } from '@core-mkt/shared/components/quiz-widget/quiz-widget.module';
import { ResourcesModule } from '@core-mkt/shared/components/resources/resources.module';
import { SideProductPickerModule } from '@core-mkt/shared/components/side-product-picker/side-product-picker.module';
import { VisionModalModule } from '@core-mkt/shared/components/vision-modal/vision-modal.module';
import { FooterModule } from '../../components/footer/footer.module';
import { HeaderModule } from '../../components/header/header.module';
import { TemplateCareerCenterRoutingModule } from './template-career-center-routing.module';
import { TemplateCareerCenterComponent } from './template-component/template-career-center.component';

@NgModule({
  declarations: [TemplateCareerCenterComponent],
  exports: [TemplateCareerCenterComponent],
  imports: [
    CommonModule,
    MarketingModule,
    VisionModalModule,
    InsertableModule,
    EmailFormModule,
    TemplateCareerCenterRoutingModule,
    HeaderModule,
    FooterModule,
    BannerModule,
    BreadcrumbModule,
    MultiColumnModule,
    LayoutModule,
    AccordionModule,
    ResourcesModule,
    CareerCenterModule,
    LearnMoreModule,
    QuizModule,
    QuizWidgetModule,
    GraphicBannerModule,
    SideProductPickerModule,
  ],
})
export class TemplateCareerCenterModule {}
