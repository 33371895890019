import { CraftTemplateType } from '@core-mkt/craft/craft-template-types';
import { CraftQuery } from '@core-mkt/craft/craft-query';
import { TemplateAarpQuery } from './template-aarp-query';
import { Template404Query } from './template-404-query';
import { TemplateSingleQuery } from '@core-mkt/shared/templates/single-template/template-component/template-single-query';

export const AarpCraftQueries = new Map<CraftTemplateType, CraftQuery>([
  ['baseTemplate', TemplateAarpQuery],
  ['404Template', Template404Query],
  ['visionTemplateEntry', TemplateSingleQuery],
]);
