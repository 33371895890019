<section class="bg-vis-dark bg-cover font-body text-vis-reverse">
  <section class="container !px-0 grid"
    [class]="hasCarousel ? 'grid-cols-1' : 'grid-cols-1 lg:grid-cols-2 items-center'">
    <study-builder-wyswyg-content *ngIf="hasWysiwygContent" [isCenterAligned]="hasCarousel ? true : false"
      [data]="wysiwygContent"></study-builder-wyswyg-content>
    <section class="grid" [class]="hasCarousel ? 'grid-cols-1 lg:grid-cols-2' : 'grid-cols-1'">
      <section class="lg:order-2 p-6" *ngIf="hasProductBuilderForm"
        [class]="hasCarousel ? 'lg:flex lg:items-center lg:justify-center' : 'flex justify-start'">
        <study-builder-form [data]="data?.productBuilder"></study-builder-form>
      </section>
      <section class="lg:order-1 p-6" *ngIf="hasCarousel">
        <study-builder-slider class="relative" [data]="carouselData"></study-builder-slider>
      </section>
    </section>
  </section>
</section>
