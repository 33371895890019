import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { ImgixService } from '@core-mkt/services/imgix/imgix.service';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import { VisionModalService } from '@core-mkt/shared/components/vision-modal/vision-modal.service';
import type { BottomRightContent, CategoryWidgets } from '../top-section';
import { TopSection } from '../top-section';

@Component({
  selector: 'top-section-wysiwyg',
  templateUrl: './top-section-wysiwyg.component.html',
  styleUrls: ['./top-section-wysiwyg.component.scss'],
})
export class TopSectionWysiwygComponent implements OnInit {
  @Input() rightContent: string;
  @Input() bottomRightContent: BottomRightContent[];
  @Input() categoryWidgets: CategoryWidgets[];
  @Input() data: TopSection;

  constructor(
    private redactor: WysiwygRedactorService,
    private imgixService: ImgixService,
    private modal: VisionModalService,
  ) {}

  ngOnInit() {
    this.rightContent = this.redactor.bypassSanitizer(this.rightContent, 'lazy');
  }

  activateModalWrapper(event: MouseEvent): void {
    if (
      (event.target as HTMLElement).classList.contains('highlighted-text-modal-1') &&
      this.data.highlightedTextModal1.length > 0
    ) {
      const modalContent = this.data.highlightedTextModal1[0].modalContent;
      this.modal.openModal({ showModal: true, modalContent });
    }
    if (
      (event.target as HTMLElement).classList.contains('highlighted-text-modal-2') &&
      this.data.highlightedTextModal2.length > 0
    ) {
      const modalContent = this.data.highlightedTextModal2[0].modalContent;
      this.modal.openModal({ showModal: true, modalContent });
    }
  }
}
