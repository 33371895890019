export interface ProductBuilderTableRow {
  state: string;
  productId: string;
  courseType: CourseTypes;
  duration: CourseDuration;
  coupon: string;
}

export interface ProductBuilderCategory {
  productCouponOverride: string;
  productBuilderTable: ProductBuilderTableRow[];
}

export interface CarouselItem {
  url: string;
  alt: string;
  width: number;
  height: number;
}

export interface ProductBuilderData {
  typeHandle: string;
  wyswygContent?: string;
  wysiwygContent?: string;
  carousel?: CarouselItem[];
  imageCarousel?: CarouselItem[];
  productBuilder: ProductBuilderCategory[];
}

export enum CourseTypes {
  salesperson = 'Salesperson',
  broker = 'Broker',
  salesAssociate = 'Sales Associate',
  managingBroker = 'Managing Broker',
  associateBroker = 'Associate Broker',
  qualifyingBroker = 'Qualifying Broker',
  principalBroker = 'Principal Broker',
  affiliateBroker = 'Affiliate Broker',
}

export enum CourseDuration {
  week = '1 Week',
  month = '1 Month',
  months = '3 Months',
}

export interface RadioOption {
  value: string | number;
  label: string | number;
}
