import { Injectable } from '@angular/core';
import { EnvService } from '@core-mkt/services/env/env.service';

import type { VerticalInfo } from './vertical';

@Injectable({
  providedIn: 'root',
})
export class VerticalService {
  constructor(private env: EnvService) {}
  getVerticalInfo(path?): VerticalInfo {
    return {
      vertical: this.env.get.brandConfig.vertical,
      segmentId: this.env.get.segmentId,
      iterableApiKey: this.env.get.brandConfig.iterableApiKey,
      ambassadorGroupId: this.env.get.brandConfig.ambassadorGroupId,
    };
  }
}
