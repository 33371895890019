export const modalContentData: ModalContentData = {
  aa: {
    modalTitle: 'You’re Getting a 20% Referral Discount!',
    modalContent:
      'Your new career and big savings are only clicks away. Select any Aceable real estate course, head to checkout,and your 20% discount will be auto-applied.',
    modalCTA: 'Select Course',
    ambassadorCampaign: '33113',
  },
  ins: {
    modalTitle: 'You’re Getting a 10% Referral Discount!',
    modalContent:
      'Your new career and big savings are only clicks away. Select any Aceable insurance course, head to checkout, and your 10% discount will be auto-applied.',
    modalCTA: 'Select Course',
    ambassadorCampaign: '73551',
  },
};

interface ModalContentData {
  [key: string]: ModalContent;
}

export interface ModalContent {
  modalTitle: string;
  modalContent: string;
  modalCTA: string;
  ambassadorCampaign: string;
}
