import { InsertableModule } from '@aceable/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccordionModule } from '@core-mkt/shared/components/accordion/accordion.module';
import { AnchorButtonModule } from '@core-mkt/shared/components/anchor-button/anchor-button.module';
import { AnchorModule } from '@core-mkt/shared/components/anchor/anchor.module';
import { BannerModule } from '@core-mkt/shared/components/banner/banner.module';
import { BreadcrumbModule } from '@core-mkt/shared/components/breadcrumb/breadcrumb.module';
import { FaqModule } from '@core-mkt/shared/components/faq/faq.module';
import { GladlyHelpCenterModule } from '@core-mkt/shared/components/gladly-help-center/gladly-help-center.module';
import { HeroHomepageModule } from '@core-mkt/shared/components/hero-homepage/hero-homepage.module';
import { LayoutModule } from '@core-mkt/shared/components/layout/layout.module';
import { MarketingModule } from '@core-mkt/shared/components/marketing/marketing.module';
import { MultiCardModule } from '@core-mkt/shared/components/multi-card/multi-card.module';
import { MultiColumnModule } from '@core-mkt/shared/components/multi-column/multi-column.module';
import { PriceSelectorModule } from '@core-mkt/shared/components/price-selector/price-selector.module';
import { ResourcesModule } from '@core-mkt/shared/components/resources/resources.module';
import { ReviewsModule } from '@core-mkt/shared/components/reviews/reviews.module';
import { TopSectionModule } from '@core-mkt/shared/components/top-section/top-section.module';
import { VisionModalModule } from '@core-mkt/shared/components/vision-modal/vision-modal.module';
import { FooterModule } from '../../components/footer/footer.module';
import { HeaderModule } from '../../components/header/header.module';
import { TemplateAarpRoutingModule } from './template-aarp-routing.module';
import { TemplateAarpComponent } from './template-component/template-aarp.component';

@NgModule({
  declarations: [TemplateAarpComponent],
  exports: [TemplateAarpComponent],
  imports: [
    CommonModule,
    VisionModalModule,
    InsertableModule,
    TemplateAarpRoutingModule,
    HeroHomepageModule,
    MarketingModule,
    HeaderModule,
    FooterModule,
    BannerModule,
    AnchorButtonModule,
    MultiCardModule,
    MultiColumnModule,
    LayoutModule,
    AccordionModule,
    ResourcesModule,
    ReviewsModule,
    TopSectionModule,
    AnchorModule,
    BreadcrumbModule,
    FaqModule,
    PriceSelectorModule,
    GladlyHelpCenterModule,
  ],
})
export class TemplateAarpModule {}
